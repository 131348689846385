import React from 'react';
import {useTranslation} from 'react-i18next';
import {Alert, Snackbar} from "@mui/material";


export default function PDSSnackbar({
                                        open,
                                        onClose,
                                        message,
                                        severity,
                                        position
                                    }) {
    const {t} = useTranslation();

    return (
        <Snackbar open={open} autoHideDuration={10000} onClose={onClose} sx={{ width: '100%' }}
                  anchorOrigin={position==='TOP'?{vertical: 'top', horizontal: 'center'}:{vertical: 'bottom', horizontal: 'left'}}        
        >
            <Alert  elevation={2} variant="filled" onClose={onClose} severity={severity}>
                {message}
            </Alert >
        </Snackbar>
    );
}

