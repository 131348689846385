import React, {useState} from 'react';
import {Typography} from '@mui/material';
import Box from "@mui/material/Box";
import ReservationListPage from "./ReservationListPage";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";


function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const AllReservation = (props) => {

    const {
        user,
        useCases,
        userUseCases,
    } = props;

    const [value, setValue] = useState('1');
    const {t} = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const isAdmin = user && user.admin;
    const hasAsset = user && user.parkingAsset != null;


    return (
        <ReservationListPage all={true}/>
    )


}


const mapStateToProps = (store) => ({
        user: store.authData.user,
        useCase: store.useCasesData.useCases,
        userUseCases: store.authData.userUseCases,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AllReservation);