import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ToolBar from "./ToolBar";
import ReceiptsList from "./ReceiptsList";
import { fetchReceipts } from "../../../redux/actions/receipt";
import { fetchParkingPlaces } from "../../../redux/actions/parkingPlace";
import { forwardReceipt } from "../../../redux/actions/receipt";
import { Skeleton } from "@mui/material";
import ReceiptDetail from "../../../components/modal/Receipt"
import ReceiptForward from "../../../components/modal/Receipt/forward"

function BackOfficeReceipt(props) {
    const {
        userUseCases,
        receipts,
        parkingPlaces,
        fetchReceipts,
        fetchParkingPlaces,
        isFetchingAllList,
        forwardReceipt
    } = props;

    const [isFetchedReceipts, setIsFetchedReceipts] = useState(false);
    const [isFetchingParkingPlaces, setIsFetchingParkingPlaces] = useState(false);
    const [filter, setFilter] = useState({});
    const [selectedPlace, setSelectedPlace] = useState();
    const [table, setTable] = useState(false);
    const [parkingPlacesValues, setParkingPlacesValues] = useState();
    const [openDetail, setOpenDetail] = useState(false);
    const [receiptDetail, setReceiptDetail] = useState({});
    const [openForward, setOpenForward] = useState(false);
    const [receiptForward, setReceiptForward] = useState({});

    useEffect(() => {
        if (!isFetchedReceipts) {
            fetchReceipts(filter.selectedPlace?filter.selectedPlace.key:null, filter.lpn, filter.email, filter.receiptId, filter.dateFrom, filter.dateTo)
                .then(() => {
                    setIsFetchedReceipts(true);
                })
                .catch((err) => {
                    console.log(err);
                    setIsFetchedReceipts(false);
                });
        }

        if (!isFetchingParkingPlaces) {
            fetchParkingPlaces()
                .then(() => {
                    setIsFetchingParkingPlaces(true);
                })
                .catch((err) => {
                    console.log(err);
                    setIsFetchingParkingPlaces(false);
                });
        }
    }, [
        fetchReceipts,
        isFetchedReceipts,
        fetchParkingPlaces,
        isFetchingParkingPlaces
    ]);

    const handleFilter = (item) => (value) => {
        if ( item==='email' || item==='lpn' || item==='receiptId') {
            setFilter({...filter, [item]:value.target.value?value.target.value:''});
        } else {
            setFilter({...filter, [item]:value});
        }
        setIsFetchedReceipts(false);
    };

    const handleSelectedPlace = (value) => {
        setSelectedPlace(value);
        setFilter({...filter, selectedPlace:value});
        setIsFetchedReceipts(false);
    }

    const handleReceiptDetail = (data) => {
        setReceiptDetail(data);
        setOpenDetail(true);
    }

    const hadleCloseDetail = () => {
        setOpenDetail(false);
    }

    const handleReceiptForward = (data) => {
        setReceiptForward(data);
        setOpenForward(true);
    }

    const hadleCloseForward = () => {
        setOpenForward(false);
    }

    const handleFowrard = (receipt, email) => {
        // console.log('handleFowrard', receipt, email);
        forwardReceipt(receipt, email);
        setOpenForward(false);
    }

    useEffect(() => {
        if (parkingPlaces) {
            setParkingPlacesValues(
                parkingPlaces.map((a) => {
                    return { value: a.parkingPlaceId, label: a.name, key: a.parkingPlaceId };
                }),
            );
        }
    }, [parkingPlaces]);

    return (
        <>
            {!isFetchingAllList ? (
                <ToolBar
                    userUseCases={userUseCases}
                    filter={filter}
                    handleFilter={handleFilter}
                    parkingPlaces={parkingPlacesValues}
                    selectedPlace={selectedPlace}
                    handleSelectedPlace={handleSelectedPlace}
                    table={table}
                    setTable={setTable}
                />
            ) : (
                <Skeleton
                    height={200}
                    sx={{
                        width: "86%",
                        marginLeft: "30px",
                    }}
                    animation="wave"
                />
            )}

            {!isFetchingAllList ? (
                <div>
                    <ReceiptsList
                        userUseCases={userUseCases}
                        receipts={receipts}
                        isLoading={isFetchingAllList}
                        table={table}
                        handleReceiptDetail={handleReceiptDetail}
                        handleReceiptForward={handleReceiptForward}
                    />
                </div>
            ) : (
                <Skeleton
                    variant="rounded"
                    height="calc(100vh - 200px)"
                    sx={{
                        width: "86%",
                        marginLeft: "30px",
                    }}
                    animation="wave"
                />
            )}

            <ReceiptDetail
                open={openDetail}
                handleClose={hadleCloseDetail}
                receiptDetail={receiptDetail}
            />

            <ReceiptForward
                open={openForward}
                handleClose={hadleCloseForward}
                handleFowrard={handleFowrard}
                receipt={receiptForward}
            />
        </>
    );
}

const mapStateToProps = (store) => ({
    userUseCases: store.authData.userUseCases,
    receipts: store.receiptData.receipts,
    parkingPlaces: store.parkingPlaceData.parkingPlaces,
    isFetchingAllList: store.usersData.isFetchingParkingList,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchReceipts,
            fetchParkingPlaces,
            forwardReceipt,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeReceipt);
