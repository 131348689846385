import React, {useEffect, useState,} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {styled} from "@mui/system";
import Paper from "@mui/material/Paper";
import {CircularProgress, Grid, TextField} from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import {useTheme} from '@emotion/react';
import CustomRtfEditor from '../../../components/common/CustomRtfEditor/CustomRtfEditor';
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {updateSetting, fetchSetting} from "../../../redux/actions/setting";
import TenantAutocomplete from '../../../components/common/TenantAutocomplete';

const FetchingProgress = styled('div')(({theme}) => ({
    paddingTop: '12px',
}));

function SettingView(props) {

    const {t} = useTranslation();
    const theme = useTheme();
    const {
        fetchSetting,
        updateSetting,
        setting,
        user
    } = props;

    const [welcomePage, setWelcomePage] = useState('');
    const [carEmail, setCarEmail] = useState('');
    const [placeEmail, setPlaceEmail] = useState('');
    const [isFetchedSetting, setIsFetchedSetting] = useState(false);
    const [selectedTenant, setSelectedTenant] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (!isFetchedSetting) {
            fetchSetting().then(() => {
                setIsFetchedSetting(true);
                setIsLoading(false);
            }).catch((err) => {
                console.log(err);
                setIsFetchedSetting(false);
                setIsLoading(true);
            });
        }


    }, [fetchSetting, setIsFetchedSetting]);

    useEffect(() => {
        if (setting) {
            setPlaceEmail(setting.placeEmail);
            setCarEmail(setting.carEmail);
        }
    }, [setting]);

    const handleHtmlContent = (content) => {
        setWelcomePage(content);
    }

    const handleSave = () => {
        try {
            updateSetting({settingId: 1, welcomePage: welcomePage, carEmail:carEmail, placeEmail:placeEmail, tenantId: selectedTenant.tenantId});
            setIsFetchedSetting(false);
        } catch (error) {
            // setError(getErrorMessage(error, t));
            // setSnackbarOpen(true);
            console.log(error);
        }
    }

    const handleChangePlace = (event) => {
        setPlaceEmail(event.target.value);
    }

    const handleChangeCar = (event) => {
        setCarEmail(event.target.value);
    }

    return (
        <div style={{marginTop: "0px", overflow: 'auto', height: "100%"}}>
            {!isLoading ?

                <AutoSizer>
                    {({height, width}) => {
                        return (
                            <div>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        width: {width},
                                        backgroundColor: (theme) => theme.palette.background.grey100,
                                        boxShadow: `1px 1px 20px 1px ${theme.palette.shadow}`,
                                        minHeight: "calc(100vh)"
                                    }}
                                >
                                    <Grid container spacing={2} style={{paddingTop: '20px', paddingLeft: '20px', height: '180px'}}>
                                        <Grid item xs={4}>
                                            <TenantAutocomplete
                                                // error={error.tenants}
                                                setSelectedTenant={setSelectedTenant}
                                                selectedTenant={selectedTenant}
                                                multiple={false}
                                                disabled={!user.admin}
                                                required={true}
                                                user={user}
                                                tenantId={user.admin ? ( setting ? setting.tenantId : 0 ) : ( user ? user.tenantId : 0 )}
                                            />
                                        </Grid>
                                        <Grid item xs={8}></Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="placeEmail"
                                                value={placeEmail}
                                                onChange={handleChangePlace}
                                                label={t('EMAIL_PLACE_MANAGER')}/>
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="carEmail"
                                                value={carEmail}
                                                onChange={handleChangeCar}
                                                label={t('EMAIL_CAR_MANAGER')}/>
                                        </Grid>

                                        <Grid item xs={4}></Grid>
                                    </Grid>

                                    <CustomRtfEditor handleHtmlContent={handleHtmlContent} placeholderText={t('WELCOME_PAGE_TEXT')} title={t('WELCOME_PAGE')} initialHtml={setting.welcomePage} />
                                    <div style={{paddingTop: '10px', paddingLeft: '20px',}}>
                                        <Button
                                            sx={{
                                                width: '150px', margin: 1
                                            }}
                                            variant="outlined"
                                            color="primary"
                                            onClick={handleSave}
                                            endIcon={<SaveIcon/>}
                                        > {t('SAVE')}</Button>
                                    </div>
                                </Paper>
                            </div>
                        )
                    }}
                </AutoSizer> : <FetchingProgress><CircularProgress size={60}/> </FetchingProgress>
            }
        </div>
    );
}

const mapStateToProps = (store) => ({
    setting: store.settingData.setting,
    isFetchingAllList: store.groupData.isFetchingAllList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateSetting, 
    fetchSetting,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SettingView);


