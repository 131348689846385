import httpService from './http.service';

const fetchRoles = () => httpService
    .get('/roles/all')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const fetchRolesByTenantId = (tenantId) => httpService
    .get('/roles/tenant/'+tenantId)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

export default {
    fetchRoles,
    fetchRolesByTenantId,
};