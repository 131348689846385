import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import IconButton from "@mui/material/IconButton/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Check from "@mui/icons-material/Check";
import {Divider} from '@mui/material';
import Button from "@mui/material/Button";


const PDSDialog = ({
                       open,
                       title,
                       onClose,
                       onSubmit,
                       size,
                       height,
                       headerColor,
                       children,
                       actionType,
                       flexDirection,
                   }) => {
    const {t} = useTranslation();

    return (
        <Dialog
            disableEnforceFocus
            maxWidth={size}
            open={open}
            onClose={onClose}
            PaperProps={{sx: {height}}}
            onKeyUp={(e) => {
                if (open) {
                    const ENTER = 13;
                    console.warn("submittting");
                    if (e.keyCode === ENTER && !e.shiftKey) {
                        onSubmit && onSubmit();
                    }
                }
            }}
        >
            <DialogTitle sx={{
                'background': headerColor,
                paddingRight: 2,
                '& h2': {
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    fontSize: 26,
                    lineHeight: '30px',
                    color: "black",
                    '& .MuiButtonBase-root': {
                        color: "black",
                    },
                    '& input + fieldset': {
                        borderColor: "black"
                    },
                },
            }}>
                <Typography variant="h2">{title}</Typography>
                <IconButton
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: 1,
                        top: 1,
                        color: '#aaaaaa',

                    }}
                    onClick={onClose}
                    size="large">
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>

            <Divider sx={{
                color: '#aaaaaa',
                margin: '1px'
            }}/>

            <DialogContent xs={{
                display: 'flex',
                flexDirection,
                justifyContent: 'space-evenly',

            }}>
                {children}
            </DialogContent>
            <DialogActions sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-around',
                marginBottom: 2,

            }}>
                <Button
                    label={t('CLOSE')}
                    onClick={onClose}
                />
                {onSubmit && <Button
                    startIcon={<Check/>}
                    label={t('SUBMIT')}
                    onClick={onSubmit}
                />}
            </DialogActions>
        </Dialog>
    );
}

PDSDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    height: PropTypes.number,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    children: PropTypes.node,
    headerColor: PropTypes.string,
    actionType: PropTypes.string,
    flexDirection: PropTypes.string,
};


PDSDialog.defaultProps = {
    children: null,
    height: 550,
    size: 'sm',
    headerColor: '#F5894A',
    actionType: 'saveModal',
    flexDirection: 'column',
};

export default PDSDialog;


