import types from "../actionTypes";

const INITIAL_STATE = {
    isFetchingReceipt: false,
    isForwarding: false,
    receipts: [],
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.RECEIPT_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingReceipt: false,
                receipts: action.payload.receipts,
            };
        case types.RECEIPT_FETCH_FAIL:
            return {
                ...state,
                isFetchingReceipt: false,
                errors: action.payload.error,
            };
        case types.RECEIPT_FETCH_REQUEST:
            return {
                ...state,
                isFetchingReceipt: true,
            };

        case types.RECEIPT_FORWARD_SUCCESS:
            return {
                ...state,
                isForwarding: false,
                forward: action.payload.forward,
            };
        case types.RECEIPT_FORWARD_FAIL:
            return {
                ...state,
                isForwarding: false,
                errors: action.payload.error,
            };
        case types.RECEIPT_FORWARD_REQUEST:
            return {
                ...state,
                isForwarding: true,
            };

        default:
            return state;
    }
};
