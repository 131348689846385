import types from '../actionTypes';
import roleService from '../../services/role.service';


export const fetchRoles = () => (dispatch, getState) => {
    if (getState().roleData.isFetchingRoles) {
        return Promise.reject();
    }

    dispatch({
        type: types.ROLES_FETCH_REQUEST,
    });

    return roleService.fetchRoles()
        .then((roles) => {
            dispatch({
                type: types.ROLES_FETCH_SUCCESS,
                payload: {roles},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ROLES_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchRolesByTenantId = (tenantId) => (dispatch, getState) => {
    if (getState().roleData.isFetchingRolesByTenantId) {
        return Promise.reject();
    }

    dispatch({
        type: types.ROLES_BY_TENANT_ID_FETCH_REQUEST,
    });

    return roleService.fetchRolesByTenantId(tenantId)
        .then((roles) => {
            dispatch({
                type: types.ROLES_BY_TENANT_ID_FETCH_SUCCESS,
                payload: {roles},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ROLES_BY_TENANT_ID_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};