import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import * as React from "react";
import {
  dayjsToDateString,
  dayjsToDateTimeString,
  dayjsToNumberDayAndTimeArray,
  dayjsToTimeString,
  isComponentEnabledForUser,
  isComponentVisibleForUser,
} from "../../utils";
import {
  ASSET_TYPE_PLACE,
  RESERVATION_STATUSES,
  UC0101,
  UC0102,
  UC0106,
  UC0107,
} from "../../utils/constants";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton/IconButton";
import Edit from "@mui/icons-material/Edit";
import { maxWidth, styled } from "@mui/system";
import { useTheme } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import LicensePlate from "../../components/common/LicensePlate";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  AccessTime,
  AccountCircle,
  AccountCircleRounded,
  CalendarMonthRounded,
  Place,
  PlaceRounded,
} from "@mui/icons-material";
import { Calendar } from "mdi-material-ui";

const Timewrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  paddingLeft: 5,
  paddingRight: 5,
  borderRadius: "30",
}));

const Attachment = styled("img")(({ theme }) => ({}));

const getBackgroundColor = (reservation, theme, withContrast) => {
  if (reservation.status === RESERVATION_STATUSES.PLANNED) {
    return theme.palette.status[`planned${withContrast ? "Contrast" : ""}`];
  } else if (reservation.status === RESERVATION_STATUSES.IN_PROGRESS) {
    return theme.palette.status[`inProgress${withContrast ? "Contrast" : ""}`];
  }
  return withContrast
    ? theme.palette.status.oldContrast
    : theme.palette.background.paper;
};

const renderImg = (image, height, width, type) => {
  return (
    <div
      style={{
        maxWidth: "300px",
        maxHeight: "150px",
        overflow: "hidden",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <Attachment
        src={
          image
            ? `data:image/png;base64,${image}`
            : type === ASSET_TYPE_PLACE
            ? "/misto.png"
            : "/auto.png"
        }
        sx={{
          width: "auto",
          height: "150px",
          padding: !image ? "5px" : "0px",
        }}
      ></Attachment>
    </div>
  );
};

const Cool = styled("div")({
  marginBottom: "30px",
  marginTop: "30px",
});

export const ReservationDetailContent = ({
  all,
  reservation,
  handleEdit,
  handleDelete,
  userUseCases,
  height,
  width,
  size = "sm",
  modal,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const action =
    handleDelete && handleEdit ? 4 : handleDelete || handleEdit ? 1 : 0;

  return (
    <span style={{ display: "flex", position: "relative" }}>
      {handleEdit &&
        ((!all && isComponentVisibleForUser(UC0101, userUseCases)) ||
          (all && isComponentVisibleForUser(UC0106, userUseCases))) &&
        `${reservation.status}` !== "OLD" && (
          <span
            style={{ position: "absolute", right: 12, top: 12, zIndex: 1000 }}
          >
            <IconButton
              color="primary"
              aria-label="edit"
              size="small"
              onClick={() => {
                if (
                  (!all && isComponentEnabledForUser(UC0101, userUseCases)) ||
                  (all && isComponentEnabledForUser(UC0106, userUseCases))
                ) {
                  handleEdit(reservation);
                }
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          </span>
        )}
      {handleDelete &&
        ((!all && isComponentVisibleForUser(UC0102, userUseCases)) ||
          (all && isComponentVisibleForUser(UC0107, userUseCases))) &&
        `${reservation.status}` === "PLANNED" && (
          <span
          style={{ position: "absolute", left: 11, top: 11, zIndex: 1000 }}
          >
            <IconButton
              color="primary"
              aria-label="delete"
              size="small"
              onClick={() => {
                if (
                  (!all && isComponentEnabledForUser(UC0102, userUseCases)) ||
                  (all && isComponentEnabledForUser(UC0107, userUseCases))
                ) {
                  handleDelete(reservation.reservationId);
                }
              }}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </span>
        )}

      <Paper
        elevation={0}
        sx={{
          padding: 2,
          backgroundColor: (theme) => theme.palette.background.paper,
          color: (theme) => theme.palette.secondary.selection,
          boxShadow: `3px 3px 5px 1px ${theme.palette.shadow}`,
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <div
          style={{
            height: "160px",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
            marginBottom: 10,
          }}
        >
          <Attachment
            src={
              reservation.img
                ? `data:image/png;base64,${reservation.locationName}`
                : "/parkovacidum.png"
            }
            style={{
              width: "auto",
              height: "100%",
              padding: !reservation.img ? "5px" : "0px",
            }}
          />
        </div>
        <span style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <AccountCircleRounded
            sx={{ color: (theme) => theme.palette.secondary.subtleText }}
          />
          <span>
            <Typography variant="body1" fontWeight="600">
              {reservation.reservedByFirstName
                ? reservation.reservedBySurname
                  ? `${reservation.reservedByFirstName} ${reservation.reservedBySurname}`
                  : reservation.reservedByFirstName
                : reservation.reservedBySurname
                ? reservation.reservedBySurname
                : ""}
            </Typography>
            <Typography
              sx={{ color: (theme) => theme.palette.secondary.subtleText }}
            >
              {reservation.reservedByEmail}
            </Typography>
          </span>
          <LicensePlate licensePlate={reservation.licensePlate} active={true} />
        </span>
        <span style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <PlaceRounded
            sx={{ color: (theme) => theme.palette.secondary.subtleText }}
          />
          <span>
            <Typography variant="body1" fontWeight="600">
              {reservation.locationName}
            </Typography>
            <Typography
              sx={{ color: (theme) => theme.palette.secondary.subtleText }}
            >
              {reservation.assetName}
            </Typography>
          </span>
        </span>
        <span style={{ display: "flex", alignItems: "center", gap: 16 }}>
          <CalendarMonthRounded
            sx={{ color: (theme) => theme.palette.secondary.subtleText }}
          />
          <Paper
            sx={{
              backgroundColor: theme.palette.background.default,
              boxShadow: "none",
              width: "100%",
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              padding: 1.4,
              borderRadius: "10px",
            }}
          >
            <Typography
              align="center"
              variant={"body2"}
              sx={{ whiteSpace: "nowrap" }}
            >
              {
                dayjsToNumberDayAndTimeArray(
                  reservation.dateFromD,
                  i18n.language
                )[0]
              }
            </Typography>
            <Typography
              align="center"
              variant={"body2"}
              sx={{ whiteSpace: "nowrap" }}
            >
              {
                dayjsToNumberDayAndTimeArray(
                  reservation.dateFromD,
                  i18n.language
                )[1]
              }
            </Typography>
          </Paper>
          <Typography align="center" variant={"body2"}>
            {t("TO").toLowerCase()}
          </Typography>
          <Paper
            sx={{
              backgroundColor: theme.palette.background.default,
              boxShadow: "none",
              width: "100%",
              display: "flex",
              gap: 2,
              justifyContent: "space-between",
              padding: 1.4,
              borderRadius: "10px",
            }}
          >
            <Typography
              align="center"
              variant={"body2"}
              sx={{ whiteSpace: "nowrap" }}
            >
              {
                dayjsToNumberDayAndTimeArray(
                  reservation.dateToD,
                  i18n.language
                )[0]
              }
            </Typography>
            <Typography
              align="center"
              variant={"body2"}
              sx={{ whiteSpace: "nowrap" }}
            >
              {
                dayjsToNumberDayAndTimeArray(
                  reservation.dateToD,
                  i18n.language
                )[1]
              }
            </Typography>
          </Paper>
        </span>
      </Paper>
    </span>
  );

  // <Grid
  //     container
  //     height={height}
  //     width={width}
  //     sx={{
  //         marginTop: "1px",
  //         borderRadius: "30px",
  //         marginLeft: modal ? "-17px" : "",
  //         boxShadow: modal
  //             ? `4px 4px 15px 3px ${theme.palette.shadow}`
  //             : "",
  //     }}
  // >
  //     <Grid
  //         item
  //         xs={12 - action}
  //         sx={{
  //             padding: 1,
  //             margin: 1,
  //         }}
  //     >
  //         {modal && (
  //             <Cool>
  //                 <img src="/hura.png" width="270px" />
  //             </Cool>
  //         )}

  //         <Typography
  //             textAlign="left"
  //             variant={size === "lg" ? "h5" : "body1"}
  //         >
  //             {`${reservation.assetName}`}
  //         </Typography>
  //         <Typography
  //             textAlign="left"
  //             variant={size === "lg" ? "h5" : "body2"}
  //         >
  //             {`${reservation.locationName}`}
  //         </Typography>
  //         <Typography
  //             textAlign="left"
  //             marginTop="5px"
  //             variant={size === "lg" ? "h6" : "body1"}
  //         >
  //             {`${reservation.reservedByFirstName} ${reservation.reservedBySurname} `}
  //         </Typography>
  //         <Typography
  //             textAlign="left"
  //             variant={size === "lg" ? "h6" : "body2"}
  //         >
  //             {`${reservation.reservedByEmail} `}
  //         </Typography>
  //         <Typography
  //             sx={{
  //                 width: "100%",
  //                 marginTop: 2,
  //                 transform: "translate(0, -40%)",
  //                 alignItems: "center",
  //             }}
  //             variant={size === "lg" ? "h6" : "body1"}
  //         >
  //             <div style={{ marginLeft: "2px", marginTop: "20px" }}>
  //                 <LicensePlate
  //                     licensePlate={reservation.licensePlate}
  //                     active
  //                 />
  //             </div>
  //         </Typography>
  //     </Grid>
  //     <Grid item xs={1} alignContent="center" sx={{ padding: 1 }}>
  //         {handleEdit &&
  //             ((!all &&
  //                 isComponentVisibleForUser(UC0101, userUseCases)) ||
  //                 (all &&
  //                     isComponentVisibleForUser(UC0106, userUseCases))) &&
  //             `${reservation.status}` !== "OLD" && (
  //                 <IconButton
  //                     color="primary"
  //                     aria-label="edit"
  //                     size="small"
  //                     onClick={() => {
  //                         if (
  //                             (!all &&
  //                                 isComponentEnabledForUser(
  //                                     UC0101,
  //                                     userUseCases,
  //                                 )) ||
  //                             (all &&
  //                                 isComponentEnabledForUser(
  //                                     UC0106,
  //                                     userUseCases,
  //                                 ))
  //                         ) {
  //                             handleEdit(reservation);
  //                         }
  //                     }}
  //                 >
  //                     <Edit fontSize="small" />
  //                 </IconButton>
  //             )}
  //     </Grid>

  //     <Grid item xs={1} alignContent="center" sx={{ padding: 1 }}>
  //         {handleDelete &&
  //             ((!all &&
  //                 isComponentVisibleForUser(UC0102, userUseCases)) ||
  //                 (all &&
  //                     isComponentVisibleForUser(UC0107, userUseCases))) &&
  //             `${reservation.status}` === "PLANNED" && (
  //                 <IconButton
  //                     color="primary"
  //                     aria-label="delete"
  //                     size="small"
  //                     onClick={() => {
  //                         if (
  //                             (!all &&
  //                                 isComponentEnabledForUser(
  //                                     UC0102,
  //                                     userUseCases,
  //                                 )) ||
  //                             (all &&
  //                                 isComponentEnabledForUser(
  //                                     UC0107,
  //                                     userUseCases,
  //                                 ))
  //                         ) {
  //                             handleDelete(reservation.reservationId);
  //                         }
  //                     }}
  //                 >
  //                     <DeleteIcon fontSize="small" />
  //                 </IconButton>
  //             )}
  //     </Grid>

  //     <Grid item xs={12} alignItems="center">
  //         <Tooltip>
  //             {renderImg(
  //                 reservation.img,
  //                 height,
  //                 width,
  //                 reservation.assetType,
  //             )}
  //         </Tooltip>
  //     </Grid>

  //     <Grid
  //         container
  //         sx={{
  //             display: "flex",
  //             justifyContent: "center",
  //             alignItems: "center",
  //         }}
  //     >
  //         <Grid
  //             item
  //             xs={12}
  //             sx={{
  //                 display: "flex",
  //                 justifyContent: "center",
  //                 alignItems: "center",
  //                 padding: 1,
  //                 margin: 1,
  //             }}
  //         >
  //             <Paper
  //                 sx={{
  //                     backgroundColor: theme.palette.background.grey,
  //                     boxShadow: "none",
  //                     maxWidth: "150px",
  //                     width: "100%",
  //                     marginLeft: 1,
  //                     paddingTop: 2,
  //                     paddingBottom: 2,
  //                 }}
  //             >
  //                 <Timewrapper>
  //                     <Typography
  //                         align="center"
  //                         variant={size === "lg" ? "h6" : "body2"}
  //                     >
  //                         {dayjsToDateTimeString(
  //                             reservation.dateFromD,
  //                             i18n.language,
  //                         )}
  //                     </Typography>
  //                 </Timewrapper>
  //             </Paper>

  //             <Typography
  //                 align="center"
  //                 sx={{ padding: 1 }}
  //                 variant={size === "lg" ? "h6" : "body1"}
  //             >
  //                 {t("TO").toLowerCase()}
  //             </Typography>

  //             <Paper
  //                 sx={{
  //                     backgroundColor: theme.palette.background.grey,
  //                     boxShadow: "none",
  //                     maxWidth: "150px",
  //                     width: "100%",
  //                     paddingTop: 2,
  //                     paddingBottom: 2,
  //                 }}
  //             >
  //                 <Timewrapper>
  //                     <Typography
  //                         align="center"
  //                         variant={size === "lg" ? "h6" : "body2"}
  //                     >
  //                         {dayjsToDateTimeString(
  //                             reservation.dateToD,
  //                             i18n.language,
  //                         )}
  //                     </Typography>
  //                 </Timewrapper>
  //             </Paper>
  //         </Grid>
  //     </Grid>
  // </Grid>
};

const ReservationDetail = ({
  reservation,
  userUseCases,
  handleDelete,
  handleEdit,
  height = "auto",
  width = "300px",
  all,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  if (!reservation) {
    return null;
  }

  return (
    <div>
      <ReservationDetailContent
        all={all}
        width={width}
        height={height}
        reservation={reservation}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        userUseCases={userUseCases}
      />
    </div>
  );
};

export default ReservationDetail;
