import httpService from './http.service';

const createLocation = (location) => httpService
    .post(`/locations`, location)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateLocation = (location) => httpService
    .put(`/locations`, location)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const activeLocation = (locationId, active) => httpService
    .put(`/locations/${locationId}/?active=${active}`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getLocations = () => httpService
    .get(`/locations`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));



export default {
    getLocations,
    createLocation,
    updateLocation,
    activeLocation,
};