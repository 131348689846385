import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Grid, IconButton, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import { fetchRolesByTenantId } from "../../../redux/actions/role";
import { fetchGroupsByTenantId } from "../../../redux/actions/group";


const CloseButton = styled(IconButton)(({ theme }) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText,
}));

function ReceiptForward(props) {
    const {
        handleClose,
        handleFowrard,
        open,
        receipt,
    } = props;

    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    useEffect(()=>{
        if ( receipt ) {
            setEmail(receipt.email);
        }
    }, [receipt]);

    const handleCloseLoc = () => {
        handleClose();
    };

    const handleEmail = (event) => {
        setEmail(event.target.value);
    }

    return (
        <Dialog
            maxWidth="md"
            sx={{
                "& .MuiDialog-paper": {
                    width: "100%",
                    margin: 1,
                },
            }}
            open={open}
            onClose={handleCloseLoc}
        >
            <DialogTitle
                sx={{
                    height: "100px",
                    paddingLeft: 5,
                    paddingRight: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <>
                    <Typography
                        variant="h4"
                        sx={{
                            color: (theme) =>
                                theme.palette.background.contrastText,
                        }}
                    >
                        {t('RECEIPT_FORWARD')}
                    </Typography>
                </>

                <CloseButton
                    aria-label="delete"
                    size="small"
                    onClick={handleCloseLoc}
                >
                    <ClearIcon fontSize="medium" />
                </CloseButton>
            </DialogTitle>

            <DialogContent>
                <Grid container >
                    <Grid item xs={7}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: (theme) =>theme.palette.background.contrastText,
                                paddingLeft: '15px', paddingTop: '10px'
                            }}
                        >
                            {t('RECEIPT_FORWARD_TEXT')}
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            variant="outlined"
                            type="search"
                            label={t("EMAIL")}
                            fullWidth
                            value={email || ""}
                            style={{paddingRight: '20px', marginTop: '10px'}}
                            onChange={handleEmail}
                        />
                    </Grid>
                    
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0,
                    height: 100,
                    width: "100%",
                }}
            >
                <Button
                    sx={{
                        width: "150px",
                        margin: 1,
                    }}
                    onClick={()=>{handleFowrard(receipt, email)}}
                    color="primary"
                    variant="outlined"
                >
                    {t("FORWARD")}{" "}
                </Button>
                <Button
                    sx={{
                        width: "150px",
                        margin: 1,
                    }}
                    onClick={handleCloseLoc}
                    color="secondary"
                    variant="outlined"
                >
                    {t("CLOSE")}{" "}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ReceiptForward.propTypes = {};

const mapStateToProps = (store) => ({
    roles: store.roleData.rolesByTenantId,
    groups: store.groupData.groupsByTenantId,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchRolesByTenantId,
            fetchGroupsByTenantId,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(ReceiptForward);
