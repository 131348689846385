import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import GroupUpdate from "./GroupUpdate";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import {styled} from "@mui/system";

const CloseButton = styled(IconButton)(({theme}) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText

}));

function Group(props) {
    const {
        userUseCases,
        locations,
        groups,
        handleClose,
        saveGroup,
        groupConfigModel,
        snackbarOpen,
        setSnackbarOpen,
        error,
        setError,
        saving,
        loggedUser,
    } = props;


    const {t} = useTranslation();

    const [updatedGroup, setUpdatedGroup] = useState();
    const [errorStep1, setErrorStep1] = useState('');
    const [files, setFiles] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState();

    useEffect(() => {
        if (groupConfigModel.group) {
            setUpdatedGroup(groupConfigModel.group);
            if (groupConfigModel.group.img != null) {
                setFiles([{preview: groupConfigModel.group.img}])
            }
        }
    }, [groupConfigModel]);

    const isValid = (isNew) => {
        let ret = true;
        const error = {};
        if (!updatedGroup.name || updatedGroup.name.trim().length === 0) {
            error.name = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        setErrorStep1(error);
        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const handleChange = (name) => (event) => {
        if (name === 'active') {
            setUpdatedGroup({...updatedGroup, [name]: event.target.checked === true});

        } else if (event && event.target) {
            setUpdatedGroup({...updatedGroup, [name]: event.target.value});
        } else {
            setUpdatedGroup({...updatedGroup, [name]: event});
        }
    }

    const handleSelectedLocation = (data) => {
        setUpdatedGroup({...updatedGroup, ['location']: data});
    }

    const handleSelectedGroup = (data) => {
        setUpdatedGroup({...updatedGroup, ['groups']: data});

    }

    const saveGroupLoc = (data) => {
        let tmp = {...updatedGroup, tenantId: selectedTenant.tenantId}
        setSelectedTenant(undefined);
        saveGroup(tmp);
    }

    const closeLoc = () => {
        setSelectedTenant(undefined);
        handleClose();
    }

    return (
        <Dialog
            maxWidth="xl"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '100%',
                    margin: 1,
                    
                },
            }}
            open={groupConfigModel.open}
            onClose={closeLoc}
        >

            <DialogTitle sx={{
                height: '100px',
                paddingLeft: 5,
                paddingRight: 5,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h4'
                                sx={{color: (theme) => theme.palette.background.contrastText}}>{t(groupConfigModel.readOnly ? 'DETAIL_GROUP' : (groupConfigModel.isNew ? 'CREATE_GROUP' : 'UPDATE_GROUP'))}</Typography>
                </>


                <CloseButton aria-label="delete" size="small" onClick={handleClose}>
                    <ClearIcon fontSize="medium"/>

                </CloseButton>

            </DialogTitle>




            <DialogContent sx={{padding: 8}}>
                <GroupUpdate
                    userUseCases={userUseCases}
                    handleChange={handleChange}
                    updatedGroup={updatedGroup}
                    error={errorStep1}
                    readOnly={groupConfigModel.readOnly}
                    setSnackbarError={setError}
                    setSnackbarOpen={setSnackbarOpen}
                    isNew={groupConfigModel.isNew}
                    setUpdatedGroup={setUpdatedGroup}
                    user={loggedUser}
                    setSelectedTenant={setSelectedTenant}
                    selectedTenant={selectedTenant}

                />
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0, height: 100, width: '100%'
                }}>


                {saving && <CircularProgress size={24}/>}

                {!groupConfigModel.readOnly &&

                    <Button
                        sx={{
                            width: '150px', margin: 1
                        }}
                        onClick={closeLoc}
                        color="secondary"
                        variant="outlined"
                        startIcon={<ArrowBackIosIcon/>}
                    >{t('CLOSE')} </Button>
                }

                {!groupConfigModel.readOnly &&
                    <>
                        <Button
                            sx={{
                                width: '150px', margin: 1
                            }}
                            disabled={saving}
                            loading={saving}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                const ret = isValid(groupConfigModel.isNew);
                                if (ret) {
                                    saveGroupLoc(updatedGroup);
                                }
                            }}
                            endIcon={<SaveIcon/>}
                        > {t('SAVE')}</Button>
                    </>
                }

            </DialogActions>


        </Dialog>

    );
}

Group.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Group);


