import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import AssetReleaseInner from "./AssetReleaseInner";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import {addGroupToAsset} from "../../../redux/actions/asset";
import ClearIcon from "@mui/icons-material/Clear";
import {styled} from "@mui/system";
import {DATE_TIME_FORMAT} from "../../../utils/constants";
import dayjs from "dayjs";

const CloseButton = styled(IconButton)(({theme}) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText

}));

function AssetRelease(props) {
    const {
        userUseCases,
        groups,
        handleClose,
        assetConfigModel,
        snackbarOpen,
        setSnackbarOpen,
        error,
        setError,
        saving,
        loggedUser,
        saveAsset
    } = props;


    const date1From = dayjs().add(1, 'day').set('hour', 9).set('m', 0);
    const date1To = dayjs().add(2, 'day').set('hour', 20).set('m', 0);
    const date2From = dayjs().add(1, 'day').set('hour', 9).set('m', 0);
    const date2To = dayjs().add(4, 'day').set('hour', 20).set('m', 0);
    const date3From = dayjs().add(1, 'day').set('hour', 9).set('m', 0);
    const date3To = dayjs().add(8, 'day').set('hour', 20).set('m', 0);


    const {t} = useTranslation();

    const [groupsToBeAdded, setGroupsToBeAdded] = useState({
        groups: [],
        interval: '3DAY'
    });
    const [updatedAsset, setUpdatedAsset] = useState();

    const [errorStep1, setErrorStep1] = useState('');
    const [groupNotAdded, setGroupNotAdded] = useState(true);


    useEffect(() => {
        if (assetConfigModel.asset) {
            setUpdatedAsset(assetConfigModel.asset);
        }
    }, [assetConfigModel]);

    const isValid = () => {
        let ret = true;
        const error = {};
        if (groupsToBeAdded.groups.length === 0) {
            error.groups = t('THIS_FIELD_IS_REQUIRED');
            ret = false;

        }

        if (groupsToBeAdded.interval === 'CUSTOM') {
            if (!groupsToBeAdded.dateFrom) {
                error.dateFrom = t('THIS_FIELD_IS_REQUIRED');
                ret = false;

            }


            if (!groupsToBeAdded.dateTo && groupsToBeAdded.interval === 'CUSTOM') {
                error.dateTo = t('THIS_FIELD_IS_REQUIRED');
                ret = false;

            }
            if (groupsToBeAdded.dateTo && groupsToBeAdded.dateFrom) {
                if (groupsToBeAdded.dateTo.isBefore(groupsToBeAdded.dateFrom)) {
                    error.dateTo = ' ';
                    ret = false;
                }

            }


        }


        console.log('error', error);
        setErrorStep1(error);
        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const handleChange = (name) => (event) => {
        if (name === 'interval') {
            setGroupsToBeAdded({...groupsToBeAdded, interval: event?.target?.value});
        } else if (name === 'groups') {
            setGroupsToBeAdded({...groupsToBeAdded, 'groups': event});
        } else if (name === 'dateFrom') {
            setGroupsToBeAdded({...groupsToBeAdded, 'dateFrom': event});
        } else if (name === 'dateTo') {
            setGroupsToBeAdded({...groupsToBeAdded, 'dateTo': event});
        } else if (name === 'timeFrom') {
            let dateFrom = groupsToBeAdded.dateFrom;
            if (!dateFrom) {
                dateFrom = dayjs();
            }
            dateFrom = dateFrom.hour(event.hour()).minute(event.minute());
            setGroupsToBeAdded({...groupsToBeAdded, 'dateFrom': dateFrom});

        } else if (name === 'timeTo') {
            let dateTo = groupsToBeAdded.dateTo;
            if (!dateTo) {
                dateTo = dayjs();
            }
            dateTo = dateTo.hour(event.hour()).minute(event.minute());
            setGroupsToBeAdded({...groupsToBeAdded, 'dateTo': dateTo});
        }

    }

    const removeGroupFromAsset = (groupToBeRemoved) => {
        const groupsM = updatedAsset.groups.filter(a => a.assetGroupId != groupToBeRemoved.assetGroupId);
        setGroupNotAdded(false);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                setUpdatedAsset({
                    ...updatedAsset,
                    groups: [...groupsM]
                });
                resolve();
            }, 500);
        });
    }

    const addGroupsToAsset = () => {
        let dateFromD = groupsToBeAdded.dateFrom;
        let dateToD = groupsToBeAdded.dateTo;
        if ('1DAY' === groupsToBeAdded.interval) {
            dateFromD = date1From;
            dateToD = date1To;
        } else if ('3DAY' === groupsToBeAdded.interval) {
            dateFromD = date2From;
            dateToD = date2To;
        } else if ('7DAY' === groupsToBeAdded.interval) {
            dateFromD = date3From;
            dateToD = date3To;
        }


        let groupsCopy = [...updatedAsset.groups];
        for (let i = 0; i < groupsToBeAdded.groups.length; i++) {
            groupsCopy.push({
                ...groupsToBeAdded.groups[i],
                dateFromD,
                dateToD,
                dateFrom: dateFromD ? dateFromD.format(DATE_TIME_FORMAT) : null,
                dateTo: dateToD ? dateToD.format(DATE_TIME_FORMAT) : null,
                label: groupsToBeAdded.groups[i].label
            });
        }
        setUpdatedAsset({...updatedAsset, 'groups': groupsCopy});
        setGroupNotAdded(false);
        setGroupsToBeAdded({
            groups: [],
            interval: '3DAY'
        });
    }


    return (

        <Dialog
            maxWidth="xl"
            sx={{
                zIndex: 1000,
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '100%',
                    margin: 1,
                },
            }}
            open={assetConfigModel.open}
            onClose={handleClose}
        >


            <DialogTitle sx={{
                height: '100px',
                paddingLeft: '40px',
                paddingTop: '30px',
                display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h6'
                                sx={{color: (theme) => theme.palette.background.contrastText}}>{t('RELEASE')}</Typography>
                </>


                <CloseButton aria-label="delete" size="small" onClick={handleClose}>
                    <ClearIcon fontSize="medium"/>

                </CloseButton>

            </DialogTitle>


            <DialogContent>
                <AssetReleaseInner
                    userUseCases={userUseCases}
                    handleChange={handleChange}
                    updatedAsset={updatedAsset}
                    isValid={isValid}
                    error={errorStep1}
                    readOnly={assetConfigModel.readOnly}
                    setSnackbarError={setError}
                    setSnackbarOpen={setSnackbarOpen}
                    groups={groups}
                    setUpdatedAsset={setUpdatedAsset}
                    groupsToBeAdded={groupsToBeAdded}
                    addGroupsToAsset={addGroupsToAsset}
                    removeGroupFromAsset={removeGroupFromAsset}
                    dates={{date1From, date1To, date2From, date2To, date3From, date3To}}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    padding: '30px', height: 100, width: '100%'
                }}>

                {saving && <CircularProgress size={24}/>}

                {!assetConfigModel.readOnly &&

                    <Button
                        sx={{
                            width: '150px', margin: 1
                        }}
                        onClick={handleClose}
                        color="secondary"
                        variant="outlined"
                        startIcon={<ArrowBackIosIcon/>}
                    >{t('CLOSE')} </Button>
                }

                {!assetConfigModel.readOnly &&
                    <>
                        <Button
                            sx={{
                                width: '150px', margin: 1
                            }}
                            disabled={groupNotAdded || saving}
                            loading={saving}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                saveAsset(updatedAsset);
                            }}
                            endIcon={<SaveIcon/>}
                        > {t('SAVE')}</Button>
                    </>
                }

            </DialogActions>


        </Dialog>

    );
}

AssetRelease.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({addGroupToAsset}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AssetRelease);


