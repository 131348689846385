import types from "../actionTypes";

const INITIAL_STATE = {
    isFetchingSetting: false,
    isUpdating: false,
    setting: [],
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.SETTING_UPDATE_SUCCESS:
            return {
                ...state, isUpdating: false
            };
        case types.SETTING_UPDATE_REQUEST:
            return {
                ...state, isUpdating: true
            };
        case types.SETTING_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: true,
                errors: action.payload.error,
            };
        case types.SETTING_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingSetting: false,
                setting: action.payload.setting,
            };
        case types.SETTING_FETCH_FAIL:
            return {
                ...state,
                isFetchingSetting: false,
                errors: action.payload.error,
            };
        case types.SETTING_FETCH_REQUEST:
            return {
                ...state,
                isFetchingSetting: true,
            };
        default:
            return state;
    }
};
