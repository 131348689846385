import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Paper from "@mui/material/Paper";
import SettingView from "./SettingView";
import ToolBar from "./ToolBar";

function Setting(props) {
  const { user } = props;

  return (
    <>
      <ToolBar />

      <Paper
        sx={{
          width: "86%",
          marginLeft: "30px",
          marginTop: "30px",
          overflow: "hidden",
          height: "86%",
          backgroundColor: (theme) => theme.palette.background.grey,
        }}
      >
        <SettingView user={user} />
      </Paper>
    </>
  );
}

const mapStateToProps = (store) => ({
  user: store.authData.user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
