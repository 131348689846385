import types from '../actionTypes';
import codelistService from '../../services/codelist.service';

export const fetchCodeLists = () => (dispatch, getState) => {
    if (getState().codelistData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.CODELIST_FETCH_REQUEST,
    });

    return codelistService.getCodeLists()
        .then((codelists) => {
            dispatch({
                type: types.CODELIST_FETCH_SUCCESS,
                payload: { codelists: codelists },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.CODELIST_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};