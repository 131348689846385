import React from 'react';
import {useTranslation} from 'react-i18next';
import {getAMPM} from "../../i18n";
import {styled} from "@mui/system";
import {TimePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/cs';
import 'dayjs/locale/ru';
import 'dayjs/locale/ro';
import 'dayjs/locale/pl';

const CustomTimePickerCss = styled(TimePicker)(({theme}) => ({}));

export default function CustomTimePicker({
                                             time,
                                             setTime,
                                             error,
                                             disableTimeFrom
                                         }) {
    const {t, i18n} = useTranslation();

    const disableFromFce = (date) => {
        return disableTimeFrom ? date.hour() <= disableTimeFrom : false;
    }

    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>

            <CustomTimePickerCss
                value={time}
                onChange={(val) => setTime(val)}
                minutesStep={30}
                timeSteps={{hours: 1, minutes: 30}}
                desktopModeMediaQuery="(min-width:600px)"
                ampm={getAMPM(i18n.language)}
                slotProps={{
                    textField: {
                        error: !!error,
                        helperText: error
                    }
                }}
                shouldDisableTime={disableFromFce}
            />
        </LocalizationProvider>
    );
}

