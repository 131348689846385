import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getCodeList, getErrorMessage } from "../../../utils";
import ToolBar from "./ToolBar";
import UserList from "./UserList";
import {
    createUser,
    fetchAllUsers,
    updateUser,
    updateUserState,
} from "../../../redux/actions/users";
import { fetchRoles } from "../../../redux/actions/role";
import { fetchCodeLists } from "../../../redux/actions/codelists";
import User from "../../../components/modal/UserUpdate";
import Paper from "@mui/material/Paper";
import PDSSnackbar from "../../../components/common/Snackbar";
import { fetchAllGroups } from "../../../redux/actions/group";
import { Skeleton } from "@mui/material";

function UserAdministration(props) {
    const {
        userUseCases,
        roles,
        groups,
        users,
        createUser,
        updateUser,
        updateUserState,
        fetchAllUsers,
        isFetchingAllList,
        user,
        fetchAllGroups,
        fetchRoles,
    } = props;

    const { t } = useTranslation();

    const [isFetchedUser, setIsFetchedUser] = useState(false);
    const [isFetchingAllGroups, setIsFetchingAllGroups] = useState(false);
    const [isFetchingRoles, setIsFetchingRoles] = useState(false);

    const [saving, setSaving] = useState(false);
    const [headerChecked, setHeaderChecked] = useState(0);
    const [userConfigModel, setUserConfigModel] = useState({
        open: false,
        user: {},
    });

    useEffect(() => {
        if (!isFetchedUser) {
            fetchAllUsers()
                .then(() => {
                    setIsFetchedUser(true);
                })
                .catch((err) => {
                    console.log(err);
                    setIsFetchedUser(false);
                });
        }

        if (!isFetchingAllGroups) {
            fetchAllGroups()
                .then(() => {
                    setIsFetchingAllGroups(true);
                })
                .catch((err) => {
                    console.log(err);
                    setIsFetchingAllGroups(false);
                });
        }

        if (!isFetchingRoles) {
            fetchRoles()
                .then(() => {
                    setIsFetchingRoles(true);
                })
                .catch((err) => {
                    console.log(err);
                    setIsFetchingRoles(false);
                });
        }
    }, [
        fetchAllUsers,
        isFetchedUser,
        isFetchingAllGroups,
        fetchAllGroups,
        fetchRoles,
        isFetchingRoles,
    ]);

    const [selectedName, setSelectedName] = useState("");
    const [selectedUCName, setSelectedUCName] = useState("");

    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedAdminRole, setSelectedAdminRole] = useState();
    const [selectedLicensePlate, setSelectedLicensePlate] = useState("");

    const [selectedRole, setSelectedRole] = useState();

    const [selectedGroup, setSelectedGroup] = useState();

    const [table, setTable] = useState(false);

    const handleSelectedName = (value) => {
        setSelectedName(value.target.value);
    };
    const handleSelectedUCName = (value) => {
        setSelectedUCName(value.target.value);
    };
    const handleSelectedStatus = (value) => {
        console.log(value);
        setSelectedStatus(value);
    };
    const handleSelectedAdminRole = (value) => {
        setSelectedAdminRole(value);
    };
    const handleSelectedRole = (value) => {
        setSelectedRole(value);
    };

    const handleSelectedGroup = (value) => {
        setSelectedGroup(value);
    };

    const matchStatus = (user) => {
        let res = false;
        for (let i = 0; i < selectedStatus.length; i++) {
            res =
                (selectedStatus[i].key === "ACTIVE" && user.active === true) ||
                (selectedStatus[i].key === "INACTIVE" && user.active === false);
            if (res) {
                return true;
            }
        }
        return false;
    };

    const matchAdmin = (user) => {
        let res = false;
        for (let i = 0; i < selectedAdminRole.length; i++) {
            res =
                (selectedAdminRole[i].key === "YES" && user.admin === true) ||
                (selectedAdminRole[i].key === "NO" && user.admin === false);
            if (res) {
                return true;
            }
        }
        return false;
    };

    const matchRole = (user) => {
        for (let i = 0; i < selectedRole.length; i++) {
            if (user.roles) {
                for (let j = 0; j < user.roles.length; j++) {
                    if (user.roles[j].roleId == selectedRole[i].key)
                        return true;
                }
            }
        }
        return false;
    };

    const matchGroup = (user) => {
        for (let i = 0; i < selectedGroup.length; i++) {
            for (let j = 0; j < user.groups.length; j++) {
                if (
                    user.groups[j].groupId == selectedGroup[i].key &&
                    user.groups[j].type === "R"
                )
                    return true;
            }
        }
        return false;
    };

    useEffect(() => {
        const filUser = [];
        if (users) {
            for (let i = 0; i < users.length; i++) {
                let matchCriteria = true;
                if (selectedName && selectedName.trim().length > 0) {
                    if (
                        (!users[i].firstName ||
                            !users[i].firstName
                                .toLowerCase()
                                .includes(selectedName.toLowerCase())) &&
                        (!users[i].lastName ||
                            !users[i].lastName
                                .toLowerCase()
                                .includes(selectedName.toLowerCase())) &&
                        (!users[i].username ||
                            !users[i].username
                                .toLowerCase()
                                .includes(selectedName.toLowerCase()))
                    ) {
                        matchCriteria = false;
                    }
                }

                if (selectedStatus && selectedStatus.length > 0) {
                    const res = matchStatus(users[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (selectedAdminRole && selectedAdminRole.length > 0) {
                    const res = matchAdmin(users[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (
                    selectedLicensePlate &&
                    selectedLicensePlate.trim().length > 0
                ) {
                    const resLic = users[i].licensePlate;
                    const resLicNoSpace = resLic
                        ? resLic.replace(/ /g, "")
                        : null;
                    if (
                        (!resLic ||
                            !resLic
                                .toLowerCase()
                                .includes(
                                    selectedLicensePlate.toLowerCase(),
                                )) &&
                        (!resLicNoSpace ||
                            !resLicNoSpace
                                .toLowerCase()
                                .includes(selectedLicensePlate.toLowerCase()))
                    ) {
                        matchCriteria = false;
                    }
                }

                if (selectedRole && selectedRole.length > 0) {
                    const res = matchRole(users[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (selectedGroup && selectedGroup.length > 0) {
                    const res = matchGroup(users[i]);
                    if (!res) {
                        matchCriteria = false;
                    }
                }

                if (matchCriteria) {
                    filUser.push(users[i]);
                }
            }
        }
        setFilteredUsers(filUser);
    }, [
        users,
        selectedName,
        selectedGroup,
        selectedStatus,
        selectedAdminRole,
        selectedLicensePlate,
        selectedRole,
    ]);

    const [filteredUsers, setFilteredUsers] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("error");

    const [rolesValues, setRolesValues] = useState();
    const [groupsValues, setGroupsValues] = useState();

    const [codeList, setCodeList] = useState();
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);

    useEffect(() => {
        if (roles) {
            setRolesValues(
                roles.map((a) => {
                    return { value: a.roleId, label: a.name, key: a.roleId };
                }),
            );
        }
    }, [roles]);

    useEffect(() => {
        if (groups) {
            setGroupsValues(
                groups.map((a) => {
                    return {
                        value: a.groupId,
                        label: a.name,
                        key: a.groupId,
                        lightColor: a.lightColor,
                        darkColor: a.darkColor,
                    };
                }),
            );
        }
    }, [groups]);

    const [subjectValues, setSubjectValues] = useState();

    const closeUserDetail = () => {
        setUserConfigModel({
            isNew: false,
            user: {},
            open: false,
            readOnly: false,
        });
    };

    const handleUserUpdate = (updatedUser, isNew, readOnly) => {
        // parking ID of user
        let user = { ...updatedUser };
        if (user.groups != null) {
            const placeGroups = user.groups.filter((a) => a.type === "P");
            user.groups = user.groups
                .filter((a) => a.type === "R")
                .map((a) => {
                    return {
                        value: a.groupId,
                        label: a.name,
                        key: a.groupId,
                        lightColor: a.lightColor,
                        darkColor: a.darkColor,
                        type: a.type,
                    };
                });

            user.managedGroups = placeGroups.map((a) => {
                return {
                    value: a.groupId,
                    label: a.name,
                    key: a.groupId,
                    lightColor: a.lightColor,
                    darkColor: a.darkColor,
                    type: a.type,
                };
            });
        }
        if (user.roles != null) {
            user.roles = user.roles.map((a) => {
                return {
                    value: a.roleId,
                    label: a.name,
                    key: a.roleId,
                };
            });
        }

        setSnackbarMessage("");
        setSnackbarOpen(false);
        setUserConfigModel({
            isNew,
            user: user,
            open: true,
            readOnly: readOnly,
        });
    };

    const handleNewUser = () => {
        setUserConfigModel({
            isNew: true,
            user: {},
            open: true,
            readOnly: false,
        });
    };

    const handleActivate = (user) => {
        updateUser({ ...user, active: !user.active })
            .then(() => {
                setConfirmModalOpened(false);
                setIsFetchedUser(false);
            })
            .catch((err) => {
                console.log(err);
                setIsFetchedUser(false);
            });
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setSnackbarOpen(false);
    };

    const saveUser = async (user) => {
        setSaving(true);
        user = { ...user };
        if (user.groups != null) {
            user.groups = user.groups.map((a) => {
                return {
                    groupId: a.value,
                    name: a.label,
                    lightColor: a.lightColor,
                    darkColor: a.darkColor,
                    type: "R",
                };
            });
        }

        if (user.managedGroups != null) {
            if (user.groups === null) {
                user.groups = [];
            }
            for (let i = 0; i < user.managedGroups.length; i++) {
                user.groups.push({
                    groupId: user.managedGroups[i].value,
                    name: user.managedGroups[i].label,
                    lightColor: user.managedGroups[i].lightColor,
                    darkColor: user.managedGroups[i].darkColor,
                    type: "P",
                });
            }
        }

        if (user.roles != null) {
            user.roles = user.roles.map((a) => {
                return {
                    roleId: a.value,
                    name: a.label,
                };
            });
        }
        const action = user && user.id ? updateUser : createUser;
        try {
            await action(user);
            setIsFetchedUser(false);
            setSaving(false);
            setSnackbarMessage(t("USER_UPDATED"));
            setSnackbarSeverity("success");
            setSnackbarOpen(true);
            closeUserDetail();
            setIsFetchedUser(false);
        } catch (error) {
            setSaving(false);
            setSnackbarMessage(getErrorMessage(error, t));
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };
    return (
        <>
            <PDSSnackbar
                open={snackbarOpen}
                onClose={handleCloseSnackbar}
                severity={snackbarSeverity}
                message={snackbarMessage}
            />

            {!isFetchingAllList && isFetchedUser ? (
                <ToolBar
                    userUseCases={userUseCases}
                    selectedName={selectedName}
                    handleSelectedName={handleSelectedName}
                    selectedUCName={selectedUCName}
                    handleSelectedUCName={handleSelectedUCName}
                    selectedStatus={selectedStatus}
                    handleSelectedStatus={handleSelectedStatus}
                    selectedAdminRole={selectedAdminRole}
                    handleSelectedAdminRole={handleSelectedAdminRole}
                    selectedRole={selectedRole}
                    selectedGroup={selectedGroup}
                    handleSelectedRole={handleSelectedRole}
                    handleSelectedGroup={handleSelectedGroup}
                    groups={groupsValues}
                    roles={rolesValues}
                    subjects={subjectValues}
                    user={user}
                    setSelectedLicensePlate={setSelectedLicensePlate}
                    selectedLicensePlate={selectedLicensePlate}
                    handleNewUser={handleNewUser}
                />
            ) : (
                <Skeleton
                    height={200}
                    sx={{
                        width: "86%",
                        marginLeft: "30px",
                    }}
                    animation="wave"
                />
            )}

            {!isFetchingAllList && isFetchedUser ? (
                <div>
                    <UserList
                        refresh={() => {
                            setIsFetchedUser(false);
                        }}
                        table={table}
                        save={saveUser}
                        userUseCases={userUseCases}
                        users={filteredUsers}
                        handleUserDetail={handleUserUpdate}
                        isLoading={isFetchingAllList || !isFetchedUser}
                        handleActivate={handleActivate}
                        confirmModalOpened={confirmModalOpened}
                        setConfirmModalOpened={setConfirmModalOpened}
                    />

                    <User
                        userConfigModel={userConfigModel}
                        userUseCases={userUseCases}
                        saveUser={saveUser}
                        handleClose={closeUserDetail}
                        snackbarMessage={snackbarMessage}
                        snackbarSeverity={snackbarSeverity}
                        snackbarOpen={snackbarOpen}
                        setSnackbarOpen={setSnackbarOpen}
                        codeList={codeList}
                        saving={saving}
                        user={user}
                    />
                </div>
            ) : (
                <Skeleton
                    variant="rounded"
                    height="calc(100vh - 200px)"
                    sx={{
                        width: "86%",
                        marginLeft: "30px",
                    }}
                    animation="wave"
                />
            )}
        </>
    );
}

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    roles: store.roleData.roles,
    users: store.usersData.parkingUsers,
    isFetchingAllList: store.usersData.isFetchingParkingList,
    allCodeList: store.codelistData.allCodeList,
    groups: store.groupData.groups,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchAllUsers,
            createUser,
            updateUser,
            updateUserState,
            fetchCodeLists,
            fetchAllGroups,
            fetchRoles,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(UserAdministration);
