import React from "react";
import { useTranslation } from "react-i18next";
import { dateTimetoIso, isComponentEnabledForUser, isComponentVisibleForUser } from "../../../utils";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { UC0181, UC0182 } from "../../../utils/constants";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";

import { Avatar, Button, useTheme } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import LicensePlate from "../../../components/common/LicensePlate";
import CustomTable from "../../../components/common/CustomTable"
import ReceiptIcon from '@mui/icons-material/Receipt';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

function ReceiptsList(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        receipts,
        userUseCases,
        handleReceiptDetail,
        table,
        handleReceiptForward,
    } = props;

    const renderUserState = (param) => {
        if (!param.licensePlate) {
            if (param.active) {
                return (
                    <Avatar variant="outlined" title={t("USER_ACTIVE")}>
                        <PersonIcon style={{ fontSize: 40 }} />
                    </Avatar>
                );
            } else {
                return (
                    <Avatar variant="outlined" title={t("USER_INACTIVE")}>
                        <PersonOffIcon style={{ fontSize: 40 }} />
                    </Avatar>
                );
            }
        } else {
            return (
                <LicensePlate
                    licensePlate={param.licensePlate}
                    active={param.active}
                />
            );
        }
    };

    const CARD_SIZE = 400;

    const Row = ({ index, data, style }) => {
        const { receipts, itemsPerRow } = data;

        if (!receipts) {
            return null;
        }

        const items = [];
        const fromIndex = index * itemsPerRow;

        const toIndex = Math.min(fromIndex + itemsPerRow, receipts.length);

        for (let i = fromIndex; i < toIndex; i++) {
            let receiptItem = receipts[i];
            items.push(
                <Paper
                    key={receiptItem.userId}
                    elevation={0}
                    sx={{
                        width: CARD_SIZE,
                        height: 150,
                        padding: 1,
                        margin: 1,
                        backgroundColor: (theme) =>
                            theme.palette.background.paper,
                        boxShadow: `1px 1px 20px 1px ${theme.palette.shadow}`,
                    }}
                >
                    <Grid
                        container
                        alignItems="center"
                        marginLeft="0px"
                        style={{ padding: 5 }}
                    >
                        <Grid item xs={2}>
                            <div
                                style={{
                                    paddingLeft: "6px",
                                    paddingTop: "6px",
                                }}
                            >
                                {renderUserState(receiptItem)}
                            </div>
                        </Grid>
                        <Grid item xs={8}>
                            <Tooltip title={t("RECEIPT_DETAIL")}>
                                <Typography
                                    marginLeft="10px"
                                    marginTop="3px"
                                    onClick={() => {
                                        isComponentVisibleForUser(
                                            UC0181,
                                            userUseCases,
                                        ) &&
                                            handleReceiptDetail(
                                                receiptItem,
                                                false,
                                                true,
                                            );
                                    }}
                                    sx={{
                                        cursor: isComponentVisibleForUser(
                                            UC0181,
                                            userUseCases,
                                        )
                                            ? "pointer"
                                            : "default",
                                        fontWeight: isComponentVisibleForUser(
                                            UC0181,
                                            userUseCases,
                                        )
                                            ? 600
                                            : "inherit",
                                    }}
                                    variant="body1"
                                >
                                    {receiptItem.parkingPlace.name}
                                </Typography>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={2}>
                            {isComponentVisibleForUser(UC0182, userUseCases) &&
                                <Tooltip title={t("FORWARD")}>
                                    <Button 
                                        onClick={() => {handleReceiptForward(receiptItem)}}
                                        disabled={!isComponentEnabledForUser(UC0182, userUseCases)}
                                    > 
                                        <ForwardToInboxIcon />
                                    </Button>
                                </Tooltip>
                            }
                        </Grid>

                        <Grid item xs={6} sx={{ paddingTop: 1 }}>
                            <Typography
                                marginLeft="10px"
                                variant="h4"
                            >
                                {receiptItem.amount + ' ' + receiptItem.currency}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sx={{ paddingTop: 1 }}>
                            <Typography
                                marginLeft="10px"
                                variant="body2"
                            >
                                {'('+receiptItem.tax + ' % DPH)'}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ paddingTop: 1 }}>
                            <Typography
                                marginLeft="10px"
                                variant="body2"
                            >
                                {'id:'+receiptItem.receiptId}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} sx={{ paddingTop: 1 }}>
                            <Typography
                                marginLeft="10px"
                                variant="body2"
                            >
                                {receiptItem.lpn}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sx={{ paddingTop: 0 }}>
                            <Typography
                                marginLeft="10px"
                                variant="body2"
                            >
                                {t('FROM') +': '+ dateTimetoIso(receiptItem.dateFrom) + ' - ' + t('TO') + ': ' + dateTimetoIso(receiptItem.dateTo) }
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>,
            );
        }

        return (
            <div style={style}>
                <div
                    style={{
                        display: "flex",
                        padding: "20px",
                        justifyContent: "flex-start",
                        height: "100%",
                    }}
                >
                    {items}
                </div>
            </div>
        );
    };

    const cellStyle = {
        paddingLeft: 4,
        paddingRight: 4,
        lineHeight: '19px',
        color: '#1B1B28',
    };

    const columns = [
        {
            title: t('AMOUNT'),
            field: 'amount',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5,
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "7%",
            render: (rowData) => rowData.amount
        },
        {
            title: t('CURRENCY'),
            field: 'currency',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "7%",
            render: (rowData) => (
                rowData.currency
            ),
        },
        {
            title: t('DPH'),
            field: 'dph',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "6%",
            render: (rowData) => (
                rowData.tax
            )
        },
        {
            title: t('DATE_FROM'),
            field: 'dateFrom',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "20%",
            render: (rowData) => (
                dateTimetoIso(rowData.dateFrom)
            )
        },
        {
            title: t('DATE_TO'),
            field: 'dateTo',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "20%",
            render: (rowData) => (
                dateTimetoIso(rowData.dateTo)
            ),
        },
        {
            title: t('LPN'),
            field: 'lpn',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "10%",
            render: (rowData) => (
                rowData.lpn
            ),
        },
        {
            title: t('PLACE'),
            field: 'place',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "20%",
            render: (rowData) => (
                rowData.parkingPlace.name
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            width: "10%",
            render: (rowData) => (
                <div >
                    {isComponentVisibleForUser(UC0181, userUseCases) &&
                        <Tooltip title={t("RECEIPT_DETAIL")}>
                            <Button
                                onClick={() => {handleReceiptDetail(rowData)}}
                                disabled={!isComponentEnabledForUser(UC0181, userUseCases)}
                            > 
                                <ReceiptIcon />
                            </Button>
                        </Tooltip>
                    }
                    {isComponentVisibleForUser(UC0182, userUseCases) &&
                        <Tooltip title={t("FORWARD")}>
                            <Button 
                                onClick={() => {handleReceiptForward(rowData)}}
                                disabled={!isComponentEnabledForUser(UC0182, userUseCases)}
                            > 
                                <ForwardToInboxIcon />
                            </Button>
                        </Tooltip>
                    }
                </div>
            ),
        },

    ];

    const tableOptions = {
        headerStyle: {
            padding: '15px 8px 15px 34px',
            lineHeight: '12px',
            color: (theme) => theme.palette.background.contrastText,
            textAlign: 'center',
        },
        paging: false
    };

    return (
        <div style={{ marginTop: "10px", height: "calc(100vh - 200px)" }}>
            {table && 
                <div style={{padding: '30px'}}>
                    <CustomTable
                        title=""
                        columns={columns}
                        data={receipts}
                        options={tableOptions}
                        isLoading={receipts ? false : true}
                        loadAllData={false}
                    />
                </div>
            }
            {!table &&
                <AutoSizer>
                    {({ height, width }) => {
                        const itemsPerRow = Math.floor(width / CARD_SIZE) || 1; // A calculation to establish how many cards will go on each row.

                        // The || 1 part is a simple hack that makes it work in a really small viewport (if someone totally collapses the window)

                        const rowCount = Math.ceil(receipts.length / itemsPerRow); // List will need the number of rows in order to be able to properly know what to render and what not to
                        return (
                            <div>
                                <List
                                    height={height}
                                    itemCount={rowCount}
                                    itemData={{ itemsPerRow, receipts: receipts }}
                                    itemSize={170}
                                    width={width}
                                >
                                    {Row}
                                </List>
                            </div>
                        );
                    }}
                </AutoSizer>
            }
        </div>
    );
}

export default ReceiptsList;
