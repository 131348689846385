import React from "react";
import { styled } from "@mui/system";

const Wrapper = styled("div")(({ theme }) => ({
    width: "80px",
    margin: "5px 1px",
}));

const WrapperOff = styled("div")(({ theme }) => ({
    width: "80px",
    margin: "5px 1px",
    opacity: 0.5,
}));

const Container = styled("span")(({ theme }) => ({
    padding: "7px 5px 7px 20px",
    fontWeight: 800,
    fontSize: "9px",
    borderRadius: "5px",
    border: `1px solid rgba(0,0,0,0.5)`,
    boxShadow: "2px 2px 2px #ddd",
    position: "relative",
    color: theme.palette.secondary.selection,
    "&::before": {
        content: '" "',
        display: "block",
        width: "15px",
        height: "25px",
        background: "#063298",
        position: "absolute",
        top: 0,
        borderRadius: "5px 0 0 5px",
        color: "#f8d038",
        fontSize: ".5em",
        lineHeight: "25px",
        paddingLeft: "1px",
    },
    "&::after": {
        content: '""',
        display: "block",
        position: "absolute",
        top: "7px",
        left: "3px",
        width: "7px",
        height: "7px",
        borderRadius: "3px",
        border: "1px dotted #f8d038",
    },
}));

export default function LicensePlate({ licensePlate, active }) {
    if (active) {
        return (
            <Wrapper>
                <Container>{licensePlate}</Container>
            </Wrapper>
        );
    } else {
        return (
            <WrapperOff>
                <Container>{licensePlate}</Container>
            </WrapperOff>
        );
    }
}
