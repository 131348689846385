import React from 'react';
import {useTranslation} from 'react-i18next';
import TextField from "@mui/material/TextField";
import {Chip} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";


export default function LocationAutocomplete({
                                              selectedLocations,
                                              locations,
                                              setLocations,
                                              multiple = true,
                                                 disabled=false,
                                                 required = false, error
                                             }) {
    const {t} = useTranslation();


    return (
        <Autocomplete
            multiple={multiple}
            fullWidth
            value={selectedLocations || (multiple ? [] : null)}
            onChange={(event, newValue) => {
                setLocations(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
                return option.value === value.value
            }
            }
            disabled={disabled}
            filterSelectedOptions
            id="location"
            options={locations || []}
            getOptionLabel={(option) => {
                return option.label;
            }}
            style={{width: '100%'}}
            renderInput={(params) => (
                <TextField variant="outlined" required={required} error={!!error} helperText={error} {...params} label={t('LOCATION')} fullWidth/>
            )}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => {
                        return <Chip
                            style={{
                                backgroundColor: option.lightColor,
                                color: 'black'
                            }}
                            variant="outlined"
                            label={option.label}
                            {...getTagProps({index})}
                        />
                    }
                )
            }
            }
        />
    );
}

