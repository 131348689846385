import types from "../actionTypes";

const INITIAL_STATE = {
    isFetchingParkingPlaces: false,
    parkingPlaces: [],
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.PARKING_PLACE_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingParkingPlaces: false,
                parkingPlaces: action.payload.parkingPlaces,
            };
        case types.PARKING_PLACE_FETCH_FAIL:
            return {
                ...state,
                isFetchingParkingPlaces: false,
                errors: action.payload.error,
            };
        case types.PARKING_PLACE_FETCH_REQUEST:
            return {
                ...state,
                isFetchingParkingPlaces: true,
            };
        default:
            return state;
    }
};
