import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import Add from "@mui/icons-material/Add";
import { isComponentEnabledForUser, isComponentVisibleForUser } from '../../utils';
import { UC0100, UC0105 } from '../../utils/constants';
import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import TextField from "@mui/material/TextField";
import LocationAutocomplete from "../../components/common/LocationAutocomplete";
import ToolBarCommon from "../../components/common/ToolBarCommon";


const ToolBar = (props) => {
    const {
        tabbedVersion,
        user,
        userUseCases,
        hadleNewReservation,
        sortingCriterium,
        setSortingCriterium,
        all,
        selectedReservedBy,
        setSelectedReservedBy,
        selectedAssetName,
        setSelectedAssetName,
        locations,
        handleSelectedLocation,
        selectedLocation,
        handleShowAll,
        showAll,
        selectedLicensePlate,
        setSelectedLicensePlate
    } = props;

    const { t } = useTranslation();


    const [filterValue, setFilterValue] = useState({});
    const [shown, setShown] = React.useState(true);

    const statusesObjs = {
        all: {
                value: "ALL",
                label: "ALL",
                key: "ALL",
            },
        active: {
            value: "ACTIVE",
            label: "ACTIVE",
            key: "ACTIVE",
        },
        none:{}
    };

    return (
        <ToolBarCommon shown={shown} setShown={setShown} title={all ? t('ALL_RESERVATION') : t('MY_RESERVATION')}>
            {all && <Grid item sm={1} xs={12}
                sx={{ paddingTop: '0 !important', justifyContent: 'flex-start', display: { 'xs': shown ? 'flex' : 'none', 'sm': 'none' }, marginTop: 2 }}>
            </Grid>}

            <Grid item sm={1.5} xs={6} sx={{ display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' } }} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showAll && showAll.value==='ACTIVE'}
                            sx={{color: (theme) => theme.palette.action.disabled}}
                        />
                    }
                    value={showAll}
                    onChange={() => {
                        handleShowAll(statusesObjs.active);
                    }}
                    label={t("ACTIVE")}
                    sx={{
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.action.disabled,
                        color: (theme) => theme.palette.text.secondary,
                        borderRadius: "15px",
                        fontSize: "12px",
                        padding: "3px",
                        width: "100%",
                        margin: 0,
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
            </Grid>
            <Grid item sm={1.5} xs={6} sx={{ display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' } }} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showAll && showAll.value==='ALL'}
                            sx={{color: (theme) => theme.palette.action.disabled}}
                        />
                    }
                    value={showAll}
                    onChange={() => {
                        if (showAll.value && showAll.value==='ALL') {
                            handleShowAll(statusesObjs.active);
                        } else {
                            handleShowAll(statusesObjs.all);
                        }
                    }}
                    label={t("SHOW_ALL")}
                    sx={{
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.action.disabled,
                        color: (theme) => theme.palette.text.secondary,
                        borderRadius: "15px",
                        fontSize: "12px",
                        padding: "3px",
                        width: "100%",
                        margin: 0,
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
            </Grid>

            <Grid item sm={2} xs={12} sx={{ display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' } }}>
                <TextField
                    variant="outlined"
                    type="search"
                    label={t('RESERVED_BY')}
                    fullWidth
                    value={selectedReservedBy || ''}
                    onChange={(event) => setSelectedReservedBy(event.target.value)} />

            </Grid>

            <Grid item sm={2} xs={6} sx={{ display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' } }}>
                <TextField
                    variant="outlined"
                    type="search"
                    label={t('PLACE_NAME')}
                    fullWidth
                    value={selectedAssetName || ''}
                    onChange={(event) => setSelectedAssetName(event.target.value)} />

            </Grid>
            <Grid item sm={2} xs={6} sx={{ display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' } }}>
                <TextField
                    variant="outlined"
                    type="search"
                    label={t('LICENSE_PLATE')}
                    fullWidth
                    value={selectedLicensePlate || ''}
                    onChange={(event) => setSelectedLicensePlate(event.target.value)} />
            </Grid>


            <Grid item sm={2} xs={10} sx={{ display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' } }}>
                <LocationAutocomplete
                    locations={locations}
                    setLocations={handleSelectedLocation}
                    selectedLocations={selectedLocation}
                />
            </Grid>

            <Grid item sm={1} xs={shown ? 2 : 11} sx={{ display: 'flex', paddingTop: '25px !important', marginLeft: '-4% !important', justifyContent: 'flex-end',}}>
                {((all && isComponentVisibleForUser(UC0105, userUseCases)) || (!all && isComponentVisibleForUser(UC0100, userUseCases))) &&
                    <div>
                        <IconButton
                            edge="start"
                            aria-label="add reservation"
                            onClick={hadleNewReservation}
                            size="large"
                            disabled={!((all && isComponentEnabledForUser(UC0105, userUseCases)) || (!all && isComponentEnabledForUser(UC0100, userUseCases)))}>
                            <Add />
                        </IconButton>
                    </div>
                }
            </Grid>
        </ToolBarCommon>
    );
}

export default ToolBar;