import types from "../actionTypes";

const INITIAL_STATE = {
    isFetchingTenants: false,
    tenants: [],
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.TENANT_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingTenants: false,
                tenants: action.payload.tenants,
            };
        case types.TENANT_FETCH_FAIL:
            return {
                ...state,
                isFetchingTenants: false,
                errors: action.payload.error,
            };
        case types.TENANT_FETCH_REQUEST:
            return {
                ...state,
                isFetchingTenants: true,
            };
        default:
            return state;
    }
};
