import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import Add from "@mui/icons-material/Add";
import {isComponentEnabledForUser, isComponentVisibleForUser} from '../../utils';
import {UC0100, UC0105} from '../../utils/constants';
import ToolBarCommon from "../../components/common/ToolBarCommon";
import {Checkbox, FormControlLabel, Switch} from "@mui/material";


const ToolBarSimple = (props) => {
    const {
        tabbedVersion,
        user,
        userUseCases,
        hadleNewReservation,
        sortingCriterium,
        setSortingCriterium,
        all,
        handleShowAll,
        showAll,
    } = props;

    const {t} = useTranslation();
    const [shown, setShown] = React.useState(true);

    const statusesObjs = {
        all: {
                value: "ALL",
                label: "ALL",
                key: "ALL",
            },
        active: {
            value: "ACTIVE",
            label: "ACTIVE",
            key: "ACTIVE",
        },
        none:{}
    };


    return (
        <ToolBarCommon shown={shown} hideShowFeature={false} setShown={setShown} title={all ? t('ALL_RESERVATION') : t('MY_RESERVATION')}>

            <Grid item sm={1.5} xs={5} sx={{ display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' } }} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showAll && showAll.value==='ACTIVE'}
                            sx={{color: (theme) => theme.palette.action.disabled}}
                        />
                    }
                    value={showAll}
                    onChange={() => {
                        handleShowAll(statusesObjs.active);
                    }}
                    label={t("ACTIVE")}
                    sx={{
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.action.disabled,
                        color: (theme) => theme.palette.text.secondary,
                        borderRadius: "15px",
                        fontSize: "12px",
                        padding: "3px",
                        width: "100%",
                        margin: 0,
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
            </Grid>
            <Grid item sm={1.5} xs={5} sx={{ display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' } }} >
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={showAll && showAll.value==='ALL'}
                            sx={{color: (theme) => theme.palette.action.disabled}}
                        />
                    }
                    value={showAll}
                    onChange={() => {
                        if (showAll.value && showAll.value==='ALL') {
                            handleShowAll(statusesObjs.active);
                        } else {
                            handleShowAll(statusesObjs.all);
                        }
                    }}
                    label={t("SHOW_ALL")}
                    sx={{
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.action.disabled,
                        color: (theme) => theme.palette.text.secondary,
                        borderRadius: "15px",
                        fontSize: "12px",
                        padding: "3px",
                        width: "100%",
                        margin: 0,
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
            </Grid>


            <Grid item sm={1} xs={1} sx={{display: 'flex', paddingTop: '22px !important', paddingLeft: '25px !important', marginTop: '0px !important', justifyContent: 'flex-start'}}>
                {((all && isComponentVisibleForUser(UC0105, userUseCases)) || (!all && isComponentVisibleForUser(UC0100, userUseCases))) &&
                    <div>
                        <IconButton
                            edge="start"
                            aria-label="add reservation"
                            onClick={hadleNewReservation}
                            size="large"
                            disabled={!((all && isComponentEnabledForUser(UC0105, userUseCases)) || (!all && isComponentEnabledForUser(UC0100, userUseCases)))}>
                            <Add/>
                        </IconButton>
                    </div>
                }
            </Grid>
        </ToolBarCommon>
    );
}

export default ToolBarSimple;