import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import AssetUpdate from "./AssetUpdate";
import {CircularProgress, IconButton, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SaveIcon from "@mui/icons-material/Save";
import {addGroupToAsset} from "../../../redux/actions/asset";
import ClearIcon from "@mui/icons-material/Clear";
import {styled} from "@mui/system";
import { ASSET_TYPE_PLACE } from '../../../utils/constants';

const CloseButton = styled(IconButton)(({theme}) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText

}));

function Asset(props) {
    const {
        userUseCases,
        locations,
        groups,
        handleClose,
        saveAsset,
        assetConfigModel,
        snackbarOpen,
        setSnackbarOpen,
        error,
        setError,
        saving,
        user,
        addGroupToAsset,
        type
    } = props;


    const {t} = useTranslation();

    const [updatedAsset, setUpdatedAsset] = useState();
    const [errorStep1, setErrorStep1] = useState('');
    const [selectedTenant, setSelectedTenant] = useState();

    const [files, setFiles] = useState([]);


    useEffect(() => {
        if (assetConfigModel.asset) {
            setUpdatedAsset({...assetConfigModel.asset, type: type});
            if (assetConfigModel.asset.img != null) {
                setFiles([{preview: assetConfigModel.asset.img}])
            }
        }
    }, [assetConfigModel]);

    const isValid = (isNew) => {
        let ret = true;
        const error = {};
        if (!updatedAsset.name || updatedAsset.name.trim().length === 0) {
            error.name = t('THIS_FIELD_IS_REQUIRED');
            ret = false;
        }
        if (!updatedAsset.location) {
            error.locations = t('THIS_FIELD_IS_REQUIRED');
            ret = false;

        }
        setErrorStep1(error);

        return ret;
    }

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const handleChange = (name) => (event) => {
        if (name === 'active') {
            setUpdatedAsset({...updatedAsset, [name]: event.target.checked === true});

        } else if (event && event.target) {
            if ( name.split('.').length>1 ) {
                let tmp = updatedAsset.car;
                tmp = {...tmp, [name.split('.')[1]]: event.target.value};
                setUpdatedAsset({...updatedAsset, car:tmp});
            } else {
                setUpdatedAsset({...updatedAsset, [name]: event.target.value});
            }
        } else {
            setUpdatedAsset({...updatedAsset, [name]: event});
        }
    }

    const handleSelectedLocation = (data) => {
        setUpdatedAsset({...updatedAsset, ['location']: data});
    }

    const handleSelectedGroup = (data) => {
        setUpdatedAsset({...updatedAsset, ['groups']: data});
    }

    const hadleCloseLoc = () => {
        setSelectedTenant(undefined);
        handleClose();
    }

    const saveLoc = (updatedAsset) => {
        let tmp = {...updatedAsset, tenantId: selectedTenant.tenantId};
        setSelectedTenant(undefined);
        saveAsset(tmp);
    }

    return (

        <Dialog
            maxWidth="xl"
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    height: '100%',
                    margin: 1,
                },
            }}
            open={assetConfigModel.open}
            onClose={hadleCloseLoc}
        >

            <DialogTitle sx={{
                height: '100px',
                paddingLeft: 5,
                paddingRight: 5,
                display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                width: '100%',
            }}>
                <>
                    <Typography variant='h4'
                                sx={{color: (theme) => theme.palette.background.contrastText}}>{t(assetConfigModel.readOnly ? 'DETAIL_'+(type===ASSET_TYPE_PLACE?'PLACE':'CAR') : (assetConfigModel.isNew ? 'CREATE_'+(type===ASSET_TYPE_PLACE?'PLACE':'CAR') : 'UPDATE_'+(type===ASSET_TYPE_PLACE?'PLACE':'CAR')))}</Typography>
                </>


                <CloseButton aria-label="delete" size="small" onClick={hadleCloseLoc}>
                    <ClearIcon fontSize="medium"/>

                </CloseButton>

            </DialogTitle>




            <DialogContent>
                <AssetUpdate
                    userUseCases={userUseCases}
                    handleChange={handleChange}
                    updatedAsset={updatedAsset}
                    error={errorStep1}
                    readOnly={assetConfigModel.readOnly}
                    setSnackbarError={setError}
                    setSnackbarOpen={setSnackbarOpen}
                    isNew={assetConfigModel.isNew}
                    handleSelectedLocation={handleSelectedLocation}
                    setSelectedTenant={setSelectedTenant}
                    handleSelectedGroup={handleSelectedGroup}
                    selectedTenant={selectedTenant}
                    groups={groups}
                    locations={locations}
                    setUpdatedAsset={setUpdatedAsset}
                    addGroupToAsset={addGroupToAsset}
                    files={files}
                    setFiles={setFiles}
                    type={type}
                    user={user}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0, height: 100, width: '100%'
                }}>


                {saving && <CircularProgress size={24}/>}

                {!assetConfigModel.readOnly &&

                    <Button
                        sx={{
                            width: '150px', margin: 1
                        }}
                        onClick={hadleCloseLoc}
                        color="secondary"
                        variant="outlined"
                        startIcon={<ArrowBackIosIcon/>}
                    >{t('CLOSE')} </Button>
                }

                {!assetConfigModel.readOnly &&
                    <>
                        <Button
                            sx={{
                                width: '150px', margin: 1
                            }}
                            disabled={saving}
                            loading={saving}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                                const ret = isValid(assetConfigModel.isNew);
                                if (ret) {
                                    saveLoc(updatedAsset);
                                }
                            }}
                            endIcon={<SaveIcon/>}
                        > {t('SAVE')}</Button>
                    </>
                }

            </DialogActions>


        </Dialog>

    );
}

Asset.propTypes = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({addGroupToAsset: addGroupToAsset}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Asset);


