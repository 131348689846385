import React, {useState} from 'react';
import {Typography} from '@mui/material';
import Box from "@mui/material/Box";
import ReservationListPage from "./ReservationListPage";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import { UC0002 } from '../../utils/constants';
import { isComponentEnabledForUser } from '../../utils';


function CustomTabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}


const Reservation = (props) => {

    const {
        user,
        useCases,
        userUseCases,
    } = props;

    const [value, setValue] = useState('1');
    const {t} = useTranslation();

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const showWelcome = user && user.welcomePage;

    if (showWelcome) {
        return <Box sx={{width: '100%'}}>
            <Paper
                sx={{
                    width: '86%',
                    marginLeft: '30px',
                    marginTop: '50px',
                    overflow: 'hidden',
                    padding: 2,
                    backgroundColor: (theme) => theme.palette.background.grey,
                }}

            >
                <div dangerouslySetInnerHTML={{__html: user.welcomePageContent}}/>
            </Paper>
        </Box>

    }

    if (isComponentEnabledForUser(UC0002, userUseCases)) {
        return (
            <ReservationListPage all={false}/>
        )
    } else {
        return (
            <div>{t('NO_RESERVATION_RIGHTS')}</div>
        )
    }


}


const mapStateToProps = (store) => ({
        user: store.authData.user,
        useCase: store.useCasesData.useCases,
        userUseCases: store.authData.userUseCases,
    }
);

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Reservation);