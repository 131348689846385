import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import UserUpdate from "./UserUpdate";
import { CircularProgress, IconButton, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import ClearIcon from "@mui/icons-material/Clear";
import { styled } from "@mui/system";
import { fetchRolesByTenantId } from "../../../redux/actions/role";
import { fetchGroupsByTenantId } from "../../../redux/actions/group";

const CloseButton = styled(IconButton)(({ theme }) => ({
    left: 10,
    color: (theme) => theme.palette.primary.contrastText,
}));

function User(props) {
    const {
        userUseCases,
        roles,
        fetchRolesByTenantId,
        groups,
        fetchGroupsByTenantId,
        handleClose,
        saveUser,
        userConfigModel,
        severity,
        saving,
        user,
    } = props;

    const { t } = useTranslation();

    const [updatedUser, setUpdatedUser] = useState();
    const [errorInner, setErrorInner] = useState("");
    const [selectedTenant, setSelectedTenant] = useState();

    const [isFetchedRoles, setIsFetchedRoles] = useState(false);
    const [isFetchedGroups, setIsFetchedGroups] = useState(false);

    const [valuesRoles, setValuesRoles] = useState([]);
    const [valuesGroups, setValuesGroups] = useState([]);

    useEffect(() => {
        if (userConfigModel.user) setUpdatedUser(userConfigModel.user);
    }, [userConfigModel]);

    useEffect(() => {
        if (!selectedTenant) {
            setValuesGroups([]);
            setValuesRoles([]);
            setUpdatedUser({ ...updatedUser, roles: [], groups: [] });
        }
        setIsFetchedRoles(false);
        setIsFetchedGroups(false);
    }, [selectedTenant]);

    useEffect(() => {
        if (!isFetchedRoles && selectedTenant) {
            fetchRolesByTenantId(selectedTenant.tenantId)
                .then(() => {
                    setIsFetchedRoles(true);
                })
                .catch((err) => {
                    console.log(err);
                    setIsFetchedRoles(false);
                });
        }
        if (!isFetchedGroups && selectedTenant) {
            fetchGroupsByTenantId(selectedTenant.tenantId)
                .then(() => {
                    setIsFetchedGroups(true);
                })
                .catch((err) => {
                    console.log(err);
                    setIsFetchedGroups(false);
                });
        }
    }, [isFetchedRoles, isFetchedGroups, selectedTenant]);

    useEffect(() => {
        if (roles) {
            setValuesRoles(
                roles.map((a) => {
                    return { value: a.roleId, label: a.name, key: a.roleId };
                }),
            );
        }
    }, [roles]);

    useEffect(() => {
        if (groups) {
            setValuesGroups(
                groups.map((a) => {
                    return {
                        value: a.groupId,
                        label: a.name,
                        key: a.groupId,
                        lightColor: a.lightColor,
                        darkColor: a.darkColor,
                    };
                }),
            );
        }
    }, [groups]);

    const isValid = () => {
        let ret = true;
        const error = {};
        if (!selectedTenant) {
            error.tenantId = t("THIS_FIELD_IS_REQUIRED");
            ret = false;
        }
        if (
            !updatedUser.firstName ||
            updatedUser.firstName.trim().length === 0
        ) {
            error.firstName = t("THIS_FIELD_IS_REQUIRED");
            ret = false;
        }
        if (!updatedUser.lastName || updatedUser.lastName.trim().length === 0) {
            error.lastName = t("THIS_FIELD_IS_REQUIRED");
            ret = false;
        }
        if (!updatedUser.userName || updatedUser.userName.trim().length === 0) {
            error.userName = t("THIS_FIELD_IS_REQUIRED");
            ret = false;
        }
        if (!updatedUser.email || updatedUser.email.trim().length === 0) {
            error.email = t("THIS_FIELD_IS_REQUIRED");
            ret = false;
        }
        setErrorInner(error);

        return ret;
    };

    const handleChange = (name) => (event) => {
        if (name === "active") {
            setUpdatedUser({
                ...updatedUser,
                [name]: event.target.checked === true,
            });
        } else if (event && event.target) {
            setUpdatedUser({ ...updatedUser, [name]: event.target.value });
        } else {
            setUpdatedUser({ ...updatedUser, [name]: event });
        }
    };

    const handleSelectedRole = (data) => {
        setUpdatedUser({ ...updatedUser, ["roles"]: data });
    };

    const handleSelectedGroup = (data) => {
        setUpdatedUser({ ...updatedUser, ["groups"]: data });
    };

    const handleSelectedManagedGroup = (data) => {
        setUpdatedUser({ ...updatedUser, ["managedGroups"]: data });
    };

    const handleCloseLoc = () => {
        setSelectedTenant(undefined);
        setErrorInner("");
        handleClose();
    };
    const saveUserLoc = () => {
        let tmp = {
            ...updatedUser,
            tenantId: selectedTenant.tenantId,
            active: updatedUser.active ? true : false,
        };
        setSelectedTenant(undefined);
        saveUser(tmp);
    };

    const setSelectedTenantInner = (tenant) => {
        if (tenant) {
            setErrorInner({ ...errorInner, tenantId: "" });
        }
        setSelectedTenant(tenant);
    };

    return (
        <Dialog
            maxWidth="md"
            sx={{
                "& .MuiDialog-paper": {
                    width: "100%",
                    margin: 1,
                },
            }}
            open={userConfigModel.open}
            onClose={handleCloseLoc}
        >
            <DialogTitle
                sx={{
                    height: "100px",
                    paddingLeft: 5,
                    paddingRight: 5,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                <>
                    <Typography
                        variant="h4"
                        sx={{
                            color: (theme) =>
                                theme.palette.background.contrastText,
                        }}
                    >
                        {t(
                            userConfigModel.isNew
                                ? "CREATE_USER"
                                : userConfigModel.readOnly
                                  ? "DETAIL_USER"
                                  : "UPDATE_USER",
                        )}
                    </Typography>
                </>

                <CloseButton
                    aria-label="delete"
                    size="small"
                    onClick={handleCloseLoc}
                >
                    <ClearIcon fontSize="medium" />
                </CloseButton>
            </DialogTitle>

            <DialogContent>
                <UserUpdate
                    userUseCases={userUseCases}
                    readOnly={userConfigModel.readOnly}
                    handleChange={handleChange}
                    updatedUser={updatedUser}
                    error={errorInner}
                    handleSelectedRole={handleSelectedRole}
                    handleSelectedGroup={handleSelectedGroup}
                    handleSelectedManagedGroup={handleSelectedManagedGroup}
                    groups={valuesGroups}
                    roles={valuesRoles}
                    selectedTenant={selectedTenant}
                    setSelectedTenant={setSelectedTenantInner}
                    user={user}
                    isNew={userConfigModel.isNew}
                />
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 0,
                    height: 100,
                    width: "100%",
                }}
            >
                {saving && <CircularProgress size={24} />}

                <Button
                    sx={{
                        width: "150px",
                        margin: 1,
                    }}
                    onClick={handleCloseLoc}
                    color="secondary"
                    variant="outlined"
                >
                    {t("CLOSE")}{" "}
                </Button>

                {!userConfigModel.readOnly && (
                    <>
                        <Button
                            sx={{
                                width: "150px",
                                margin: 1,
                                color: "#fff",
                            }}
                            loading={saving}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                const ret = isValid();
                                if (ret) {
                                    saveUserLoc(updatedUser);
                                }
                            }}
                        >
                            {" "}
                            {t("SAVE")}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
}

User.propTypes = {};

const mapStateToProps = (store) => ({
    roles: store.roleData.rolesByTenantId,
    groups: store.groupData.groupsByTenantId,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchRolesByTenantId,
            fetchGroupsByTenantId,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(User);
