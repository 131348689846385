import types from '../actionTypes';
import tenantsService from '../../services/tenant.service';


export const fetchTenants = () => (dispatch, getState) => {
    if (getState().tenantData.isFetchingTenants) {
        return Promise.reject();
    }

    dispatch({
        type: types.TENANT_FETCH_REQUEST,
    });

    return tenantsService.fetchTenants()
        .then((tenants) => {
            dispatch({
                type: types.TENANT_FETCH_SUCCESS,
                payload: {tenants: tenants},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.TENANT_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};
