import React from 'react';
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";


const ToolBar = styled('div')(({ theme }) => ({
    width: '85%',
    marginLeft: '30px',
    display: 'flex',
    flexDirection: "row",
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    position: 'relative',
    

}));

const KeyboardDoubleArrowDown = styled(KeyboardDoubleArrowDownIcon)(({ theme, shown }) => ({
    //position: 'absolute',
    //top: '80px',
    //left: '-20px',
    zIndex: 4000,
    [theme.breakpoints.only('xs')]: {
        display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },

}));

const KeyboardDoubleArrowUp = styled(KeyboardDoubleArrowUpIcon)(({ theme, shown }) => ({
    //position: 'absolute',
    //top: '80px',
    //left: '-20px',
    zIndex: 4000,
    [theme.breakpoints.only('xs')]: {
        display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },

}));

function ToolBarCommon(props) {


    const {
        shown,
        setShown,
        title,
        children,
        hideShowFeature = true,
    } = props;


    const toggleDrawer = (open) => (event) => {
        setShown(!shown);
    };


    return (
        <ToolBar>

            <Grid container columnSpacing={1} rowSpacing={3} row sx={{
                width: '100%',
                justifyContent: 'flex-start',
                alignItems:'flex-start',
                marginTop: '20px',
            }}>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Typography align="left" variant='h4'
                        style={{ paddingRight: 20 }}>{title}</Typography>
                </Grid>
                {hideShowFeature &&
                    <Grid item xs={1} sx={{ display: !shown ? 'flex' : { 'xs': 'flex', 'lg': 'none' }, justifyContent: 'flex-start', alignItems: 'start' }}>
                        {(shown ?
                            <KeyboardDoubleArrowDown onClick={toggleDrawer(true)}></KeyboardDoubleArrowDown> :
                            <KeyboardDoubleArrowUp onClick={toggleDrawer(true)}></KeyboardDoubleArrowUp>
                        )}
                    </Grid>}

                {children}

            </Grid>
        </ToolBar>
    );
}


ToolBarCommon.propTypes = {};

ToolBarCommon.defaultProps = {};

export default ToolBarCommon;
