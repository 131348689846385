const Dashes = ({ color }) => {
  console.log(color);
  return (
    <span
      style={{
        display: "flex",
        gap: "13px",
        alignItems: "end",
        paddingBottom: "5px",
      }}
    >
      {new Array(3).fill(null).map((_, i) => (
        <span
          style={{
            backgroundColor: color,
            transmission: "ease-in-out 0.3s",
            width: "15px",
            height: "2px",
            borderRadius: "1px",
            display: "inline-block",
          }}
          key={i}
        />
      ))}
    </span>
  );
};

export default Dashes;
