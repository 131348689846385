import Loading from "../../components/common/Loading";
import { connect, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import ReservationList from "./ReservationList";
import React, { useEffect, useState } from "react";
import {
  createReservation,
  deleteReservation,
  fetchReservations,
  updateReservation,
} from "../../redux/actions/reservation";
import { fetchCodeLists } from "../../redux/actions/codelists";
import { fetchAllUsers } from "../../redux/actions/users";
import NewReservation from "../../components/modal/Reservation";
import { UC0002, UC0003 } from "../../utils/constants";
import { getErrorMessage, isComponentVisibleForUser } from "../../utils";
import { useTranslation } from "react-i18next";
import { CircularProgress, Skeleton, useTheme } from "@mui/material";
import PDSSnackbar from "../../components/common/Snackbar";
import { styled } from "@mui/system";
import {
  fetchAvailableLocations,
  fetchLocations,
} from "../../redux/actions/location";
import ToolBarSimple from "./ToolBarSimple";
import Paper from "@mui/material/Paper";
import { fetchAvailableAssets } from "../../redux/actions/asset";
import ToolBar from "./ToolBar";

const Container = styled("div")(({ theme }) => ({}));

const Container2 = styled("div")(({ theme }) => ({
  padding: theme.spacing(2),
  background: theme.palette.background.gray,
}));

const FetchingProgress = styled("div")(({ theme }) => ({
  paddingTop: "12px",
}));

const ReservationListPage = (props) => {
  const {
    user,
    users,
    fetchAllUsers,
    reservations,
    isFetchingAllList,
    fetchReservations,
    reservationsErrors,
    availableAssets,
    fetchAvailableAssets,
    fetchLocations,
    codeLists,
    fetchCodeLists,
    createReservation,
    updateReservation,
    deleteReservation,
    useCases,
    userUseCases,
    all,
    tabbedVersion,
    locations,
  } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [saving, setSaving] = useState(false);
  const [valid, setValid] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isFetchedReservations, setIsFetchedReservations] = useState(false);
  const [isFetchedLocations, setIsFetchedLocations] = useState(false);
  const [isFetchedCodeList, setIsFetchedCodeLists] = useState(false);
  const [isFetchedUsers, setIsFetchedUsers] = useState(false);
  const [showNew, setShowNew] = useState(false);
  const [editReservation, setEditReservation] = useState();
  const [error, setError] = useState("");
  const [sortingCriterium, setSortingCriterium] = useState(1);

  const [showAll, setShowAll] = useState({value:'ACTIVE'});

  const [selectedReservedBy, setSelectedReservedBy] = useState("");
  const [selectedAssetName, setSelectedAssetName] = useState("");
  const [filteredReservations, setFilteredReservations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("error");
  const [selectedLicensePlate, setSelectedLicensePlate] = useState("");

  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
  };

  const handleShowAll = (value) => {
    setShowAll(value);
    setIsFetchedReservations(false);
  };

  const [locationsValues, setLocationsValues] = useState();

  useEffect(() => {
    if (locations) {
      setLocationsValues(
        locations.map((a) => {
          return { value: a.locationId, label: a.name, key: a.locationId };
        })
      );
    }
  }, [locations]);

  useEffect(() => {
    if (user) {
      if (!isFetchedReservations) {
        setIsLoading(true);
        fetchReservations(!all ? user.id : null, showAll.value==="ALL"?true:false)
          .then(() => {
            setIsFetchedReservations(true);
          })
          .catch((err) => {
            setIsFetchedReservations(true);
            setIsLoading(false);
          });
      }
    }
  }, [isFetchedReservations, fetchReservations, user]);

  useEffect(() => {
    if (!isFetchedLocations) {
      setIsLoading(true);
      fetchLocations()
        .then(() => {
          setIsFetchedLocations(true);
        })
        .catch((err) => {
          setIsFetchedLocations(true);
          setIsLoading(false);
        });
    }
  }, [isFetchedLocations, fetchLocations]);

  useEffect(() => {
    if (!isFetchedUsers) {
      setIsLoading(true);
      fetchAllUsers()
        .then(() => {
          setIsFetchedUsers(true);
        })
        .catch((err) => {
          setIsFetchedUsers(true);
          setIsLoading(false);
        });
    }
  }, [isFetchedUsers, fetchAllUsers]);

  const matchLocation = (res) => {
    for (let i = 0; i < selectedLocation.length; i++) {
      if (res.locationId == selectedLocation[i].key) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const filUser = [];
    if (reservations) {
      for (let i = 0; i < reservations.length; i++) {
        let matchCriteria = true;
        if (selectedReservedBy && selectedReservedBy.trim().length > 0) {
          if (
            !reservations[i].reservedByName
              .toLowerCase()
              .includes(selectedReservedBy.toLowerCase())
          ) {
            matchCriteria = false;
          }
        }

        if (selectedLicensePlate && selectedLicensePlate.trim().length > 0) {
          const resLic = reservations[i].licensePlate;
          const resLicNoSpace = resLic ? resLic.replace(/ /g, "") : null;
          if (
            (!resLic ||
              !resLic
                .toLowerCase()
                .includes(selectedLicensePlate.toLowerCase())) &&
            (!resLicNoSpace ||
              !resLicNoSpace
                .toLowerCase()
                .includes(selectedLicensePlate.toLowerCase()))
          ) {
            matchCriteria = false;
          }
        }

        if (selectedAssetName && selectedAssetName.trim().length > 0) {
          if (
            !reservations[i].assetName
              .toLowerCase()
              .includes(selectedAssetName.toLowerCase())
          ) {
            matchCriteria = false;
          }
        }

        if (selectedLocation && selectedLocation.length > 0) {
          const res = matchLocation(reservations[i]);
          if (!res) {
            matchCriteria = false;
          }
        }

        if (matchCriteria) {
          filUser.push(reservations[i]);
        }
      }
    }
    setFilteredReservations(filUser);
  }, [
    reservations,
    selectedLicensePlate,
    selectedReservedBy,
    selectedAssetName,
    selectedLocation,
  ]);

  if (!user && !isLoading) {
    return <></>;
  }

  const hadleNewReservation = (obj) => {
    setSnackbarOpen(false);
    setSnackbarMessage("");
    setShowNew(true);
  };

  const handleNewClose = () => {
    setShowNew(false);
    setEditReservation(null);
    dispatch({ type: "RESERVATION_ERRORS_CLEAR" });
  };

  const handleNewSave = (reservation) => {
    setSaving(true);
    if (reservation.reservationId) {
      updateReservation(reservation)
        .then(() => {
          setEditReservation(null);
          setIsFetchedReservations(false);
        })
        .catch((err) => {
          console.log("updateReservation error", err);
        });
    } else {
      createReservation(reservation)
        .then(() => {
          setSnackbarSeverity("success");
          //  setSnackbarOpen(true);
          setSaving(false);
          setSnackbarMessage(t("RESERVATION_CREATED"));
          setShowNew(false);
          setIsFetchedReservations(false);
        })
        .catch((err) => {
          console.log("createReservation error", err);
          setSaving(false);
          setSnackbarMessage(getErrorMessage(err, t));
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        });
    }
  };

  const handleEdit = (reservation) => {
    setEditReservation(reservation);
  };

  const handleDelete = (id) => {
    deleteReservation(id)
      .then(() => {
        setIsFetchedReservations(false);
      })
      .catch(() => {
        setIsFetchedReservations(false);
      });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <>
      <PDSSnackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
        message={snackbarMessage}
      />
      {(showNew || editReservation) && (
        <NewReservation
          all={all}
          users={users}
          saving={saving}
          openNew={showNew}
          handleClose={handleNewClose}
          handleSave={handleNewSave}
          codeLists={codeLists}
          editReservation={editReservation}
          availableAssets={availableAssets}
          locations={locations}
          fetchAvailableAssets={fetchAvailableAssets}
          reservationsErrors={reservationsErrors}
          userUseCases={userUseCases}
          user={user}
        />
      )}

      {!isFetchingAllList && isFetchedReservations ? (
        all ? (
          <ToolBar
            tabbedVersion={tabbedVersion}
            all={all}
            user={user}
            users={users}
            hadleNewReservation={hadleNewReservation}
            userUseCases={userUseCases}
            sortingCriterium={sortingCriterium}
            setSortingCriterium={setSortingCriterium}
            selectedReservedBy={selectedReservedBy}
            setSelectedReservedBy={setSelectedReservedBy}
            selectedAssetName={selectedAssetName}
            setSelectedAssetName={setSelectedAssetName}
            selectedLocation={selectedLocation}
            locations={locationsValues}
            handleSelectedLocation={handleSelectedLocation}
            handleShowAll={handleShowAll}
            setSelectedLicensePlate={setSelectedLicensePlate}
            selectedLicensePlate={selectedLicensePlate}
            showAll={showAll}
          />
        ) : (
          <ToolBarSimple
            tabbedVersion={tabbedVersion}
            all={all}
            user={user}
            users={users}
            hadleNewReservation={hadleNewReservation}
            userUseCases={userUseCases}
            sortingCriterium={sortingCriterium}
            setSortingCriterium={setSortingCriterium}
            handleShowAll={handleShowAll}
            showAll={showAll}
          />
        )
      ) : (
        <Skeleton
          height={200}
          sx={{
            width: "86%",
            marginLeft: "30px",
          }}
          animation="wave"
        />
      )}

      {!isFetchingAllList && isFetchedReservations ? (
        <div style={{ padding: 30 }}>
          {((!all && isComponentVisibleForUser(UC0002, userUseCases)) ||
            (all && isComponentVisibleForUser(UC0003, userUseCases))) && (
            <ReservationList
              all={all}
              showAll={showAll}
              reservations={filteredReservations}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
              userUseCases={userUseCases}
              sortingCriterium={sortingCriterium}
            />
          )}
        </div>
      ) : (
        <Skeleton
          variant="rounded"
          height="calc(100vh - 200px)"
          sx={{
            width: "86%",
            marginLeft: "30px",
          }}
          animation="wave"
        />
      )}
    </>
  );
};

ReservationListPage.propTypes = {
  user: PropTypes.object,
};

ReservationListPage.defaultProps = {
  user: null,
};

const mapStateToProps = (store) => ({
  user: store.authData.user,
  reservations: store.reservationData.reservations,
  reservationsErrors: store.reservationData.errors,
  isFetchingAllList: store.reservationData.isFetchingAllList,
  locations: store.locationData.locations,
  availableAssets: store.assetData.availableAssets,
  codeLists: store.codelistData.codelist,
  useCases: store.useCasesData.useCases,
  userUseCases: store.authData.userUseCases,
  users: store.usersData.parkingUsers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchReservations,
      fetchCodeLists,
      fetchAllUsers,
      createReservation,
      updateReservation,
      deleteReservation,
      fetchAvailableAssets,
      fetchLocations,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReservationListPage);
