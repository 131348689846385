import actionTypes from '../actionTypes';

const INITIAL_STATE = {
  user: null,
  errors: '',
  userUseCases: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_INFO:
      return {
        ...state,
        user: action.payload.user,
        userUseCases: action.payload.userUseCases,
      };
    case actionTypes.LOG_OUT:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};
