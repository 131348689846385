import types from "../actionTypes";

const INITIAL_STATE = {
    isFetchingAllGroupsList: false,
    groups: [],
    isFetchingGroupsByTenantId: false,
    groupsByTenantId: [],
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.GROUP_CREATE_SUCCESS:
            return {
                ...state, isCreating: false
            };
        case types.GROUP_CREATE_REQUEST:
            return {
                ...state, isCreating: true
            };
        case types.GROUP_CREATE_FAIL:
            return {
                ...state,
                isCreating: true,
                errors: action.payload.error,
            };

        case types.GROUP_UPDATE_SUCCESS:
            return {
                ...state, isUpdating: false
            };
        case types.GROUP_UPDATE_REQUEST:
            return {
                ...state, isUpdating: true
            };
        case types.GROUP_UPDATE_FAIL:
            return {
                ...state,
                isUpdating: true,
                errors: action.payload.error,
            };

        case types.GROUP_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAvailableList: false,
                groups: action.payload.groups,
            };
        case types.GROUP_FETCH_FAIL:
            return {
                ...state,
                isFetchingAvailableList: false,
                errors: action.payload.error,
            };
        case types.GROUP_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAvailableList: true,
            };

        case types.GROUP_BY_TENANT_ID_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingGroupsByTenantId: false,
                groupsByTenantId: action.payload.groups,
            };
        case types.GROUP_BY_TENANT_ID_FETCH_FAIL:
            return {
                ...state,
                isFetchingGroupsByTenantId: false,
                errors: action.payload.error,
            };
        case types.GROUP_BY_TENANT_ID_FETCH_FAIL:
            return {
                ...state,
                isFetchingGroupsByTenantId: true,
            };
        default:
            return state;
    }
};
