import types from '../actionTypes';
import receiptsService from '../../services/receipts.service';


export const fetchReceipts = (parkingPlaceId, lpn, email, receiptId, dateFrom, dateTo) => (dispatch, getState) => {
    if (getState().receiptData.isFetchingReceipts) {
        return Promise.reject();
    }

    dispatch({
        type: types.RECEIPT_FETCH_REQUEST,
    });

    return receiptsService.fetchReceipts(parkingPlaceId, lpn, email, receiptId, dateFrom, dateTo)
        .then((receipts) => {
            dispatch({
                type: types.RECEIPT_FETCH_SUCCESS,
                payload: {receipts: receipts},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const forwardReceipt = (receipt, email) => (dispatch, getState) => {
    if (getState().receiptData.isForwarding) {
        return Promise.reject();
    }

    dispatch({
        type: types.RECEIPT_FORWARD_REQUEST,
    });

    return receiptsService.forwardReceipt(receipt, email)
        .then((forward) => {
            dispatch({
                type: types.RECEIPT_FORWARD_SUCCESS,
                payload: {forward: forward},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.RECEIPT_FORWARD_FAIL,
                payload: {error},
            });

            throw error;
        });
};