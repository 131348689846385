import React from "react";
import { useTranslation } from "react-i18next";
import { dateTimetoIso, isComponentEnabledForUser, isComponentVisibleForUser } from "../../../utils";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { UC0181 } from "../../../utils/constants";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import PersonIcon from "@mui/icons-material/Person";
import PersonOffIcon from "@mui/icons-material/PersonOff";

import { Avatar, Button, useTheme } from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import LicensePlate from "../../../components/common/LicensePlate";
import ReceiptIcon from '@mui/icons-material/Receipt';
import CustomTable from "../../../components/common/CustomTable"

function ParkingActiveList(props) {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        parkingActive,
        userUseCases,
        handleParkingActiveDetail,
        table,
    } = props;

    const cellStyle = {
        paddingLeft: 4,
        paddingRight: 4,
        lineHeight: '19px',
        color: '#1B1B28',
    };

    const columns = [
        {
            title: t('ID'),
            field: 'id',
            headerStyle: { textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'center', paddingLeft: 5,
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "20%",
            render: (rowData) => rowData.email ? rowData.email : rowData.applicationId
        },
        {
            title: t('DATE_FROM'),
            field: 'dateFrom',
            headerStyle: { textAlign: 'left', paddingLeft: 5},
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "20%",
            render: (rowData) => (
                dateTimetoIso(rowData.dateFrom)
            )
        },
        {
            title: t('DATE_TO'),
            field: 'dateTo',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "20%",
            render: (rowData) => (
                dateTimetoIso(rowData.dateTo)
            ),
        },
        {
            title: t('LPN'),
            field: 'lpn',
            headerStyle: { textAlign: 'left', paddingLeft: 5 },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "10%",
            render: (rowData) => (
                rowData.favoriteCar && rowData.favoriteCar.lpn ? rowData.favoriteCar.lpn : rowData.lpn
            ),
        },
        {
            title: t('PLACE'),
            field: 'place',
            headerStyle: { textAlign: 'left', paddingLeft: 5, },
            cellStyle: {
                ...cellStyle,
                align: 'left',
                color: (theme) => theme.palette.background.contrastText,
            },
            width: "20%",
            render: (rowData) => (
                rowData.parkingPlace.name
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            width: "10%",
            render: (rowData) => (
                <div >
                    {isComponentVisibleForUser(UC0181, userUseCases) &&
                        <Button
                            onClick={()=>{handleParkingActiveDetail(rowData)}}
                            disabled={!isComponentEnabledForUser(UC0181, userUseCases)}
                        > 
                            <ReceiptIcon />
                        </Button>
                    }
                </div>
            ),
        },

    ];

    const tableOptions = {
        headerStyle: {
            padding: '15px 8px 15px 34px',
            lineHeight: '12px',
            color: (theme) => theme.palette.background.contrastText,
            textAlign: 'center',
        },
        paging: false
    };

    const renderUserState = (param) => {
        if (!param.licensePlate) {
            if (param.active) {
                return (
                    <Avatar variant="outlined" title={t("USER_ACTIVE")}>
                        <PersonIcon style={{ fontSize: 40 }} />
                    </Avatar>
                );
            } else {
                return (
                    <Avatar variant="outlined" title={t("USER_INACTIVE")}>
                        <PersonOffIcon style={{ fontSize: 40 }} />
                    </Avatar>
                );
            }
        } else {
            return (
                <LicensePlate
                    licensePlate={param.licensePlate}
                    active={param.active}
                />
            );
        }
    };

    const CARD_SIZE = 400;

    const Row = ({ index, data, style }) => {
        const { receipts: parkingActives, itemsPerRow } = data;

        if (!parkingActives) {
            return null;
        }

        const items = [];
        const fromIndex = index * itemsPerRow;

        const toIndex = Math.min(fromIndex + itemsPerRow, parkingActives.length);

        for (let i = fromIndex; i < toIndex; i++) {
            let parkingActiveItem = parkingActives[i];
            items.push(
                <Paper
                    key={parkingActiveItem.userId}
                    elevation={0}
                    sx={{
                        width: CARD_SIZE,
                        height: 150,
                        padding: 1,
                        margin: 1,
                        backgroundColor: (theme) =>
                            theme.palette.background.paper,
                        boxShadow: `1px 1px 20px 1px ${theme.palette.shadow}`,
                    }}
                >
                    <Grid
                        container
                        alignItems="center"
                        marginLeft="0px"
                        style={{ padding: 5 }}
                    >
                        <Grid item xs={2}>
                            <div
                                style={{
                                    paddingLeft: "6px",
                                    paddingTop: "6px",
                                }}
                            >
                                {renderUserState(parkingActiveItem)}
                            </div>
                        </Grid>
                        <Grid item xs={10}>
                            <Tooltip title={t("ADDRESS")}>
                                <Typography
                                    marginLeft="10px"
                                    marginTop="3px"
                                    onClick={() => {
                                        isComponentVisibleForUser(
                                            UC0181,
                                            userUseCases,
                                        ) &&
                                            handleParkingActiveDetail(parkingActiveItem);
                                    }}
                                    sx={{
                                        cursor: isComponentVisibleForUser(
                                            UC0181,
                                            userUseCases,
                                        )
                                            ? "pointer"
                                            : "default",
                                        fontWeight: isComponentVisibleForUser(
                                            UC0181,
                                            userUseCases,
                                        )
                                            ? 600
                                            : "inherit",
                                    }}
                                    variant="body1"
                                >
                                    {parkingActiveItem.parkingPlace.name}
                                </Typography>
                            </Tooltip>
                        </Grid>

                        <Grid item xs={4} sx={{ paddingTop: 2 }}>
                            <Typography
                                marginLeft="10px"
                                variant="h4"
                            >
                                {parkingActiveItem.favoriteCar?parkingActiveItem.favoriteCar.lpn:parkingActiveItem.lpn}
                            </Typography>
                        </Grid>

                        <Grid item xs={4} sx={{ paddingTop: 2 }}>
                            <Typography
                                marginLeft="10px"
                                variant="body2"
                            >
                                {parkingActiveItem.email?parkingActiveItem.email:parkingActiveItem.applicationId}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} sx={{ paddingTop: 2 }}></Grid>

                        <Grid item xs={12} sx={{ paddingTop: 2 }}>
                            <Typography
                                marginLeft="10px"
                                variant="body2"
                            >
                                {t('FROM') +': '+ dateTimetoIso(parkingActiveItem.dateFrom) + ' - ' + t('TO') + ': ' + dateTimetoIso(parkingActiveItem.dateTo) }
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>,
            );
        }

        return (
            <div style={style}>
                <div
                    style={{
                        display: "flex",
                        padding: "20px",
                        justifyContent: "flex-start",
                        height: "100%",
                    }}
                >
                    {items}
                </div>
            </div>
        );
    };

    return (
        <div style={{ marginTop: "10px", height: "calc(100vh - 200px)" }}>
            {table &&
                <div style={{padding: '30px'}}>
                    <CustomTable
                        title=""
                        columns={columns}
                        data={parkingActive}
                        options={tableOptions}
                        isLoading={parkingActive ? false : true}
                        loadAllData={false}
                    />
                </div>
            }

            {!table &&
                <AutoSizer>
                    {({ height, width }) => {
                        const itemsPerRow = Math.floor(width / CARD_SIZE) || 1; // A calculation to establish how many cards will go on each row.

                        // The || 1 part is a simple hack that makes it work in a really small viewport (if someone totally collapses the window)

                        const rowCount = Math.ceil(parkingActive.length / itemsPerRow); // List will need the number of rows in order to be able to properly know what to render and what not to
                        return (
                            <div>
                                <List
                                    height={height}
                                    itemCount={rowCount}
                                    itemData={{ itemsPerRow, receipts: parkingActive }}
                                    itemSize={170}
                                    width={width}
                                >
                                    {Row}
                                </List>
                            </div>
                        );
                    }}
                </AutoSizer>
            }
        </div>
    );
}

export default ParkingActiveList;
