import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
    Autocomplete,
    Button,
    Chip,
} from "@mui/material";
import ToolBarCommon from "../../../components/common/ToolBarCommon";
import CustomDatePicker from '../../../components/common/CustomDatePicker'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

function ToolBar(props) {
    const {
        filter,
        handleFilter,
        selectedPlace,
        handleSelectedPlace,
        parkingPlaces,
        table,
        setTable
    } = props;

    const { t } = useTranslation();
    const [shown, setShown] = React.useState(true);
    const toggleDrawer = (open) => (event) => {
        setShown(!shown);
    };

    const handleTableClick = () => () => {
        setTable(!table);
    }

    return (
        <ToolBarCommon shown={shown} setShown={setShown} title={t("RECEIPTS_LIST")}>
            {!shown && (
                <Grid
                    item
                    xs={11}
                    sx={{
                        display: shown ? "flex" : { xs: "flex", lg: "none" },
                    }}
                ></Grid>
            )}

            <Grid
                item
                xs={6}
                sm={1.5}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <CustomDatePicker disablePast={false} setDate={handleFilter('dateFrom')} label={t('DATE_FROM')}/>
            </Grid>
            <Grid
                item
                xs={6}
                sm={1.5}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <CustomDatePicker disablePast={false} setDate={handleFilter('dateTo')} label={t('DATE_TO')}/>
            </Grid>
            <Grid
                item
                xs={6}
                sm={2}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <TextField
                    variant="outlined"
                    type="search"
                    label={t("EMAIL")}
                    fullWidth
                    value={filter.email || ""}
                    onChange={handleFilter('email')}
                />
            </Grid>
            <Grid
                item
                sm={1}
                xs={6}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <TextField
                    variant="outlined"
                    type="search"
                    label={t("LICENSE_PLATE")}
                    fullWidth
                    value={filter.lpn || ""}
                    onChange={handleFilter('lpn')}
                />
            </Grid>
            <Grid
                xs={6}
                item
                sm={4}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <Autocomplete
                    value={selectedPlace}
                    onChange={(event, newValue) => {
                        handleSelectedPlace(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    filterSelectedOptions
                    id="role"
                    style={{ width: "100%" }}
                    options={parkingPlaces || []}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant="outlined"
                            {...params}
                            label={t("PARKING_PLACE")}
                            fullWidth
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip
                                    style={{
                                        backgroundColor: option.color,
                                        color: "black",
                                    }}
                                    variant="outlined"
                                    label={option.label}
                                    {...getTagProps({ index })}
                                />
                            );
                        })
                    }
                />
            </Grid>

            <Grid
                xs={12}
                item
                sm={1.5}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <TextField
                    variant="outlined"
                    type="search"
                    label={t("RECEIPT_ID")}
                    fullWidth
                    value={filter.receiptId || ""}
                    onChange={handleFilter('receiptId')}
                />
            </Grid>

            <Grid
                xs={12}
                item
                sm={0.5}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <Button onClick={handleTableClick()}>
                    <FormatListBulletedIcon/>
                </Button>
            </Grid>

        </ToolBarCommon>
    );
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
