import types from '../actionTypes';
import groupService from '../../services/group.service';


export const createGroup = (group) => (dispatch, getState) => {

    dispatch({
        type: types.GROUP_CREATE_REQUEST,
    });

    return groupService.createGroup(group)
        .then((newGroup) => {
            dispatch({
                type: types.GROUP_CREATE_SUCCESS,
                payload: {group: newGroup},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.GROUP_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};



export const updateGroup = (group) => (dispatch, getState) => {

    dispatch({
        type: types.GROUP_UPDATE_REQUEST,
    });

    return groupService.updateGroup(group)
        .then((newGroup) => {
            dispatch({
                type: types.GROUP_UPDATE_SUCCESS,
                payload: {group: newGroup},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.GROUP_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllGroups = () => (dispatch, getState) => {
    if (getState().groupData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.GROUP_FETCH_REQUEST,
    });

    return groupService.getGroups()
        .then((groups) => {
            dispatch({
                type: types.GROUP_FETCH_SUCCESS,
                payload: {groups},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.GROUP_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchGroupsByTenantId = (tenantId) => (dispatch, getState) => {
    if (getState().groupData.isFetchingGroupsByTenantId) {
        return Promise.reject();
    }

    dispatch({
        type: types.GROUP_BY_TENANT_ID_FETCH_REQUEST
    });

    return groupService.fetchGroupsByTenantId(tenantId)
        .then((groups) => {
            dispatch({
                type: types.GROUP_BY_TENANT_ID_FETCH_SUCCESS,
                payload: {groups: groups},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.GROUP_BY_TENANT_ID_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};