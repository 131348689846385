import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {useTranslation} from 'react-i18next';
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";


function TenantAutocomplete(props) {
    const {t} = useTranslation();
    const {
        selectedTenant,
        setSelectedTenant,
        error,
        disabled,
        tenants,
        tenantId,
        required
    } = props;


    useEffect(()=>{
        if ( tenants && !selectedTenant ) {
            for ( let i=0; i<tenants.length; i++){
                if ( tenantId === tenants[i].tenantId ) {
                    setSelectedTenant(tenants[i]);
                }
            }
        }
    }, [tenants, tenantId]);

    const getTenantLogo = (name) => {
        for (let i=0; i<tenants.length; i++) {
            if ( tenants[i].name === name ) {
                return tenants[i].logo;
            }
        }
    }

    return (
        <Autocomplete
            multiple={false}
            fullWidth
            required={required}
            value={selectedTenant || (null)}
            onChange={(event, newValue) => {
                setSelectedTenant(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
                return option.value === value.value
            }
            }
            disabled={disabled}
            id="tenant"
            options={tenants || []}
            getOptionLabel={(option) => {
                return option.name;
            }}
            style={{width: '100%'}}
            renderInput={(params) => (
                <TextField variant="outlined" required={required} error={!!error} helperText={error}  {...params} label={t('TENANT')} 
                    style={{
                            background: `url(data:image/png;base64,${getTenantLogo(params.inputProps.value)}) no-repeat calc(100% - 62px) / auto 25px`,
                    }}
                />
            )}
            renderOption={(props, option) => (                
                <div {...props}>
                    <Grid container sx={{width: '100%',}}>
                        <Grid item sx={12}>
                            {option.name} 
                        </Grid>
                    </Grid>
                    <img
                        height="20"
                        src={`data:image/png;base64,${option.logo}`}
                    /> 
                </div>
              )}
        />
    );
}

const mapStateToProps = (store) => ({
    tenants: store.tenantData.tenants,
    isFetchedTenants: store.tenantData.isFetchedTenants,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TenantAutocomplete);

