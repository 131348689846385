import types from '../actionTypes';
import settingService from '../../services/setting.service';


export const updateSetting = (setting) => (dispatch, getState) => {

    dispatch({
        type: types.SETTING_UPDATE_REQUEST,
    });

    return settingService.updateSetting(setting)
        .then((updatedSetting) => {
            dispatch({
                type: types.SETTING_UPDATE_SUCCESS,
                payload: {setting: updatedSetting},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SETTING_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const fetchSetting = () => (dispatch, getState) => {
    if (getState().settingData.isFetchingSetting) {
        return Promise.reject();
    }

    dispatch({
        type: types.SETTING_FETCH_REQUEST,
    });

    return settingService.fetchSetting()
        .then((setting) => {
            dispatch({
                type: types.SETTING_FETCH_SUCCESS,
                payload: {setting},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SETTING_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};
