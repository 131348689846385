import httpService from "./http.service";

const getReservations = (showAll) =>
  httpService
    .get(`/reservations?showAll=${showAll}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const getReservationsForUser = (userId, showAll) =>
  httpService
    .get(`/reservations/user?userId=${userId}&showAll=${showAll}`)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err.response));

const createReservation = (reservation) =>
  httpService
    .post("/reservations", reservation)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const validateReservation = (reservation) =>
  httpService
    .post("/reservations/validate", reservation)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const updateReservation = (reservation) =>
  httpService
    .put("/reservations", reservation)
    .then(({ data }) => data)
    .catch((err) => Promise.reject(err));

const deleteReservation = (reservationId) =>
  httpService
    .remove(`/reservations/${reservationId}`)
    .then(({ data }) => data)
    .catch((err) => {
      console.log(err);
      Promise.reject(err);
    });

export default {
  getReservations,
  getReservationsForUser,
  createReservation,
  validateReservation,
  updateReservation,
  deleteReservation,
};
