import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {useTranslation} from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as React from "react";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {ASSET_TYPE_PLACE, DATE_TIME_FORMAT} from "../../../utils/constants";
import {E0141} from "../../../utils/errors";
import PDSSnackbar from "../../common/Snackbar";
import {
    Chip,
    CircularProgress,
    FormControlLabel,
    IconButton,
    MenuItem,
    MobileStepper,
    Select,
    Skeleton,
    Slider,
    Switch,
    Typography,
    useTheme,
} from "@mui/material";
import {display, styled} from "@mui/system";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/de";
import "dayjs/locale/cs";
import "dayjs/locale/ru";
import "dayjs/locale/ro";
import "dayjs/locale/pl";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import {ReservationDetailContent} from "../../../containers/reservation/ReservationCard";
import Button from "@mui/material/Button";
import CustomTimePicker from "../../common/CustomTimePicker";
import CustomDatePicker from "../../common/CustomDatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import SliderCom from "../../common/SliderCom";
import {
    dayjsToDateString,
    dayjsToDateTimeString,
    dayjsToNumberDayAndTimeArray,
    dayjsToNumberDayString,
} from "../../../utils";
import {validateReservation} from "../../../redux/actions/reservation";
import Tracker from "./Tracker";
import {themeOptionsLight} from "../../../theme";

dayjs.extend(isBetweenPlugin);

const ButtonNavigation = styled("div")(({theme}) => ({
    display: "flex",
    height: "60px",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    bottom: 0,
    justifyContent: "flex-end",
    width: "100%",
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    alignItems: "center",
}));

const Content1 = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
    marginTop: "50px",
}));

const Content2 = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: "20px",
}));

const Attachment = styled("img")(({theme}) => ({
    paddingTop: "5px",
}));

const TitleRow = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
}));

const CloseButton = styled(IconButton)(({theme}) => ({
    left: 10,
}));

const Opps = styled("div")({
    marginBottom: "30px",
    marginTop: "30px",
});

const marks = [
    {
        value: 0,
    },
    {
        value: 20,
    },
    {
        value: 30,
    },
    {
        value: 40,
    },
    {
        value: 50,
    },
    {
        value: 60,
    },
    {
        value: 100,
    },
    {
        value: 200,
    },
];

const Timewrapper = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: "30",
}));

const NewReservation = (props) => {
    const {
        openNew,
        handleClose,
        handleSave,
        availableLocations,
        availableAssets,
        fetchAvailableAssets,
        codeLists,
        editReservation,
        reservationsErrors,
        user,
        userUseCases,
        all,
        users,
        locations,
        saving,
        validateReservation,
    } = props;

    const [activeStep, setActiveStep] = useState(0);
    const [error, setError] = useState("");
    const [errorFrom, setErrorFrom] = useState(false);
    const [errorTo, setErrorTo] = useState(false);

    const [errorLocation, setErrorLocation] = useState(false);
    const [errorAsset, setErrorAsset] = useState(false);

    const [locationsFetched, setLocationsFetched] = useState(false);
    const [assetsFetched, setAssetsFetched] = useState(false);

    const [readyToSave, setReadyToSave] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const [asset, setAsset] = useState();
    const [assets, setAssets] = useState("");
    const [assetsOut, setAssetsOut] = useState("");
    const [location, setLocation] = useState();
    const [dateFrom, setDateFrom] = useState(dayjs().add(1, "h").set("m", 0));
    const [disabledDateFrom, setDisabledDateFrom] = useState(
        dayjs().add(-1, "d").add(1, "h").set("m", 0)
    );
    const [disabledTimeFrom, setDisabledTimeFrom] = useState(0);
    const [dateTo, setDateTo] = useState(dayjs().add(1, "d").set("m", 0));
    const [reservationId, setReservationId] = useState();
    const [usersValues, setUsersValues] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [notification, setNotification] = useState(true);
    const [notBefore, setNotBefore] = useState(30);
    const [notAfter, setNotAfter] = useState(30);

    const handleSelectedUser = (value) => {
        setSelectedUser(value);
    };

    const {t, i18n} = useTranslation();
    const theme = useTheme();

    const setFrom = (from, isTime) => {
        const dateFromInner = isTime ? dateFrom : from;
        const timeFromInner = isTime ? from : dateFrom.hour(8).minute(0);
        const dateFromS = dateFromInner
            .hour(timeFromInner.hour())
            .minute(timeFromInner.minute());
        setDateFrom(dateFromS);
        setDisabledDateFrom(dateFromS.add(-1, "d"));

        if (dateFromS > dateTo) {
            const dateToS = dateFromInner
                .hour(timeFromInner.hour() + 1)
                .minute(timeFromInner.minute());
            setDateTo(dateToS);
            if (
                dateToS.get("y") == dateFromS.get("y") &&
                dateToS.get("d") == dateFromS.get("d")
            ) {
                setDisabledTimeFrom(timeFromInner.hour() - 1);
            } else {
                setDisabledTimeFrom(undefined);
            }
        } else {
            setDisabledTimeFrom(undefined);
        }
    };

    const setTo = (to, isTime) => {
        const dateToInner = isTime ? dateTo : to;
        const timeToInner = isTime ? to : dateTo.hour(8).minute(0);
        let dateToS = dateToInner
            .hour(timeToInner.hour())
            .minute(timeToInner.minute());
        if (
            !isTime &&
            dateToS.get("y") == dateFrom.get("y") &&
            dateToS.get("d") == dateFrom.get("d")
        ) {
            setDisabledTimeFrom(dateFrom.hour() - 1);
            dateToS = dateToS.hour(dateFrom.hour() + 1);
        } else {
            setDisabledTimeFrom(undefined);
        }
        setDateTo(dateToS);
    };

    useEffect(() => {
        if (users) {
            setUsersValues(
                users.map((a) => {
                    return {
                        id: a.id,
                        firstName: a.firstName,
                        lastName: a.lastName,
                        licensePlate: a.licensePlate,
                        value: a.id,
                        label: `${a.firstName} ${a.lastName} - [${a.email}]`,
                        key: a.id,
                    };
                })
            );
        }
    }, [users]);

    useEffect(() => {
        if (editReservation) {
            setDateFrom(editReservation.dateFromD);
            setDateTo(editReservation.dateToD);
            setLocation({
                locationId: editReservation.locationId,
                name: editReservation.locationName,
            });
            setAsset({
                assetId: editReservation.assetId,
                name: editReservation.assetName,
            });
            setReservationId(editReservation.reservationId);
        }
    }, [editReservation]);

    useEffect(() => {
        if (reservationsErrors.response?.data?.status !== undefined) {
            let args = reservationsErrors.response?.data?.errorParams;
            if (reservationsErrors.response?.data?.errorCode === E0141) {
                args = args.map((e) => e.name).join(", ");
            }
            if (reservationsErrors.response?.data?.errorCode) {
                setError(
                    t(reservationsErrors.response?.data?.errorCode, {
                        args: args,
                    })
                );
            } else {
                setError(t("UNKNOWN_ERROR"));
            }
            setSnackbarOpen(true);
        }
    }, [reservationsErrors]);

    useEffect(() => {
        if (availableAssets && location) {
            let tmp = [];
            if (availableAssets.assets) {
                setAssets(
                    availableAssets.assets.filter(
                        (item) => item.locationId === location.locationId
                    )
                );
                tmp = availableAssets.assets.filter(
                    (item) => item.locationId !== location.locationId
                );
            }
            if (availableAssets.otherAssets) {
                tmp = tmp.concat(availableAssets.otherAssets);
            }
            setAssetsOut(tmp);
        }
    }, [availableAssets]);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
        setError(null);
    };

    const handleNext = () => {
        if (activeStep === 0) {
            let anyErrorForStep1 = false;
            if (!dateFrom) {
                setErrorFrom(true);
                anyErrorForStep1 = true;
            } else {
                setErrorFrom(false);
            }
            if (!dateTo) {
                anyErrorForStep1 = true;
                setErrorTo(true);
            } else {
                setErrorTo(false);
            }

            if (dateFrom && dateTo) {
                if (dateTo.isBefore(dateFrom)) {
                    anyErrorForStep1 = true;
                    setErrorTo(true);
                }
            }

            if (!anyErrorForStep1) {
                handleValidationLocal();
                // setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else if (activeStep === 1) {
            if (location) {
                setErrorLocation(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setErrorLocation(true);
            }
        } else if (activeStep === 2) {
            if (asset) {
                setErrorAsset(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                setErrorAsset(true);
            }
        } else if (activeStep === 3) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleAssetSelect = (selectedAsset) => {
        if (activeStep !== 2) return;
        if (asset != null && selectedAsset.assetId === asset.assetId) {
            setAsset(null);
        } else {
            setAsset(selectedAsset);
        }
    };

    const handleLocationSelect = (selectedLocation) => {
        if (activeStep !== 1) return;
        if (
            location != null &&
            selectedLocation.locationId === location.locationId
        ) {
            setLocation(null);
        } else {
            setLocation(selectedLocation);
        }
    };

    const handleSaveLocal = () => {
        handleSave(getReservationInner());
    };

    const handleValidationLocal = () => {
        validateReservation(getReservationInnerValidate())
            .then(() => {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            })
            .catch((err) => {
                console.log("validateReservation error", err);
            });
    };

    useEffect(() => {
        if (
            dateFrom !== undefined &&
            dateFrom !== null &&
            dateTo !== undefined &&
            dateTo !== null &&
            location != null &&
            activeStep === 2
        ) {
            const dateFromS = dateFrom.format(DATE_TIME_FORMAT);
            const dateToS = dateTo.format(DATE_TIME_FORMAT);
            setAssetsFetched(false);
            setAssets([]);
            fetchAvailableAssets(
                null,
                dateFromS,
                dateToS,
                all && selectedUser ? selectedUser.key : user.id,
                reservationId ? reservationId : 0
            ).then(() => {
                setAssetsFetched(true);
            });
        }
    }, [dateFrom, dateTo, location, activeStep]);

    const getReservationInner = () => {
        const dateFromLoc = dateFrom;
        const dateToLoc = dateTo;
        const us = selectedUser ? selectedUser : user;
        return {
            locationName: location.name,
            locationId: location.locationId,
            assetId: asset.assetId,
            assetName: asset.name,
            dateFromD: dateFromLoc,
            dateToD: dateToLoc,
            dateFrom: dateFromLoc.format(DATE_TIME_FORMAT),
            dateTo: dateToLoc.format(DATE_TIME_FORMAT),
            reservationId,
            img: asset.img,
            reservedById: us.id,
            reservedByName: us.username,
            reservedByFirstName: us.firstName,
            reservedBySurname: us.lastName,
            reservedByEmail: us.email,
            licensePlate: us.licensePlate,
            beforeStart: notification ? notBefore : null,
            beforeEnd: notification ? notAfter : null,
        };
    };

    const getReservationInnerValidate = () => {
        const dateFromLoc = dateFrom;
        const dateToLoc = dateTo;
        const us = selectedUser ? selectedUser : user;
        return {
            dateFromD: dateFromLoc,
            dateToD: dateToLoc,
            dateFrom: dateFromLoc.format(DATE_TIME_FORMAT),
            dateTo: dateToLoc.format(DATE_TIME_FORMAT),
            reservationId,
            reservedById: us.id,
            reservedByName: us.username,
            reservedByFirstName: us.firstName,
            reservedBySurname: us.lastName,
            reservedByEmail: us.email,
            licensePlate: us.licensePlate,
        };
    };

    const renderAssets = (onlySelected = false) => {
        let tmp = [];
        if (assets && assets.length > 0) {
            assets.forEach((item) => {
                if (onlySelected) {
                    if (item.selected) tmp.push(item);
                } else {
                    tmp.push(item);
                }
            });

            return tmp.map((ast) => {
                return (
                    <Paper
                        key={ast.assetId}
                        elevation={0}
                        sx={{
                            width: "220px",
                            padding: 2,
                            filter: asset
                                ? asset.assetId === ast.assetId
                                    ? "none"
                                    : "grayscale(100%)"
                                : "none",

                            opacity: asset
                                ? asset.assetId === ast.assetId
                                    ? "1"
                                    : "0.5"
                                : "1",
                            backgroundColor: (theme) => theme.palette.background.paper,
                            boxShadow:
                                asset != null
                                    ? `4px 4px 15px 3px ${theme.palette.shadow}`
                                    : `3px 3px 5px 1px ${theme.palette.shadow}`,
                            transition: "ease-in-out 0.3s",
                        }}
                        onClick={() => {
                            handleAssetSelect(ast);
                        }}
                    >
                        <div
                            style={{
                                height: "160px",
                                width: "100%",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 10,
                                marginBottom: 10,
                            }}
                        >
                            <Attachment
                                src={
                                    ast.img
                                        ? `data:image/png;base64,${ast.img}`
                                        : ast.type === ASSET_TYPE_PLACE
                                            ? "/misto.png"
                                            : "/auto.png"
                                }
                                style={{
                                    width: "auto",
                                    height: "100%",
                                    padding: !ast.img ? "5px" : "0px",
                                }}
                            />
                        </div>
                        <Typography variant="body1" fontWeight="600">
                            {ast.locationName}
                        </Typography>
                        <Typography sx={{opacity: "0.5", marginTop: 1}}>
                            {ast.name}
                        </Typography>
                    </Paper>
                );
            });
        } else if (assets && assets.length === 0 && assetsFetched) {
            return (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "100%",
                    }}
                >
                    <Opps>
                        <img src="/jejda.png" width="270px" alt="oops"/>
                    </Opps>
                    <Typography
                        variant="h5"
                        align="center"
                        style={{paddingBottom: "15px"}}
                    >
                        {t("OPPS")}
                    </Typography>
                    <Typography
                        variant="body1"
                        align="left"
                        style={{paddingBottom: "15px"}}
                    >
                        {t("NO_PLACES")}
                    </Typography>
                    <div style={{border: "1px solid #E7E7E7"}}/>
                    <Typography
                        variant="subtitle2"
                        align="left"
                        style={{paddingTop: "15px", fontWeight: "bold"}}
                    >
                        {t("NO_PLACES2")}
                    </Typography>
                    <Paper
                        elevation={0}
                        sx={{
                            padding: theme.spacing(2),
                            backgroundColor: theme.palette.background.default,
                            display: "grid",
                            gridTemplateColumns: "repeat(auto-fit, minmax(330px, 1fr))",
                            gap: theme.spacing(2),
                            width: "100%",
                            justifyContent: "center",
                            justifyItems: "center",
                        }}
                    >
                        {assetsOut &&
                            assetsOut.map((item) => {
                                return (
                                    <Paper
                                        key={item.assetId}
                                        elevation={0}
                                        sx={{
                                            width: "100%",
                                            paddingTop: 2,
                                            paddingBottom: 3,
                                            paddingLeft: 1,
                                            paddingRight: 1,
                                            cursor: "pointer",
                                            transition: "ease-in-out 0.3s",
                                            backgroundColor: (theme) =>
                                                theme.palette.background.paper,
                                            filter: asset
                                                ? asset.assetId === item.assetId
                                                    ? "none"
                                                    : "grayscale(100%)"
                                                : "none",

                                            opacity: asset
                                                ? asset.assetId === item.assetId
                                                    ? "1"
                                                    : "0.5"
                                                : "1",
                                            boxShadow:
                                                asset && asset.assetId === item.assetId
                                                    ? `4px 4px 15px 3px ${theme.palette.shadow}`
                                                    : `3px 3px 5px 1px ${theme.palette.shadow}`,
                                            margin: "10px",
                                        }}
                                        onClick={() => {
                                            handleAssetSelect(item);
                                        }}
                                    >
                                        <Typography
                                            variant="subtitle2"
                                            align="left"
                                            sx={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {item.locationName}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                paddingBottom: "15px",
                                                fontSize: "0.8rem",
                                                color: (theme) => theme.palette.secondary.subtleText,
                                            }}
                                        >
                                            {item.name}
                                        </Typography>

                                        <span
                                            style={{
                                                display: "grid",
                                                gridTemplateColumns: "1fr 20px 1fr",
                                                width: "100%",
                                                alignItems: "center",
                                                gap: 10,
                                            }}
                                        >
                      <Paper
                          sx={{
                              backgroundColor: theme.palette.background.default,
                              boxShadow: "none",
                              width: "100%",
                              display: "flex",
                              gap: 2,
                              justifyContent: "space-between",
                              padding: 1.4,
                              borderRadius: "10px",
                          }}
                      >
                        <Typography
                            align="center"
                            variant={"body2"}
                            sx={{whiteSpace: "nowrap"}}
                        >
                          {
                              dayjsToNumberDayAndTimeArray(
                                  dateFrom,
                                  i18n.language
                              )[0]
                          }
                        </Typography>
                        <Typography
                            align="center"
                            variant={"body2"}
                            sx={{whiteSpace: "nowrap"}}
                        >
                          {
                              dayjsToNumberDayAndTimeArray(
                                  dateFrom,
                                  i18n.language
                              )[1]
                          }
                        </Typography>
                      </Paper>
                      <Typography
                          align="center"
                          sx={{padding: 0, margin: 0}}
                          variant={"body1"}
                      >
                        {t("TO").toLowerCase()}
                      </Typography>
                      <Paper
                          sx={{
                              backgroundColor: theme.palette.background.default,
                              boxShadow: "none",
                              width: "100%",
                              display: "flex",
                              gap: 2,
                              justifyContent: "space-between",
                              padding: 1.4,
                              borderRadius: "10px",
                          }}
                      >
                        <Typography
                            align="center"
                            variant={"body2"}
                            sx={{whiteSpace: "nowrap"}}
                        >
                          {
                              dayjsToNumberDayAndTimeArray(
                                  dateTo,
                                  i18n.language
                              )[0]
                          }
                        </Typography>
                        <Typography
                            align="center"
                            variant={"body2"}
                            sx={{whiteSpace: "nowrap"}}
                        >
                          {
                              dayjsToNumberDayAndTimeArray(
                                  dateTo,
                                  i18n.language
                              )[1]
                          }
                        </Typography>
                      </Paper>
                    </span>
                                    </Paper>
                                );
                            })}
                    </Paper>
                </div>
            );
        } else {
            return (
                <>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Grid item xs={12}>
                            <Skeleton
                                sx={{
                                    width: "100%",
                                    marginTop: "50px",
                                }}
                                animation="wave"
                            />
                        </Grid>
                    </Grid>
                </>
            );
        }
    };

    const renderLocations = (onlySelected = false) => {
        let tmp = [];
        if (locations && locations.length > 0) {
            locations.forEach((item) => {
                if (onlySelected) {
                    if (location != null && item.locationId === location.locationId)
                        tmp.push(item);
                } else {
                    tmp.push(item);
                }
            });

            return tmp.map((loc) => {
                return (
                    <Paper
                        key={loc.locationId}
                        elevation={0}
                        sx={{
                            width: "220px",
                            height: "280px",
                            padding: 2,
                            filter: location
                                ? location.locationId === loc.locationId
                                    ? "none"
                                    : "grayscale(100%)"
                                : "none",
                            backgroundColor: (theme) => theme.palette.background.paper,
                            opacity: location
                                ? location.locationId === loc.locationId
                                    ? "1"
                                    : "0.5"
                                : "1",
                            color: (theme) => theme.palette.secondary.selection,
                            opacity:
                                location == null ||
                                (location && location.locationId === loc.locationId)
                                    ? 1
                                    : 0.5,
                            boxShadow:
                                location && location.locationId === loc.locationId
                                    ? `4px 4px 15px 3px ${theme.palette.shadow}`
                                    : `3px 3px 5px 1px ${theme.palette.shadow}`,
                            transition: "ease-in-out 0.3s",
                        }}
                        onClick={() => {
                            handleLocationSelect(loc);
                        }}
                    >
                        <div
                            style={{
                                height: "160px",
                                width: "100%",
                                overflow: "hidden",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: 10,
                                marginBottom: 10,
                            }}
                        >
                            <Attachment
                                src={
                                    loc.img
                                        ? `data:image/png;base64,${loc.img}`
                                        : "/parkovacidum.png"
                                }
                                style={{
                                    width: "auto",
                                    height: "100%",
                                    padding: !loc.img ? "5px" : "0px",
                                }}
                            />
                        </div>

                        <Typography variant="body1" fontWeight="600">
                            {loc.name}
                        </Typography>

                        <Typography sx={{opacity: "0.5", marginTop: 1}}>
                            {loc.street}
                        </Typography>
                        <Typography
                            sx={{opacity: "0.5"}}
                        >{`${loc.city}, ${loc.zip}`}</Typography>
                    </Paper>
                );
            });
        } else if (locations && locations.length === 0 && locationsFetched) {
            return (
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h4" align="center">
                            {t("NO_RECORD")}
                        </Typography>
                    </Grid>
                </Grid>
            );
        } else {
            return (
                <>
                    <Grid
                        container
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Grid item xs={12}>
                            <Skeleton
                                sx={{
                                    width: "100%",
                                    marginTop: "50px",
                                }}
                                animation="wave"
                            />
                        </Grid>
                    </Grid>
                </>
            );
        }
    };

    return (
        <Dialog
            maxWidth="xl"
            sx={{
                "& .MuiDialog-paper": {
                    height: "80%",
                    width: "75%",
                    margin: 1,
                },
            }}
            open={openNew || editReservation}
            onClose={handleClose}
        >
            <DialogTitle
                sx={{
                    height: "100px",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 5,
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    width: "100%",
                }}
            >
                {activeStep === 0 && (
                    <>
                        <Typography
                            variant="h4"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {t("CREATE_RESERVATION")}
                        </Typography>
                    </>
                )}
                {activeStep === 1 && (
                    <>
                        <Typography
                            variant="h4"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {t("LOCATION_SELECTION")}
                        </Typography>
                    </>
                )}
                {activeStep === 2 && (
                    <>
                        <Typography
                            variant="h4"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {t("PLACE_SELECTION")}
                        </Typography>
                    </>
                )}
                {activeStep === 3 && (
                    <>
                        <Typography
                            variant="h4"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {t("NOTIFICATION_SETTING")}
                        </Typography>
                    </>
                )}
                {activeStep === 4 && (
                    <>
                        <Typography
                            variant="h4"
                            sx={{
                                color: (theme) => theme.palette.background.contrastText,
                            }}
                        >
                            {t("SUMMARY")}
                        </Typography>
                    </>
                )}

                <CloseButton aria-label="delete" size="small" onClick={handleClose}>
                    <ClearIcon fontSize="medium"/>
                </CloseButton>
            </DialogTitle>

            <DialogContent sx={{padding: 8}}>
                <Tracker step={activeStep}/>
                <PDSSnackbar
                    open={snackbarOpen}
                    onClose={handleCloseSnackbar}
                    severity={"error"}
                    message={error}
                    position="TOP"
                />

                {activeStep === 0 && (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <Paper
                            sx={{
                                padding: 2,
                                backgroundColor: (theme) => theme.palette.background.default,
                            }}
                            elevation={0}
                        >
                            <Typography variant="subtitle1">{t("FROM")}</Typography>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "2fr 1fr",
                                    gap: 15,
                                }}
                            >
                                <CustomDatePicker
                                    date={dateFrom}
                                    setDate={(from) => setFrom(from, false)}
                                    error={errorFrom}
                                />
                                <CustomTimePicker
                                    time={dateFrom}
                                    setTime={(from) => setFrom(from, true)}
                                    error={errorFrom}
                                    sx={{paddingLeft: "10px"}}
                                />
                            </div>
                            <Typography variant="subtitle1" sx={{paddingRight: "16px"}}>
                                {t("TO")}
                            </Typography>
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "2fr 1fr",
                                    gap: 15,
                                }}
                            >
                                <CustomDatePicker
                                    date={dateTo}
                                    setDate={(to) => setTo(to, false)}
                                    error={errorTo}
                                    disableDateFrom={disabledDateFrom}
                                />

                                <CustomTimePicker
                                    time={dateTo}
                                    setTime={(to) => setTo(to, true)}
                                    error={errorTo}
                                    disableTimeFrom={disabledTimeFrom}
                                />
                            </div>
                            {all && (
                                <div>
                                    <Typography variant="subtitle1" sx={{paddingRight: "16px"}}>
                                        &nbsp;
                                    </Typography>
                                    <Autocomplete
                                        multiple={false}
                                        value={selectedUser || null}
                                        onChange={(event, newValue) => {
                                            handleSelectedUser(newValue);
                                        }}
                                        isOptionEqualToValue={(option, value) => {
                                            return option.value === value.value;
                                        }}
                                        filterSelectedOptions
                                        id="usersValues"
                                        options={usersValues || []}
                                        getOptionLabel={(option) => {
                                            return option.label;
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                variant="outlined"
                                                {...params}
                                                label={t("USER")}
                                            />
                                        )}
                                        renderTags={(value, getTagProps) => {
                                            return value.map((option, index) => {
                                                return (
                                                    <Chip
                                                        style={{
                                                            backgroundColor: option.lightColor,
                                                            color: "black",
                                                        }}
                                                        variant="outlined"
                                                        label={option.label}
                                                        {...getTagProps({
                                                            index,
                                                        })}
                                                    />
                                                );
                                            });
                                        }}
                                    />
                                </div>)}
                        </Paper>
                    </div>
                )}
                {activeStep === 1 && (
                    <div
                        style={{
                            marginTop: "20px",
                            display: "flex",
                            gap: "30px",
                            maxWidth: "80vw",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}
                    >
                        {renderLocations()}
                    </div>
                )}
                {activeStep === 2 && (
                    <div
                        style={{
                            marginTop: "20px",
                            display: "flex",
                            gap: "30px",
                            maxWidth: "80vw",
                            flexWrap: "wrap",
                            justifyContent: "center",
                        }}
                    >
                        {renderAssets()}
                    </div>
                )}
                {activeStep === 3 && (
                    <span
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            marginTop: 10,
                        }}
                    >
            <Paper
                elevation={0}
                sx={{
                    backgroundColor: (theme) => theme.palette.background.default,
                    padding: 3,
                }}
            >
              <Box sx={{width: 250, height: 50}}>
                <FormControlLabel
                    control={
                        <Switch
                            checked={notification}
                            onChange={(event) =>
                                setNotification(event.target.checked)
                            }
                            inputProps={{
                                "aria-label": "controlled",
                            }}
                        />
                    }
                    label={t("NOTIFICATIONS")}
                />
              </Box>
              <div
                  style={{
                      transition:
                          "opacity 0.3s ease-in-out, max-height 0.3s ease-in-out",
                      opacity: notification ? 1 : 0,
                      maxHeight: notification ? "300px" : "0",
                      overflow: "hidden",
                  }}
              >
                <Box sx={{width: 250, height: 100}}>
                  <Typography gutterBottom sytle={{paddingBottom: 50}}>
                    {t("MINUTES_BEFORE")}
                  </Typography>

                  <Select
                      value={notBefore}
                      onChange={(event) => {
                          setNotBefore(event.target.value);
                      }}
                      sx={{
                          backgroundColor: "white",
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                          },
                          "& .MuiOutlinedInput-input": {
                              border: 0,
                          },
                      }}
                  >
                    {Array.from({length: 15}).map((_, i) => (
                        <MenuItem key={i * 15} value={i * 15}>
                            {`${i * 15} ${t("MINUTES").toLowerCase()}`}
                        </MenuItem>
                    ))}
                  </Select>
                </Box>
                <Box sx={{width: 250, height: 100}}>
                  <Typography gutterBottom>{t("MINUTES_AFTER")}</Typography>

                  <Select
                      value={notAfter}
                      onChange={(event) => {
                          setNotAfter(event.target.value);
                      }}
                      sx={{
                          backgroundColor: "white",
                          width: "100%",
                          "& .MuiOutlinedInput-notchedOutline": {
                              border: 0,
                          },
                          "& .MuiOutlinedInput-input": {
                              border: 0,
                          },
                      }}
                  >
                    {Array.from({length: 15}).map((_, i) => (
                        <MenuItem key={i * 15} value={i * 15}>
                            {`${i * 15} ${t("MINUTES").toLowerCase()}`}
                        </MenuItem>
                    ))}
                  </Select>
                </Box>
              </div>
            </Paper>
          </span>
                )}

                {activeStep === 4 && (
                    <ReservationDetailContent
                        width={300}
                        height={"auto"}
                        reservation={getReservationInner()}
                        userUseCases={userUseCases}
                        modal={true}
                    />
                )}
            </DialogContent>

            <DialogActions
                sx={{
                    padding: 1,
                    height: 100,
                    width: "100%",
                }}
            >
                <ButtonNavigation>
                    {saving && <CircularProgress sx={{zIndex: 2000}} size={24}/>}
                    <Button
                        sx={{
                            zIndex: 1000,
                            width: "150px",
                            margin: 1,
                        }}
                        disabled={activeStep <= 0}
                        onClick={handleBack}
                        color="primary"
                        variant="outlined"
                    >
                        {t("BACK")}{" "}
                    </Button>
                    {activeStep < 4 && (
                        <Button
                            sx={{
                                zIndex: 1000,
                                width: "150px",
                                margin: 1,
                            }}
                            disabled={
                                (activeStep === 1 && !location) ||
                                (activeStep === 2 &&
                                    (!asset ||
                                        ((!assets || assets.length === 0) &&
                                            (!assetsOut || assetsOut.length === 0))))
                            }
                            variant="outlined"
                            color="secondary"
                            onClick={handleNext}
                        >
                            {" "}
                            {t("CONTINUE")}
                        </Button>
                    )}
                    {activeStep === 4 && (
                        <Button
                            sx={{
                                zIndex: 1000,

                                width: "150px",
                                margin: 1,
                            }}
                            variant="outlined"
                            color="primary"
                            onClick={handleSaveLocal}
                        >
                            {" "}
                            {t("SAVE")}
                        </Button>
                    )}
                </ButtonNavigation>
            </DialogActions>
        </Dialog>
    );
};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            validateReservation,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(NewReservation);
