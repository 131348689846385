import httpService from './http.service';


const getCodeLists = () => httpService
    .get(`/codelists`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));


export default {
    getCodeLists,
};