import React from 'react';
import { useTranslation } from 'react-i18next';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {styled} from "@mui/system";
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import {UC0170} from "../../../utils/constants";
import IconButton from "@mui/material/IconButton/IconButton";
import Add from "@mui/icons-material/Add";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import ToolBarCommon from "../../../components/common/ToolBarCommon";


const Toolbar = styled('div')(({ theme }) => ({
    width: '86%',
    marginLeft: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    position: 'relative',

}));
const KeyboardDoubleArrowDown = styled(KeyboardDoubleArrowDownIcon)(({ theme, shown }) => ({
    position: 'absolute',
    top: '40px',
    left: '-20px',
    zIndex: 4000,
    [theme.breakpoints.only('xs')]: {
        display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },

}));

const KeyboardDoubleArrowUp = styled(KeyboardDoubleArrowUpIcon)(({ theme, shown }) => ({
    position: 'absolute',
    top: '40px',
    left: '-20px',
    zIndex: 4000,
    [theme.breakpoints.only('xs')]: {
        display: 'block',
    },
    [theme.breakpoints.up('sm')]: {
        display: 'none',
    },

}));


function ToolBar(props) {


    const {
        userUseCases,
        handleUserUpdate,
        selectedName,
        handleSelectedName,
        handleNewGroup,
        user,
    } = props;

    const { t } = useTranslation();

    const [shown, setShown] = React.useState(true);
    const toggleDrawer = (open) => (event) => {
        setShown(!shown);
    };


    return (
        <ToolBarCommon shown={shown} setShown={setShown} title={t('GROUP_LIST')}>


            <Grid item md={3} xs={9}
                sx={{ marginRight: '0px !important', paddingTop: '25px !important', display: shown ? 'flex' : { 'xs': 'none', 'lg': 'flex' }, justifyContent: 'flex-end' }}>
                <TextField
                    variant="outlined"
                    type="search"
                    label={t('GROUP_NAME')}
                    fullWidth
                    value={selectedName || ''}
                    onChange={handleSelectedName} />

            </Grid>
            <Grid item md={1} xs={shown ? 2 : 11} sx={{borderRadius: '30px', display: 'flex', marginTop: '25px !important', marginLeft: '-2%', paddingTop: '0 !important', justifyContent: 'center'}}>
                {isComponentVisibleForUser(UC0170, userUseCases) && <div>
                    <IconButton
                        edge="start"
                        aria-label="add place"
                        onClick={handleNewGroup}
                        size="large"
                        disabled={!isComponentEnabledForUser(UC0170, userUseCases)}>
                        <Add/>
                    </IconButton>
                </div>
                }
            </Grid>

        </ToolBarCommon>
    )
        ;
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
