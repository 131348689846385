import types from '../actionTypes';
import locationService from '../../services/location.service';


export const createLocation = (location) => (dispatch, getState) => {
    if (getState().locationData.isCreating) {
        return;
    }

    dispatch({
        type: types.LOCATION_CREATE_REQUEST,
    });

    return locationService.createLocation(location)
        .then((newLocation) => {
            dispatch({
                type: types.LOCATION_CREATE_SUCCESS,
                payload: {location: newLocation},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.LOCATION_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateLocation = (location) => (dispatch, getState) => {
    if (getState().locationData.isUpdating) {
        return;
    }

    dispatch({
        type: types.LOCATION_UPDATE_REQUEST,
    });

    return locationService.updateLocation(location)
        .then((newLocation) => {
            dispatch({
                type: types.LOCATION_UPDATE_SUCCESS,
                payload: {location: newLocation},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.LOCATION_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const actiavateLocation = (locationId, active) => (dispatch, getState) => {
    if (getState().locationData.isUpdating) {
        return;
    }

    dispatch({
        type: types.LOCATION_ACTIVE_REQUEST,
    });

    return locationService.activeLocation(locationId, active)
        .then((newLocation) => {
            dispatch({
                type: types.LOCATION_ACTIVE_SUCCESS,
                payload: {location: newLocation},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.LOCATION_ACTIVE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchLocations = () => (dispatch, getState) => {
    if (getState().locationData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.LOCATION_FETCH_REQUEST,
    });

    return locationService.getLocations()
        .then((locations) => {
            dispatch({
                type: types.LOCATION_FETCH_SUCCESS,
                payload: { locations },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.LOCATION_FETCH_FAIL,
                payload: { error },
            });

            throw error;
        });
};

