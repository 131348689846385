import React from "react";
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux';
import {ReactKeycloakProvider} from '@react-keycloak/web';
import 'react-perfect-scrollbar/dist/css/styles.css';

import store, {history} from './redux/store';
import {keycloak, keycloakInitConfig, onKeycloakEvent, onKeycloakTokens,} from './services/keycloak.service';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import Loading from './components/common/Loading';
import './style.css';


const root = ReactDOM.createRoot(document.getElementById("root"));

const LoadingScreen = props => {
  return (
    <div>

    </div>
  )
}

root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={keycloakInitConfig}
    onEvent={onKeycloakEvent(store)}
    onTokens={onKeycloakTokens}
    LoadingComponent={<LoadingScreen/>}
  >
    <Provider store={store}>
      <BrowserRouter history={history}>
          <App />
      </BrowserRouter>
    </Provider>
  </ReactKeycloakProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
