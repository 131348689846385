import types from '../actionTypes';

const INITIAL_STATE = {
    isCreating: false,
    isUpdating: false,
    isFetchingAllList: false,
    isFetchingAvailableList: false,
    errors: '',
    assets: [],
    availableAssets: [],
    availableAssetFrom: null,
    availableAssetTo: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.ASSET_CREATE_SUCCESS:
            return {
                ...state, isCreating: false
            };
        case types.ASSET_CREATE_REQUEST:
            return {
                ...state, isCreating: true
            };
        case types.ASSET_CREATE_FAIL:
            return {
                ...state, 
                isCreating: true,
                errors: action.payload.error,
            };

        case types.ASSET_UPDATE_SUCCESS:
            return {
                ...state, isUpdating: false
            };
        case types.ASSET_UPDATE_REQUEST:
            return {
                ...state, isUpdating: true
            };
        case types.ASSET_UPDATE_FAIL:
            return {
                ...state, 
                isUpdating: true,
                errors: action.payload.error,
            };
        case types.ASSET_AVAILABLE_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAvailableList: false,
                availableAssets: action.payload.assets,
                availableAssetFrom: action.payload.dateFrom,
                availableAssetTo: action.payload.dateTo,
            };
        case types.ASSET_AVAILABLE_FETCH_FAIL:
            return {
                ...state,
                isFetchingAvailableList: false,
                errors: action.payload.error,
            };
        case types.ASSET_AVAILABLE_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAvailableList: true,
            };

        case types.ASSET_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAvailableList: false,
                assets: action.payload.assets?.assets,
            };
        case types.ASSET_FETCH_FAIL:
            return {
                ...state,
                isFetchingAvailableList: false,
                errors: action.payload.error,
            };
        case types.ASSET_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAvailableList: true,
            };
        default:
            return state;
    }
};
