import React from 'react';
import PropTypes from 'prop-types';
import {BallSpinner} from 'react-spinners-kit';
import {styled} from "@mui/system";

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));




function Loading(props) {
  return (
    <Container name="spinner" >
    	<BallSpinner size={50} color="#686769" loading={ true } />
    </Container>
  );
}

Loading.propTypes = {
  color: PropTypes.string,
};

Loading.defaultProps = {
  color: 'secondary',
};

export default Loading;
