import httpService from './http.service';

const getUsersAll = () => httpService
    .get(`/users`)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err.response));

const createUser = (user) => httpService
    .post(`/users`, user)
    .then(({data}) => data)
    .catch((err) => {
        throw err;
    });

const updateUser = (user) => httpService
    .put(`/users`, user)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));


export default {
    getUsersAll,
    createUser,
    updateUser,
};
