import httpService from './http.service';

const updateSetting = (setting) => httpService
    .put('/settings', setting)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const fetchSetting = () => httpService
    .get('/settings')
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

export default {
    updateSetting,
    fetchSetting,
};