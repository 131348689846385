import types from '../actionTypes';
import parkingActiveService from '../../services/parkingActive.service';


export const fetchParkingActive = (parkingPlaceId, lpn, email, receiptId, dateFrom, dateTo) => (dispatch, getState) => {
    if (getState().parkingActiveDate.isFetchingParkingActive) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_ACTIVE_FETCH_REQUEST,
    });

    return parkingActiveService.fetchParkingActive(parkingPlaceId, lpn, email, receiptId, dateFrom, dateTo)
        .then((parkingActive) => {
            dispatch({
                type: types.PARKING_ACTIVE_FETCH_SUCCESS,
                payload: {parkingActive: parkingActive},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_ACTIVE_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};
