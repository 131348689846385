import httpService from './http.service';

const fetchReceipts = (parkingPlaceId, lpn, email, receiptId, dateFrom, dateTo) => httpService
    .get('/receipt?'+getParams(parkingPlaceId, lpn, email, receiptId, dateFrom, dateTo))
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const forwardReceipt = (receipt, email) => httpService
    .put('/receipt/resend?email='+encodeURIComponent(email), receipt)
    .then(({data}) => data)
    .catch((err) => Promise.reject(err));

const getParams = (parkingPlaceId, lpn, email, receiptId, dateFrom, dateTo) => {
    let result = parkingPlaceId ? 'parkingPlaceId='+parkingPlaceId:'';
    result = result + (lpn?(result?'&':'')+'lpn='+lpn:'');
    result = result + (email?(result?'&':'')+'email='+email:'');
    result = result + (receiptId?(result?'&':'')+'receiptId='+receiptId:'');
    result = result + (dateFrom?(result?'&':'')+'dateFrom='+new Date(dateFrom).toISOString():'');
    result = result + (dateTo?(result?'&':'')+'dateTo='+new Date(dateTo).toISOString():'');
    return result;
}

export default {
    fetchReceipts, forwardReceipt
};