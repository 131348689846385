import React from 'react';
import {useTranslation} from 'react-i18next';
import {styled} from "@mui/system";
import {DateTimePicker} from "@mui/x-date-pickers";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/en-gb';
import 'dayjs/locale/de';
import 'dayjs/locale/cs';
import 'dayjs/locale/ru';
import 'dayjs/locale/ro';
import 'dayjs/locale/pl';
import {getLocaleDateTimeShortString} from "../../i18n";

const CustomDateTimePickerCss = styled(DateTimePicker)(({theme}) => ({
    width: '100%',
}));

export default function CustomDateTimePicker({
                                                 dateTime,
                                                 setDateTime,
                                                 error,
                                             }) {
    const {t, i18n} = useTranslation();


    return (

        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>

            <CustomDateTimePickerCss
                slotProps={{
                    field: { clearable: true },
                }}
                value={dateTime}
                onChange={(val) => setDateTime(val)}
                desktopModeMediaQuery="(min-width:600px)"
                format={getLocaleDateTimeShortString(i18n.language)}
                slotProps={{textField: {error: !!error, helperText: error}}}
                dayOfWeekFormatter={(day) => `${day}.`}
            />
        </LocalizationProvider>
    );
}

