import types from "../actionTypes";

const INITIAL_STATE = {
    isFetchingRoles: false,
    roles: [],
    isFetchingRolesByTenantId: false,
    rolesByTenantId: [],
    errors: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.ROLES_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingRoles: false,
                roles: action.payload.roles,
            };
        case types.ROLES_FETCH_FAIL:
            return {
                ...state,
                isFetchingRoles: false,
                errors: action.payload.error,
            };
        case types.ROLES_FETCH_REQUEST:
            return {
                ...state,
                isFetchingRoles: true,
            };

        case types.ROLES_BY_TENANT_ID_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingRolesByTenantId: false,
                rolesByTenantId: action.payload.roles,
            };
        case types.ROLES_BY_TENANT_ID_FETCH_FAIL:
            return {
                ...state,
                isFetchingRolesByTenantId: false,
                errors: action.payload.error,
            };
        case types.ROLES_BY_TENANT_ID_FETCH_REQUEST:
            return {
                ...state,
                isFetchingRolesByTenantId: true,
            };
        default:
            return state;
    }
};
