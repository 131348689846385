import types from '../actionTypes';
import parkingPlaceService from '../../services/parkingPlces.service';


export const fetchParkingPlaces = () => (dispatch, getState) => {
    if (getState().parkingPlaceData.isFetchingParkingPlaces) {
        return Promise.reject();
    }

    dispatch({
        type: types.PARKING_PLACE_FETCH_REQUEST,
    });

    return parkingPlaceService.fetchParkingPlaces()
        .then((parkingPlaces) => {
            dispatch({
                type: types.PARKING_PLACE_FETCH_SUCCESS,
                payload: {parkingPlaces: parkingPlaces},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.PARKING_PLACE_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};
