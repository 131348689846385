import React from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link } from "react-router-dom";
import {
    UC0002,
    UC0003,
    UC0004,
    UC0005,
    UC0006,
    UC0007,
    UC0008,
    UC0180,
    UC0190,
} from "../../utils/constants";
import {
    isComponentEnabledForUser,
    isComponentVisibleForUser,
} from "../../utils";
import { styled } from "@mui/system";
import {
    Button,
    Divider,
    ListItemButton,
    ListItemText,
    Switch,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { logout } from "../../redux/actions/auth";
import { connect } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuButton from "./MenuButton";
import { AccountCircleRounded } from "@mui/icons-material";

const ListDiv = styled("div")({
    width: "90%",
    margin: "auto",
    paddingTop: "1px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
});

const Logo = styled("div")({
    height: "30px",
    marginBottom: "50px",
    marginTop: "30px",
    marginLeft: "10px",
    backgroundSize: "cover",
});

const MenuLink = styled(Link)({
    textDecoration: "none",
    marginBottom: "30px",
});

function Menu(props) {
    const { userUseCases, handleModeChange, user, logout, mode } = props;

    const { t } = useTranslation();

    return (
        <ListDiv role="presentation">
            <List
                sx={{
                    justifyContent: "center",
                }}
            >
                <Logo>
                    <img
                        width="75%"
                        src="/logo-parkingshare-2.png"
                        alt="logo"
                    />
                </Logo>

                {isComponentVisibleForUser(UC0002, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0002, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/reservation`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="Reservation"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("MY_RESERVATION")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                {isComponentVisibleForUser(UC0003, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0003, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/administration/reservation`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="All Reservation"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("ALL_RESERVATION")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                {isComponentVisibleForUser(UC0004, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0004, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/administration/userlist`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="Users"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("USER_LIST")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                {isComponentVisibleForUser(UC0005, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0005, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/administration/placelist`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="Places"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("PLACE_LIST")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                {isComponentVisibleForUser(UC0008, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0008, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/administration/carlist`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="Card"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("CAR_LIST")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                {isComponentVisibleForUser(UC0006, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0006, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/administration/grouplist`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="Groups"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("GROUP_LIST")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                {isComponentVisibleForUser(UC0180, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0180, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/backoffice/receipt`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="receipt"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("RECEIPTS_LIST")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                {isComponentVisibleForUser(UC0190, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0190, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/backoffice/parkingActive`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="receipt"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("PARKING_ACTIVE")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                {isComponentVisibleForUser(UC0007, userUseCases) && (
                    <MenuLink
                        to={
                            isComponentEnabledForUser(UC0007, userUseCases)
                                ? `/${process.env.REACT_APP_PATH}/administration/setting`
                                : "#"
                        }
                    >
                        <ListItemButton
                            key="Admin"
                            sx={{
                                borderBottom: (theme) =>
                                    `2px ${theme.palette.background.grey100} solid`,
                            }}
                        >
                            <ListItemText secondary={t("SYSTEM_SETTING")} />
                        </ListItemButton>
                    </MenuLink>
                )}

                <ListItemButton
                    key="Mode"
                    sx={{
                        marginLeft: "-10px",
                    }}
                >
                    <Switch
                        checked={mode === "dark"}
                        onChange={handleModeChange}
                    />
                    <ListItemText secondary={t("DARK_MODE")} />
                </ListItemButton>
            </List>
            {user && (
                <span>
                    <Divider />
                    <Button
                        startIcon={
                            <LogoutIcon
                                style={{ transform: "rotate(180deg)" }}
                            />
                        }
                        onClick={logout}
                        sx={{}}
                    >
                        {user.lastName &&
                            user.firstName &&
                            `${user.firstName} ${user.lastName}`}
                    </Button>
                </span>
            )}
        </ListDiv>
    );
}

Menu.propTypes = {
    user: PropTypes.object,
    logout: PropTypes.func.isRequired,
};

Menu.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            logout,
        },
        dispatch,
    );

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
