import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {getErrorMessage} from '../../../utils';
import ToolBar from './ToolBar';
import GroupList from "./GroupList";
import {createGroup, fetchAllGroups, updateGroup} from "../../../redux/actions/group";
import Group from '../../../components/modal/GroupUpdate';
import Paper from "@mui/material/Paper";
import dayjs from "dayjs";
import {Skeleton} from "@mui/material";


function GroupAdministration(props) {
    const {
        userUseCases,
        groups,
        createGroup,
        updateGroup,
        fetchAllGroups,
        isFetchingAllList,
        user,
    } = props;


    const {t} = useTranslation();

    const [isFetchedGroup, setIsFetchedGroup] = useState(false);

    const [saving, setSaving] = useState(false);
    const [groupConfigModel, setGroupConfigModel] = useState({
        open: false,
        group: {},
        isNew: true
    });


    useEffect(() => {
        if (!isFetchedGroup) {
            fetchAllGroups().then(() => {
                setIsFetchedGroup(true)
            }).catch((err) => {
                console.log(err);
                setIsFetchedGroup(false);
            });
        }


    }, [fetchAllGroups, isFetchedGroup]);


    const [selectedName, setSelectedName] = useState('');


    const handleSelectedName = (value) => {
        setSelectedName(value.target.value);
    };


    useEffect(() => {
        const filGroup = [];
        if (groups) {
            for (let i = 0; i < groups.length; i++) {
                let matchCriteria = true;
                if (selectedName && selectedName.trim().length > 0) {
                    if (
                        (!groups[i].name || !groups[i].name.toLowerCase().includes(selectedName.toLowerCase()))
                    ) {
                        matchCriteria = false;
                    }
                }


                if (matchCriteria) {
                    filGroup.push(groups[i]);
                }
            }
        }
        setFilteredGroups(filGroup);
    }, [groups, selectedName]);


    const [filteredGroups, setFilteredGroups] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [error, setError] = useState('');

    const [locationsValues, setLocationsValues] = useState();
    const [groupsValues, setGroupsValues] = useState();

    const [codeList, setCodeList] = useState();
    const [confirmModalOpened, setConfirmModalOpened] = useState(false);


    const closeGroupDetail = () => {
        setError('');
        setSnackbarOpen(false);
        setGroupConfigModel({isNew: false, group: {}, open: false, readOnly: false});
    }


    const handleNewGroup = () => {
        setError('');
        setSnackbarOpen(false);
        setGroupConfigModel({isNew: true, group: {}, open: true, readOnly: false});
    }
    const handleGroupUpdate = (updatedGroup, isNew, readOnly) => {
        // parking ID of user
        let group = {...updatedGroup}
        setError('');
        setSnackbarOpen(false);
        setGroupConfigModel({isNew, group: group, open: true, readOnly: readOnly});

    };


    const saveGroup = async (group) => {
        setSaving(true);
        group = {...group}
        if (group.lightColor && group.lightColor.hex) {
            group.lightColor = group.lightColor.hex;
        }
        if (group.darkColor && group.darkColor.hex) {
            group.darkColor = group.darkColor.hex;
        }

        const action = (group && group.groupId) ? updateGroup : createGroup;
        try {
            await action(group);
            setIsFetchedGroup(false);
            setSaving(false);
            closeGroupDetail();
        } catch (error) {
            setSaving(false);
            setError(getErrorMessage(error, t));
            setSnackbarOpen(true);
        }

    };

    return (
        <>

            {!isFetchingAllList && isFetchedGroup ? <ToolBar
                userUseCases={userUseCases}
                handleGroupUpdate={handleGroupUpdate}
                selectedName={selectedName}
                handleSelectedName={handleSelectedName}
                user={user}
                handleNewGroup={handleNewGroup}
            /> : <Skeleton height={200} sx={{
                width: '86%',
                marginLeft: '30px',
            }} animation="wave"/>}

            {!isFetchingAllList && isFetchedGroup ? <Paper
                sx={{
                    width: '86%',
                    marginLeft: '30px',
                    marginTop: '30px',
                    overflow: 'hidden',
                    backgroundColor: (theme) => theme.palette.background.grey,
                }}

            >
                <GroupList
                    refresh={() => {
                        setIsFetchedGroup(false);
                    }}
                    save={saveGroup}
                    userUseCases={userUseCases}
                    groups={filteredGroups}
                    handleGroupDetail={handleGroupUpdate}
                    isLoading={isFetchingAllList || !isFetchedGroup}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarError={error}
                    confirmModalOpened={confirmModalOpened}
                    setConfirmModalOpened={setConfirmModalOpened}
                />


                <Group
                    groupConfigModel={groupConfigModel}
                    userUseCases={userUseCases}
                    saveGroup={saveGroup}
                    error={error}
                    snackbarOpen={snackbarOpen}
                    handleClose={closeGroupDetail}
                    setSnackbarOpen={setSnackbarOpen}
                    setSnackbarError={error}
                    saving={saving}
                    loggedUser={user}
                    groups={groupsValues}
                    locations={locationsValues}
                />


            </Paper> : <Skeleton variant="rounded" height="calc(100vh - 200px)" sx={{
                width: '86%',
                marginLeft: '30px',

            }} animation="wave"/>
            }

        </>
    );
}

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    groups: store.groupData.groups,
    isFetchingAllList: store.groupData.isFetchingAllList,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAllGroups,
    createGroup,
    updateGroup,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(GroupAdministration);
