import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { getErrorMessage, isComponentEnabledForUser } from "../../../utils";
import ToolBar from "./ToolBar";
import AssetList from "./AssetList";
import {
  createAsset,
  fetchAllAssets,
  updateAsset,
} from "../../../redux/actions/asset";
import { fetchCodeLists } from "../../../redux/actions/codelists";
import Asset from "../../../components/modal/AssetUpdate";
import Paper from "@mui/material/Paper";
import { fetchLocations } from "../../../redux/actions/location";
import dayjs from "dayjs";
import AssetRelease from "../../../components/modal/AssetRelease";
import { fetchAllGroups } from "../../../redux/actions/group";
import { Skeleton } from "@mui/material";

function AssetAdministration(props) {
  const {
    userUseCases,
    locations,
    groups,
    assets,
    createAsset,
    updateAsset,
    fetchAllAssets,
    fetchLocations,
    fetchAllGroups,
    isFetchingAllList,
    user,
    type,
  } = props;

  const { t } = useTranslation();

  const [isFetchedAsset, setIsFetchedAsset] = useState(false);
  const [isFetchingAllGroups, setIsFetchingAllGroups] = useState(false);
  const [isFetchingAllLocations, setIsFetchingAlLLocations] = useState(false);

  const [saving, setSaving] = useState(false);
  const [headerChecked, setHeaderChecked] = useState(0);
  const [assetConfigModel, setAssetConfigModel] = useState({
    open: false,
    asset: {},
    isNew: true,
  });

  const [assetReleaseConfigModel, setAssetReleaseConfigModel] = useState({
    open: false,
    asset: {},
    isNew: true,
  });

  useEffect(() => {
    if (!isFetchedAsset) {
      if (isComponentEnabledForUser("UC0009", userUseCases)) {
        fetchAllAssets(user.tenantId)
          .then(() => {
            setIsFetchedAsset(true);
          })
          .catch((err) => {
            console.log(err);
            setIsFetchedAsset(false);
          });
      } else {
        fetchAllAssets()
          .then(() => {
            setIsFetchedAsset(true);
          })
          .catch((err) => {
            console.log(err);
            setIsFetchedAsset(false);
          });
      }
    }
  }, [fetchAllAssets, isFetchedAsset]);

  useEffect(() => {
    if (!isFetchingAllLocations) {
      fetchLocations()
        .then(() => {
          setIsFetchingAlLLocations(true);
        })
        .catch((err) => {
          console.log(err);
          setIsFetchingAlLLocations(false);
        });
    }
  }, [fetchLocations, isFetchingAllLocations]);

  useEffect(() => {
    if (!isFetchingAllGroups) {
      fetchAllGroups()
        .then(() => {
          setIsFetchingAllGroups(true);
        })
        .catch((err) => {
          console.log(err);
          setIsFetchingAllGroups(false);
        });
    }
  }, [fetchAllGroups, isFetchingAllGroups]);

  const [selectedName, setSelectedName] = useState("");
  const [selectedUCName, setSelectedUCName] = useState("");

  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedAdminRole, setSelectedAdminRole] = useState();

  const [selectedLocation, setSelectedLocation] = useState();

  const [selectedGroup, setSelectedGroup] = useState();

  const [table, setTable] = useState(false);

  const handleSelectedName = (value) => {
    setSelectedName(value.target.value);
  };
  const handleSelectedUCName = (value) => {
    setSelectedUCName(value.target.value);
  };
  const handleSelectedStatus = (value) => {
    setSelectedStatus(value);
  };
  const handleSelectedAdminRole = (value) => {
    setSelectedAdminRole(value);
  };
  const handleSelectedLocation = (value) => {
    setSelectedLocation(value);
  };

  const handleSelectedGroup = (value) => {
    setSelectedGroup(value);
  };

  const matchStatus = (user) => {
    let res = false;
    for (let i = 0; i < selectedStatus.length; i++) {
      res =
        (selectedStatus[i].key === "ACTIVE" && user.active === true) ||
        (selectedStatus[i].key === "INACTIVE" && user.active === false);
      if (res) {
        return true;
      }
    }
    return false;
  };

  const matchLocation = (asset) => {
    for (let i = 0; i < selectedLocation.length; i++) {
      if (asset.locationId == selectedLocation[i].key) {
        return true;
      }
    }
    return false;
  };

  const matchGroup = (asset) => {
    for (let i = 0; i < selectedGroup.length; i++) {
      for (let j = 0; j < asset.groups.length; j++) {
        if (asset.groups[j].groupId == selectedGroup[i].key) return true;
      }
    }
  };

  const currentDateTime = dayjs();
  const initGroups = (asset) => {
    if (asset.groups) {
      for (let j = 0; j < asset.groups.length; j++) {
        const a = asset.groups[j];
        const dateFromD = a.dateFrom ? dayjs(a.dateFrom) : null;
        const dateToD = a.dateTo ? dayjs(a.dateTo) : null;
        a.dateFromD = dateFromD;
        a.dateToD = dateToD;
        a.active =
          (dateFromD == null || dateFromD.isBefore(currentDateTime)) &&
          (dateToD == null || dateToD.isAfter(currentDateTime));
        a.future = dateFromD ? dateFromD.isAfter(currentDateTime) : null;
      }
      asset.groups.sort((a, b) =>
        a.active == b.active && a.dateFromD != null && b.dateFromD != null
          ? a.dateFromD.isBefore(b.dateFromD)
            ? 1
            : b.dateFromD.isBefore(a.dateFromD)
            ? -1
            : 0
          : a.active
          ? -1
          : 1
      );
    }
  };

  useEffect(() => {
    const filAsset = [];
    if (assets) {
      for (let i = 0; i < assets.length; i++) {
        initGroups(assets[i]);

        let matchCriteria = true;
        if (assets[i].type !== type) {
          matchCriteria = false;
        }

        if (selectedName && selectedName.trim().length > 0) {
          if (
            !assets[i].name ||
            !assets[i].name.toLowerCase().includes(selectedName.toLowerCase())
          ) {
            matchCriteria = false;
          }
        }

        if (selectedStatus && selectedStatus.length > 0) {
          const res = matchStatus(assets[i]);
          if (!res) {
            matchCriteria = false;
          }
        }

        if (selectedLocation && selectedLocation.length > 0) {
          const res = matchLocation(assets[i]);
          if (!res) {
            matchCriteria = false;
          }
        }

        if (selectedGroup && selectedGroup.length > 0) {
          const res = matchGroup(assets[i]);
          if (!res) {
            matchCriteria = false;
          }
        }

        if (matchCriteria) {
          filAsset.push(assets[i]);
        }
      }
    }
    setFilteredAssets(filAsset);
  }, [
    assets,
    selectedName,
    selectedGroup,
    selectedStatus,
    selectedAdminRole,
    selectedLocation,
    type,
  ]);

  const [filteredAssets, setFilteredAssets] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState("");

  const [locationsValues, setLocationsValues] = useState();
  const [groupsValues, setGroupsValues] = useState();

  const [codeList, setCodeList] = useState();
  const [confirmModalOpened, setConfirmModalOpened] = useState(false);

  useEffect(() => {
    if (locations) {
      setLocationsValues(
        locations.map((a) => {
          return { value: a.locationId, label: a.name, key: a.locationId };
        })
      );
    }
  }, [locations]);

  useEffect(() => {
    if (groups) {
      setGroupsValues(
        groups.map((a) => {
          return {
            value: a.groupId,
            label: a.name,
            key: a.groupId,
            lightColor: a.lightColor,
            darkColor: a.darkColor,
          };
        })
      );
    }
  }, [groups]);

  const [subjectValues, setSubjectValues] = useState();

  const closeAssetDetail = () => {
    setError("");
    setSnackbarOpen(false);
    setAssetConfigModel({
      isNew: false,
      asset: {},
      open: false,
      readOnly: false,
    });
  };

  const closeAssetReleaseDetail = () => {
    setError("");
    setSnackbarOpen(false);
    setAssetReleaseConfigModel({ asset: {}, open: false, readOnly: false });
  };

  const handleNewAsset = () => {
    setError("");
    setSnackbarOpen(false);
    setAssetConfigModel({
      isNew: true,
      asset: {},
      open: true,
      readOnly: false,
    });
  };
  const handleAssetUpdate = (updatedAsset, isNew, readOnly) => {
    // parking ID of user
    let asset = { ...updatedAsset };
    if (asset.groups != null) {
      asset.groups = asset.groups.map((a) => {
        return {
          value: a.groupId,
          label: a.groupName,
          key: a.groupId,
          lightColor: a.lightColor,
          darkColor: a.darkColor,
          dateFromD: a.dateFromD,
          dateToD: a.dateToD,
          dateFrom: a.dateFrom,
          dateTo: a.dateTo,
          active: a.active,
          future: a.future,
          assetGroupId: a.assetGroupId,
        };
      });
    }
    if (asset.locationId != null) {
      asset.location = {
        value: asset.locationId,
        label: asset.locationName,
        key: asset.locationId,
      };
    }

    setError("");
    setSnackbarOpen(false);
    setAssetConfigModel({
      isNew,
      asset: asset,
      open: true,
      readOnly: readOnly,
    });
  };

  const handleAssetRelease = (updatedAsset, isNew, readOnly) => {
    // parking ID of user
    let asset = { ...updatedAsset };
    if (asset.groups != null) {
      asset.groups = asset.groups.map((a) => {
        return {
          value: a.groupId,
          label: a.groupName,
          key: a.groupId,
          lightColor: a.lightColor,
          darkColor: a.darkColor,
          dateFromD: a.dateFromD,
          dateToD: a.dateToD,
          dateFrom: a.dateFrom,
          dateTo: a.dateTo,
          active: a.active,
          future: a.future,
          groupId: a.groupId,
          assetGroupId: a.assetGroupId,
          assetId: asset.assetId,
        };
      });
    }
    if (asset.locationId != null) {
      asset.location = {
        value: asset.locationId,
        label: asset.locationName,
        key: asset.locationId,
      };
    }

    setError("");
    setSnackbarOpen(false);
    setAssetReleaseConfigModel({
      isNew,
      asset: asset,
      open: true,
      readOnly: readOnly,
    });
  };

  const handleActivate = async (asset) => {
    try {
      await updateAsset({ ...asset, active: !asset.active });
      setConfirmModalOpened(false);
      setIsFetchedAsset(false);
    } catch (err) {
      console.log(err);
      setIsFetchedAsset(false);
    }
  };

  const saveAsset = async (asset) => {
    setSaving(true);
    asset = { ...asset };
    if (asset.groups != null) {
      asset.groups = asset.groups.map((a) => {
        return {
          groupId: a.value,
          name: a.label,
          lightColor: a.lightColor,
          darkColor: a.darkColor,
          dateFrom: a.dateFrom,
          dateTo: a.dateTo,
          assetGroupId: a.assetGroupId,
        };
      });
    }
    if (asset.location != null) {
      asset.locationId = asset.location.key;
    }
    if (asset.tenant) {
      asset.tenantId = asset.tenant.tenantId;
    }
    const action = asset && asset.assetId ? updateAsset : createAsset;
    try {
      await action(asset);
      setSaving(false);
      closeAssetDetail();
      closeAssetReleaseDetail();
      setIsFetchedAsset(false);
    } catch (error) {
      setSaving(false);
      setError(getErrorMessage(error, t));
      setSnackbarOpen(true);
      closeAssetReleaseDetail();
      setIsFetchedAsset(false);
    }
  };

  return (
    <>
      {!isFetchingAllList && isFetchedAsset ? (
        <ToolBar
          userUseCases={userUseCases}
          handleAssetUpdate={handleAssetUpdate}
          selectedName={selectedName}
          handleSelectedName={handleSelectedName}
          selectedStatus={selectedStatus}
          handleSelectedStatus={handleSelectedStatus}
          selectedAdminRole={selectedAdminRole}
          handleSelectedAdminRole={handleSelectedAdminRole}
          selectedLocation={selectedLocation}
          selectedGroup={selectedGroup}
          handleSelectedLocation={handleSelectedLocation}
          handleSelectedGroup={handleSelectedGroup}
          groups={groupsValues}
          locations={locationsValues}
          subjects={subjectValues}
          user={user}
          handleNewAsset={handleNewAsset}
          type={type}
        />
      ) : (
        <Skeleton
          height={200}
          sx={{
            width: "86%",
            marginLeft: "30px",
          }}
          animation="wave"
        />
      )}

      {!isFetchingAllList && isFetchedAsset ? (
        <Paper
          sx={{
            width: "86%",
            marginLeft: "30px",
            marginTop: "30px",
            overflow: "hidden",
            backgroundColor: (theme) => theme.palette.background.grey,
          }}
        >
          <AssetList
            refresh={() => {
              setIsFetchedAsset(false);
            }}
            table={table}
            save={saveAsset}
            userUseCases={userUseCases}
            assets={filteredAssets}
            handleAssetDetail={handleAssetUpdate}
            isLoading={isFetchingAllList || !isFetchedAsset}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarError={error}
            handleActivate={handleActivate}
            confirmModalOpened={confirmModalOpened}
            setConfirmModalOpened={setConfirmModalOpened}
            handleAssetRelease={handleAssetRelease}
            type={type}
            user={user}
          />

          <Asset
            assetConfigModel={assetConfigModel}
            userUseCases={userUseCases}
            saveAsset={saveAsset}
            error={error}
            snackbarOpen={snackbarOpen}
            handleClose={closeAssetDetail}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarError={error}
            saving={saving}
            user={user}
            groups={groupsValues}
            locations={locationsValues}
            type={type}
          />

          <AssetRelease
            assetConfigModel={assetReleaseConfigModel}
            userUseCases={userUseCases}
            saveAsset={saveAsset}
            error={error}
            snackbarOpen={snackbarOpen}
            handleClose={closeAssetReleaseDetail}
            setSnackbarOpen={setSnackbarOpen}
            setSnackbarError={error}
            saving={saving}
            loggedUser={user}
            groups={groupsValues}
          />
        </Paper>
      ) : (
        <Skeleton
          variant="rounded"
          height="calc(100vh - 200px)"
          sx={{
            width: "86%",
            marginLeft: "30px",
          }}
          animation="wave"
        />
      )}
    </>
  );
}

const mapStateToProps = (store) => ({
  user: store.authData.user,
  userUseCases: store.authData.userUseCases,
  locations: store.locationData.locations,
  assets: store.assetData.assets,
  isFetchingAllList: store.assetData.isFetchingAllList,
  allCodeList: store.codelistData.allCodeList,
  groups: store.groupData.groups,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchAllAssets,
      createAsset,
      updateAsset,
      fetchCodeLists,
      fetchLocations,
      fetchAllGroups,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssetAdministration);
