import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/system";
import {Checkbox, Chip, FormControlLabel, Typography} from "@mui/material";
import CustomTable from "../../common/CustomTable";
import Autocomplete from '@mui/material/Autocomplete';
import {dayjsToDateTimeString} from "../../../utils";
import CustomDateTimePicker from "../../common/CustomDateTimePicker";
import GroupAutocomplete from "../../common/GroupAutocomplete";
import {ASSET_TYPE_CAR, ASSET_TYPE_PLACE, DATE_TIME_FORMAT} from "../../../utils/constants";
import dayjs from "dayjs";
import {useDropzone} from "react-dropzone";
import LocationAutocomplete from "../../common/LocationAutocomplete";
import TenantAutocomplete from '../../common/TenantAutocomplete';

const FormFields = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    flexDirection: 'row',

    alignItems: 'flex-start',
    paddingTop: '50px',

}));

const EditorWrapper = styled('div')(({theme}) => ({
    height: '100%',
    width: '100%',
    padding: theme.spacing(3.25, 4, 4, 4),

}));


const ErrorMessage = styled('div')(({theme}) => ({
    fontSize: 13,

}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false,
    rowStyle: (rowdata) => {
        return {
            backgroundColor: rowdata.active ? "#fff" : 'rgba(252,234,234,0.37)',
        };
    },

};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginRight: 4,
    width: 90,
    height: 100,
    padding: 4,
    boxSizing: 'border-box'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%'
};


function DropZone({files, setFiles}) {

    const {t} = useTranslation();

    const {getRootProps, getInputProps} = useDropzone({
        maxFiles: 1,
        accept: {
            'image/*': []
        },
        onDrop: acceptedFiles => {
            const reader = new FileReader();
            reader.onload = (event) => {
                setFiles([{
                    preview: URL.createObjectURL(acceptedFiles[0]),
                    data: event.target.result.split(',')[1],
                    path: acceptedFiles[0].path
                }]);
            };
            reader.readAsDataURL(acceptedFiles[0]);

        }
    });

    const thumbs = files.map(file => (
        <div style={thumb} key={file.name}>
            <div style={thumbInner}>
                <img
                    src={file.path && file.preview ? file.preview : `data:image/png;base64,${file.preview}`}
                    style={img}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                        URL.revokeObjectURL(file.preview)
                    }}
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    return (
        <section style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div {...getRootProps({
                    style: {
                        width: '150px',
                        border: '1px dashed #eaeaea',
                        height: '100px',
                        padding: '4px',
                    }
                }
            )}>
                <input {...getInputProps()} />
                <p style={{margin: 0, color: '#eaeaea'}}>{t('DROP_ZONE_TEXT')}</p>
            </div>
            <aside style={thumbsContainer}>
                {thumbs}
            </aside>
        </section>
    );
}

function renderGroupName(rowData) {
    return <Chip
        style={{
            backgroundColor: rowData.lightColor,
            color: 'black'
        }}
        variant="outlined"
        label={rowData.label}
    />;
}

const columns = (t, locale, groups) => [
    {
        title: t('GROUP'),
        field: 'group',
        headerStyle: {textAlign: 'left', paddingLeft: 5},
        cellStyle: {
            ...cellStyle,
            align: 'left',
        },
        render: (rowData) => (
            renderGroupName(rowData)
        ),
        editComponent: props => {
            const value = props.rowData.key ? {
                key: props.rowData.key,
                value: props.rowData.key,
                label: props.rowData.label
            } : props.value !== undefined ? props.value : null;
            return <GroupAutocomplete multiple={false} selectedGroups={value} setGroups={props.onChange}
                                      groups={groups}/>
        }
    },
    {
        title: t('FROM'),
        field: 'from',
        headerStyle: {textAlign: 'left', paddingLeft: 5},
        cellStyle: {
            ...cellStyle,
            align: 'left',
        },
        render: (rowData) => {
            return rowData.dateFromD ? dayjsToDateTimeString(rowData.dateFromD, locale) : t('N_A')
        },
        initialEditValue: new dayjs(),
        editComponent: props => {
            const value = props.rowData.dateFromD ? props.rowData.dateFromD : null;
            return (<CustomDateTimePicker dateTime={value}
                                          setDateTime={props.onChange}/>)
        }
    },
    {
        title: t('TO'),
        field: 'to',
        headerStyle: {textAlign: 'left', paddingLeft: 5},
        cellStyle: {
            ...cellStyle,
            align: 'left',
        },
        render: (rowData) => {
            if (rowData.dateToD != null) {
                return dayjsToDateTimeString(rowData.dateToD, locale);
            } else {
                return t('N_A')
            }
        },
        editComponent: props => (<CustomDateTimePicker dateTime={props.value}
                                                       setDateTime={props.onChange}
            ></CustomDateTimePicker>
        )
    }

];


function AssetUpdate(props) {

    const {
        userUseCases,
        locations,
        groups,
        handleChange,
        updatedAsset,
        error,
        readOnly,
        isNew,
        handleSelectedLocation,
        setSelectedTenant,
        handleSelectedGroup,
        setUpdatedAsset,
        addGroupToAsset,
        files,
        setFiles,
        type,
        user,
        selectedTenant
    } = props;

    const onFilesAdded = (newFiles) => {
        setFiles(newFiles);
        setUpdatedAsset({...updatedAsset, img: newFiles[0].data});
    };

    const handleGroupToAsset = async (data) => {

        const groupsM = updatedAsset.groups.filter(a => a.assetGroupId != data.assetGroupId);
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                setUpdatedAsset({
                    ...updatedAsset,
                    groups: [...groupsM]
                });
                resolve();
            }, 500);
        });
    }


    const {t, i18n} = useTranslation();
    const [data, setData] = useState();

    return (
        <FormFields>
            <form autoComplete='off'>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={6} >
                        <Typography variant='subtitle2' sx={{fontWeight: 'bold'}}>{t('TENANT')}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} ></Grid>
                    <Grid item md={6} xs={6}>
                        <TenantAutocomplete
                            error={error.tenants}
                            setSelectedTenant={setSelectedTenant}
                            selectedTenant={selectedTenant}
                            multiple={false}
                            disabled={readOnly || !user.admin?true:false}
                            required={true}
                            user={user}
                            tenantId={user.admin ? ( updatedAsset ? updatedAsset.tenantId : 0 ) : ( user ? user.tenantId : 0 )}
                        />
                    </Grid>
                    <Grid item md={6} xs={6} ></Grid>
                    <Grid item md={12} xs={12} ></Grid>
                    <Grid item md={12} xs={12} ></Grid>

                    <Grid item md={6} xs={6} >
                        <Typography variant='subtitle2' sx={{fontWeight: 'bold'}}>{t('CAR_INFORMATION')}</Typography>
                    </Grid>
                    <Grid item md={6} xs={6} ></Grid>
                    <Grid item md={3} xs={6}>
                        <TextField
                            variant="outlined"
                            autoFocus
                            error={error.name}
                            helperText={error.name}
                            fullWidth
                            disabled={readOnly}
                            id="name"
                            value={updatedAsset && updatedAsset.name || ''}
                            onChange={handleChange('name', false)}
                            label={type===ASSET_TYPE_PLACE?t('PLACE_NAME'):t('CAR_NAME')}/>
                    </Grid>


                    <Grid item md={3} xs={6}>
                        <LocationAutocomplete
                            locations={locations}
                            error={error.locations}
                            setLocations={handleSelectedLocation}
                            selectedLocations={updatedAsset && updatedAsset.location || ''}
                            multiple={false}
                            disabled={readOnly}
                            required={true}
                        />
                    </Grid>

                    <Grid item md={3} xs={6}>
                        <FormControlLabel labelPlacement="start" checked={updatedAsset && updatedAsset.active || ''}
                                          defaultChecked
                                          control={<Checkbox disabled={readOnly} onChange={(event) => {
                                              handleChange("active")(event)
                                          }}
                                                             sx={{'& .MuiSvgIcon-root': {fontSize: 28}}}/>}
                                          label={t('ACTIVE')}/>
                    </Grid>

                    <Grid item md={3} xs={6}>
                        <DropZone files={files} setFiles={onFilesAdded}/>
                    </Grid>

                    { type === ASSET_TYPE_CAR && 
                        <>
                            <Grid item md={6} xs={6} >
                                <Typography variant='subtitle2' sx={{fontWeight: 'bold'}}>{t('CAR_SPECIFICATION')}</Typography>
                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant='subtitle2' sx={{fontWeight: 'bold'}}>{t('TIRES')}</Typography>
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.cartype}
                                    helperText={error.cartype}
                                    fullWidth
                                    disabled={readOnly}
                                    id="car.type"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.type ? updatedAsset.car.type : ''}
                                    onChange={handleChange('car.type', false)}
                                    label={t('TYPE')}/>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.tiresSummerCount}
                                    helperText={error.tiresSummerCount}
                                    fullWidth
                                    disabled={readOnly}
                                    id="tiresSummerCount"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.tiresSummerCount ? updatedAsset.car.tiresSummerCount : ''}
                                    onChange={handleChange('car.tiresSummerCount', false)}
                                    label={t('TIRES_SUMMER_COUNT')}
                                    type='number'
                                />
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.model}
                                    helperText={error.model}
                                    fullWidth
                                    disabled={readOnly}
                                    id="model"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.model ? updatedAsset.car.model : ''}
                                    onChange={handleChange('car.model', false)}
                                    label={t('CAR_MODEL')}/>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.tiresSummerType}
                                    helperText={error.tiresSummerType}
                                    fullWidth
                                    disabled={readOnly}
                                    id="tiresSummerType"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.tiresSummerType ? updatedAsset.car.tiresSummerType : ''}
                                    onChange={handleChange('car.tiresSummerType', false)}
                                    label={t('TIRES_SUMMER_TYPE')}/>
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.engineAmount}
                                    helperText={error.engineAmount}
                                    fullWidth
                                    disabled={readOnly}
                                    id="engineAmount"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.engineAmount ? updatedAsset.car.engineAmount : ''}
                                    onChange={handleChange('car.engineAmount', false)}
                                    label={t('ENGINE_AMOUNT')}/>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.tiresWinterCount}
                                    helperText={error.tiresWinterCount}
                                    fullWidth
                                    disabled={readOnly}
                                    id="tiresWinterCount"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.tiresWinterCount ? updatedAsset.car.tiresWinterCount : ''}
                                    onChange={handleChange('car.tiresWinterCount', false)}
                                    label={t('TIRES_WINTER_COUNT')}
                                    type='number'
                                />
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.enginePower}
                                    helperText={error.enginePower}
                                    fullWidth
                                    disabled={readOnly}
                                    id="enginePower"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.enginePower ? updatedAsset.car.enginePower : ''}
                                    onChange={handleChange('car.enginePower', false)}
                                    label={t('ENGINE_POWER')}/>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.tiresWinterType}
                                    helperText={error.tiresWinterType}
                                    fullWidth
                                    disabled={readOnly}
                                    id="tiresWinterType"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.tiresWinterType ? updatedAsset.car.tiresWinterType : ''}
                                    onChange={handleChange('car.tiresWinterType', false)}
                                    label={t('TIRES_WINTER_TYPE')}/>
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.carType}
                                    helperText={error.carType}
                                    fullWidth
                                    disabled={readOnly}
                                    id="carType"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.carType ? updatedAsset.car.carType : ''}
                                    onChange={handleChange('car.carType', false)}
                                    label={t('CAR_TYPE')}/>
                            </Grid>
                            <Grid item md={6} xs={6} >
                                <Typography variant='subtitle2' sx={{fontWeight: 'bold'}}>Emise</Typography>
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.gerBoxType}
                                    helperText={error.gerBoxType}
                                    fullWidth
                                    disabled={readOnly}
                                    id="gerBoxType"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.gerBoxType ? updatedAsset.car.gerBoxType : ''}
                                    onChange={handleChange('car.gerBoxType', false)}
                                    label={t('GER_BOX_TYPE')}/>
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.emissionStandard}
                                    helperText={error.emissionStandard}
                                    fullWidth
                                    disabled={readOnly}
                                    id="emissionStandard"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.emissionStandard ? updatedAsset.car.emissionStandard : ''}
                                    onChange={handleChange('car.emissionStandard', false)}
                                    label={t('EMISSOIN_STANDARD')}/>
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.seatCount}
                                    helperText={error.seatCount}
                                    fullWidth
                                    disabled={readOnly}
                                    id="seatCount"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.seatCount ? updatedAsset.car.seatCount : ''}
                                    onChange={handleChange('car.seatCount', false)}
                                    label={t('SEAT_COUNT')}
                                    type='number'
                                />
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.emissionCO2}
                                    helperText={error.emissionCO2}
                                    fullWidth
                                    disabled={readOnly}
                                    id="emissionCO2"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.emissionCO2 ? updatedAsset.car.emissionCO2 : ''}
                                    onChange={handleChange('car.emissionCO2', false)}
                                    label={t('EMISSION_CO2')}
                                    type='number'
                                />
                            </Grid>

                            <Grid item md={6} xs={6}>
                                <TextField
                                    variant="outlined"
                                    autoFocus
                                    error={error.fuelType}
                                    helperText={error.fuelType}
                                    fullWidth
                                    disabled={readOnly}
                                    id="fuelType"
                                    value={updatedAsset && updatedAsset.car && updatedAsset.car.fuelType ? updatedAsset.car.fuelType : ''}
                                    onChange={handleChange('car.fuelType', false)}
                                    label={t('FUEL_TYPE')}/>
                            </Grid>
                        </>
                    }

                    {updatedAsset && updatedAsset.assetId && <Grid item xs={12}>
                        <Typography align="left" variant='h6'
                                    style={{paddingRight: 20}}>{t('ASSIGNED_TO_GROUPS')}</Typography>
                    </Grid>
                    }
                    <Grid item xs={12}>
                        {updatedAsset && updatedAsset.assetId && <CustomTable
                            title=""
                            columns={columns(t, i18n.language, groups)}
                            data={updatedAsset.groups}
                            options={tableOptions}
                            loadAllData={false}
                        />}
                    </Grid>

                </Grid>
            </form>
        </FormFields>
    )
        ;
}

AssetUpdate.propTypes = {};
AssetUpdate.defaultProps = {};


export default AssetUpdate;


