import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import {
    Autocomplete,
    Checkbox,
    Chip,
    FormControlLabel,
    IconButton,
} from "@mui/material";
import ToolBarCommon from "../../../components/common/ToolBarCommon";
import GroupAutocomplete from "../../../components/common/GroupAutocomplete";
import {
    isComponentEnabledForUser,
    isComponentVisibleForUser,
} from "../../../utils";
import { UC0162 } from "../../../utils/constants";
import { Add } from "@mui/icons-material";
import { fontSize } from "@mui/system";

function ToolBar(props) {
    const {
        userUseCases,
        handleNewUser,
        selectedName,
        handleSelectedName,
        selectedStatus,
        handleSelectedStatus,
        selectedAdminRole,
        handleSelectedAdminRole,
        selectedRole,
        handleSelectedRole,
        handleSelectedGroup,
        roles,
        groups,
        selectedGroup,
        user,
        selectedLicensePlate,
        setSelectedLicensePlate,
    } = props;

    const { t } = useTranslation();

    const statuses = [
        {
            value: "ACTIVE",
            label: t("ACTIVE"),
            key: "ACTIVE",
        },
        {
            value: "INACTIVE",
            label: t("INACTIVE"),
            key: "INACTIVE",
        },
    ];

    const [shown, setShown] = React.useState(true);
    const toggleDrawer = (open) => (event) => {
        setShown(!shown);
    };

    const statusesObjs = {
        active: [
            {
                value: "ACTIVE",
                label: "Active",
                key: "ACTIVE",
            },
        ],
        inactive: [
            {
                value: "INACTIVE",
                label: "Inactive",
                key: "INACTIVE",
            },
        ],
        both: [
            {
                value: "ACTIVE",
                label: "Active",
                key: "ACTIVE",
            },
            {
                value: "INACTIVE",
                label: "Inactive",
                key: "INACTIVE",
            },
        ],
        none: [],
    };

    const activeStatus = [
        {
            value: "ACTIVE",
            label: "Active",
            key: "ACTIVE",
        },
    ];
    const inactiveStatus = [
        {
            value: "INACTIVE",
            label: "Inactive",
            key: "INACTIVE",
        },
    ];

    const both = [
        {
            value: "ACTIVE",
            label: "Active",
            key: "ACTIVE",
        },
        {
            value: "INACTIVE",
            label: "Inactive",
            key: "INACTIVE",
        },
    ];

    const none = [];

    return (
        <ToolBarCommon shown={shown} setShown={setShown} title={t("USER_LIST")}>
            {!shown && (
                <Grid
                    item
                    xs={11}
                    sx={{
                        display: shown ? "flex" : { xs: "flex", lg: "none" },
                    }}
                ></Grid>
            )}

            <Grid
                item
                xs={6}
                sm={1.5}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{
                                color: (theme) => theme.palette.action.disabled,
                            }}
                        />
                    }
                    value={selectedStatus.includes({
                        value: "ACTIVE",
                        label: "Active",
                        key: "ACTIVE",
                    })}
                    onChange={() => {
                        if (selectedStatus.length === 0) {
                            handleSelectedStatus(statusesObjs.active);
                        } else if (
                            selectedStatus.length === 1 &&
                            selectedStatus[0].value === "ACTIVE"
                        ) {
                            handleSelectedStatus(statusesObjs.none);
                        } else if (selectedStatus.length === 1) {
                            handleSelectedStatus(statusesObjs.both);
                        } else {
                            handleSelectedStatus(statusesObjs.inactive);
                        }
                    }}
                    label={t("ACTIVE")}
                    sx={{
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.action.disabled,
                        borderRadius: "15px",
                        fontSize: "12px",
                        color: (theme) => theme.palette.text.secondary,
                        padding: "3px",
                        width: "100%",
                        margin: 0,
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
            </Grid>
            <Grid
                item
                xs={6}
                sm={1.5}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            sx={{
                                color: (theme) => theme.palette.action.disabled,
                            }}
                        />
                    }
                    value={selectedStatus.includes({
                        value: "INACTIVE",
                        label: "Inactive",
                        key: "INACTIVE",
                    })}
                    onChange={() => {
                        if (selectedStatus.length === 0) {
                            handleSelectedStatus(statusesObjs.inactive);
                        } else if (
                            selectedStatus.length === 1 &&
                            selectedStatus[0].value === "INACTIVE"
                        ) {
                            handleSelectedStatus(statusesObjs.none);
                        } else if (selectedStatus.length === 1) {
                            handleSelectedStatus(statusesObjs.both);
                        } else {
                            handleSelectedStatus(statusesObjs.active);
                        }
                    }}
                    label={t("INACTIVE")}
                    sx={{
                        border: "1px solid",
                        borderColor: (theme) => theme.palette.action.disabled,
                        borderRadius: "15px",
                        fontSize: "12px",
                        color: (theme) => theme.palette.text.secondary,
                        padding: "3px",
                        width: "100%",
                        margin: 0,
                        "& .MuiFormControlLabel-label": {
                            fontSize: "12px",
                        },
                    }}
                />
            </Grid>
            <Grid
                item
                xs={5}
                sm={2}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <TextField
                    variant="outlined"
                    type="search"
                    label={t("NAME")}
                    fullWidth
                    value={selectedName || ""}
                    onChange={handleSelectedName}
                />
            </Grid>
            <Grid
                item
                sm={2}
                xs={6}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <TextField
                    variant="outlined"
                    type="search"
                    label={t("LICENSE_PLATE")}
                    fullWidth
                    value={selectedLicensePlate || ""}
                    onChange={(event) =>
                        setSelectedLicensePlate(event.target.value)
                    }
                />
            </Grid>
            <Grid
                xs={6}
                item
                sm={2}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <Autocomplete
                    multiple
                    value={selectedRole}
                    onChange={(event, newValue) => {
                        handleSelectedRole(newValue);
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    filterSelectedOptions
                    id="role"
                    style={{ width: "100%" }}
                    options={roles || []}
                    getOptionLabel={(option) => {
                        return option.label;
                    }}
                    renderInput={(params) => (
                        <TextField
                            variant="outlined"
                            {...params}
                            label={t("ROLE")}
                            fullWidth
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip
                                    style={{
                                        backgroundColor: option.color,
                                        color: "black",
                                    }}
                                    variant="outlined"
                                    label={option.label}
                                    {...getTagProps({ index })}
                                />
                            );
                        })
                    }
                />
            </Grid>

            <Grid
                xs={12}
                item
                sm={2}
                sx={{ display: shown ? "flex" : { xs: "none", lg: "flex" } }}
            >
                <GroupAutocomplete
                    groups={groups}
                    setGroups={handleSelectedGroup}
                    selectedGroups={selectedGroup}
                ></GroupAutocomplete>
            </Grid>

            {/* <Grid xs={12} item sm={1} sx={{display: shown ? 'flex' : {'xs': 'none', 'lg': 'flex'}}}> */}
            <Grid
                item
                md={0.5}
                xs={shown ? 2 : 11}
                sx={{
                    display: "flex",
                    paddingTop: "25px !important",
                    paddingLeft: "50px !important",
                    justifyContent: "flex-end",
                }}
            >
                {isComponentVisibleForUser(UC0162, userUseCases) && (
                    <div>
                        <IconButton
                            edge="start"
                            aria-label="add card"
                            onClick={handleNewUser}
                            size="large"
                            disabled={
                                !isComponentEnabledForUser(UC0162, userUseCases)
                            }
                        >
                            <Add />
                        </IconButton>
                    </div>
                )}
            </Grid>
        </ToolBarCommon>
    );
}

ToolBar.propTypes = {};

ToolBar.defaultProps = {};

export default ToolBar;
