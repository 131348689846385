import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import DirectionsCarFilledRoundedIcon from "@mui/icons-material/DirectionsCarFilledRounded";
import PlaceRoundedIcon from "@mui/icons-material/PlaceRounded";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import { BorderBottom } from "@mui/icons-material";
import { themeOptionsDark } from "../../../theme";
import Dashes from "./Dashes";

const Tracker = ({ step }) => {
  const primColor = themeOptionsDark.palette.primary.main;
  const secColor = themeOptionsDark.palette.secondary.main;
  return (
    <span
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 50,
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "20px",
        }}
      >
        <CalendarMonthRoundedIcon
          style={
            step >= 0
              ? step === 0
                ? {
                    color: secColor,
                    transition: "ease-in-out 0.3s",
                  }
                : {
                    color: primColor,
                    transition: "ease-in-out 0.3s",
                  }
              : {
                  color: "rgba(0, 0, 0, 0.12)",
                  transition: "ease-in-out 0.3s",
                }
          }
        />
        <Dashes
          color={
            step >= 1
              ? step === 1
                ? secColor
                : primColor
              : "rgba(0, 0, 0, 0.12)"
          }
        />
        <PlaceRoundedIcon
          style={
            step >= 1
              ? step === 1
                ? {
                    color: secColor,
                    transition: "ease-in-out 0.3s",
                  }
                : {
                    color: primColor,
                    transition: "ease-in-out 0.3s",
                  }
              : {
                  color: "rgba(0, 0, 0, 0.12)",
                  transition: "ease-in-out 0.3s",
                }
          }
        />
        <Dashes
          color={
            step >= 2
              ? step === 2
                ? secColor
                : primColor
              : "rgba(0, 0, 0, 0.12)"
          }
        />
        <DirectionsCarFilledRoundedIcon
          style={
            step >= 2
              ? step === 2
                ? {
                    color: secColor,
                    transition: "ease-in-out 0.3s",
                  }
                : {
                    color: primColor,
                    transition: "ease-in-out 0.3s",
                  }
              : {
                  color: "rgba(0, 0, 0, 0.12)",
                  transition: "ease-in-out 0.3s",
                }
          }
        />
        <Dashes
          color={
            step >= 3
              ? step === 3
                ? secColor
                : primColor
              : "rgba(0, 0, 0, 0.12)"
          }
        />
        <NotificationsActiveRoundedIcon
          style={
            step >= 3
              ? step === 3
                ? {
                    color: secColor,
                    transition: "ease-in-out 0.3s",
                  }
                : {
                    color: primColor,
                    transition: "ease-in-out 0.3s",
                  }
              : {
                  color: "rgba(0, 0, 0, 0.12)",
                  transition: "ease-in-out 0.3s",
                }
          }
        />
        <Dashes color={step >= 4 ? secColor : "rgba(0, 0, 0, 0.12)"} />
        <CheckCircleRoundedIcon
          style={
            step >= 4
              ? {
                  color: secColor,
                  transition: "ease-in-out 0.3s",
                }
              : {
                  color: "rgba(0, 0, 0, 0.12)",
                  transition: "ease-in-out 0.3s",
                }
          }
        />
      </div>
    </span>
  );
};

export default Tracker;
