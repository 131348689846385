import types from '../actionTypes';
import assetService from '../../services/asset.service';


export const createAsset = (asset) => (dispatch, getState) => {

    dispatch({
        type: types.ASSET_CREATE_REQUEST,
    });

    return assetService.createAsset(asset)
        .then((newAsset) => {
            dispatch({
                type: types.ASSET_CREATE_SUCCESS,
                payload: {asset: newAsset},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ASSET_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const addGroupToAsset = (dateFromS, dateToS, groupId, assetId) => (dispatch, getState) => {
    const ret = assetService.addGroupToAsset(assetId, {dateFrom: dateFromS, dateTo: dateToS, groupId});
    return ret;
};


export const updateAsset = (asset) => (dispatch, getState) => {

    dispatch({
        type: types.ASSET_UPDATE_REQUEST,
    });

    return assetService.updateAsset(asset)
        .then((newAsset) => {
            dispatch({
                type: types.ASSET_UPDATE_SUCCESS,
                payload: {asset: newAsset},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ASSET_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const activateAsset = (assetId, active) => (dispatch, getState) => {
    if (getState().assetData.isUpdating) {
        return;
    }

    dispatch({
        type: types.ASSET_ACTIVE_REQUEST,
    });

    return assetService.activeAsset(assetId, active)
        .then((newAsset) => {
            dispatch({
                type: types.ASSET_ACTIVE_SUCCESS,
                payload: {asset: newAsset},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ASSET_ACTIVE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllAssets = (tenantId) => (dispatch, getState) => {
    if (getState().assetData.isFetchingAllList) {
        return Promise.reject();
    }

    dispatch({
        type: types.ASSET_FETCH_REQUEST,
    });

    return assetService.getAssets(tenantId)
        .then((assets) => {
            dispatch({
                type: types.ASSET_FETCH_SUCCESS,
                payload: {assets: assets},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ASSET_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAvailableAssets = (locationId, dateFrom, dateTo, userId, reservationId) => (dispatch, getState) => {
    if (getState().assetData.isFetchingAvailableList) {
        return Promise.reject();
    }

    dispatch({
        type: types.ASSET_AVAILABLE_FETCH_REQUEST,
    });

    return assetService.getAvailableAssets(locationId, dateFrom, dateTo, userId, reservationId)
        .then((assets) => {
            dispatch({
                type: types.ASSET_AVAILABLE_FETCH_SUCCESS,
                payload: {assets, dateFrom, dateTo},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.ASSET_AVAILABLE_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};
