import types from '../actionTypes';

const INITIAL_STATE = {
    isCreating: false,
    isUpdating: false,
    isFetchingAllList: false,
    isFetchingAvailableList: false,
    errors: '',
    locations: [],
    availableLocations: [],
    availableLocationFrom: null,
    availableLocationTo: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case types.LOCATION_CREATE_SUCCESS:
            return {
                ...state, isCreating: false
            };
        case types.LOCATION_CREATE_REQUEST:
            return {
                ...state, isCreating: true
            };
        case types.LOCATION_CREATE_FAIL:
            return {
                ...state, 
                isCreating: true,
                errors: action.payload.error,
            };

        case types.LOCATION_UPDATE_SUCCESS:
            return {
                ...state, isUpdating: false
            };
        case types.LOCATION_UPDATE_REQUEST:
            return {
                ...state, isUpdating: true
            };
        case types.LOCATION_UPDATE_FAIL:
            return {
                ...state, 
                isUpdating: true,
                errors: action.payload.error,
            };
        case types.LOCATION_AVAILABLE_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAvailableList: false,
                availableLocations: action.payload.locations,
                availableLocationFrom: action.payload.dateFrom,
                availableLocationTo: action.payload.dateTo,
            };
        case types.LOCATION_AVAILABLE_FETCH_FAIL:
            return {
                ...state,
                isFetchingAvailableList: false,
                errors: action.payload.error,
            };
        case types.LOCATION_AVAILABLE_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAvailableList: true,
            };

        case types.LOCATION_FETCH_SUCCESS:
            return {
                ...state,
                isFetchingAvailableList: false,
                locations: action.payload.locations,
            };
        case types.LOCATION_FETCH_FAIL:
            return {
                ...state,
                isFetchingAvailableList: false,
                errors: action.payload.error,
            };
        case types.LOCATION_FETCH_REQUEST:
            return {
                ...state,
                isFetchingAvailableList: true,
            };
        default:
            return state;
    }
};
