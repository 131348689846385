import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { isComponentVisibleForUser } from "../../../utils";
import Typography from "@mui/material/Typography";
import Edit from "@mui/icons-material/Edit";
import CertificateIcon from "@mui/icons-material/VerifiedUser";
import SecurityIcon from "@mui/icons-material/Security";
import Tooltip from "@mui/material/Tooltip";
import PDSDialog from "../../../components/common/PDSDialog";
import {
  ASSET_TYPE_CAR,
  ASSET_TYPE_PLACE,
  UC0153,
  UC0154,
  UC0157,
  UC0158,
} from "../../../utils/constants";
import { height, styled } from "@mui/system";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {
  BottomNavigation,
  Button,
  Chip,
  CircularProgress,
  IconButton,
} from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList as List } from "react-window";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { useTheme } from "@emotion/react";

const FetchingProgress = styled("div")(({ theme }) => ({
  paddingTop: "12px",
}));

const Container = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexWrap: "wrap",
  paddingBottom: 100,
  paddingTop: 20,
}));

const Attachment = styled("img")(({ theme }) => ({
  // height: 'auto',
  // maxHeight: '200px'
}));

const Chips = styled("div")(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-around",
  paddingLeft: "2px",
  flexWrap: "wrap",
  minHeight: "40px",
}));

function AssetList(props) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    assets,
    userUseCases,
    isLoading,
    handleAssetDetail,
    table,
    handleActivate,
    confirmModalOpened,
    setConfirmModalOpened,
    handleAssetRelease,
    type,
    user,
  } = props;

  console.warn(isLoading);

  const [userToActive, setUserToActive] = useState();
  const [actionInProgress, setActionInProgress] = useState(false);

  const renderUserState = (param) => {
    if (!param) {
      return (
        <Tooltip title={t("PLACE_INACTIVE")}>
          <span
            style={{
              width: 20,
              height: 20,
              borderRadius: "50%",
              backgroundColor: "#e7e7e7",
              display: "flex",
            }}
          />
        </Tooltip>
      );
    } else {
      if (param.active)
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Tooltip title={t("PLACE_ACTIVE")}>
              <span
                style={{
                  width: 20,
                  height: 20,
                  borderRadius: "50%",
                  backgroundColor: "#51B148",
                  display: "flex",
                }}
              />
            </Tooltip>
          </div>
        );
      else
        return (
          <Tooltip title={t("PLACE_INACTIVE")}>
            <span
              style={{
                width: 20,
                height: 20,
                borderRadius: "50%",
                backgroundColor: "#e7e7e7",
                display: "flex",
              }}
            />
          </Tooltip>
        );
    }
  };

  const renderImg = (image, type) => {
    return (
      <div
        style={{
          maxWidth: "300px",
          maxHeight: "150px",
          overflow: "hidden",
          justifyContent: "center",
          display: "flex",
          margin: "5px",
          marginLeft: "15px",
          marginRight: "15px",
          borderRadius: theme.shape.borderRadius,
        }}
      >
        <Attachment
          src={
            image
              ? `data:image/png;base64,${image}`
              : type === ASSET_TYPE_PLACE
                ? "/misto.png"
                : "/auto.png"
          }
          sx={{
            //
            width: "auto",
            height: "150px",
            padding: !image ? "5px" : "0px",
          }}
        ></Attachment>
      </div>
    );
  };

  const renderChips = (groups) => {
    return (
      <Chips>
        {groups &&
          groups
            .filter((a) => a.active)
            .map((group, index) => {
              return (
                <Chip
                  style={{
                    backgroundColor: group.lightColor,
                    color: "black",
                  }}
                  label={group.groupName}
                />
              );
            })}
      </Chips>
    );
  };

  const renderPlace = (userItem) => {
    return (
      <Grid container width="100%" alignItems="center">
        <Grid item xs={2} sx={{ padding: 1 }}>
          {renderUserState(userItem)}
        </Grid>

        <Grid item xs={8} sx={{ padding: 1 }}>
          <Tooltip title={`${t("NAME")}`}>
            <Typography
              onClick={
                isComponentVisibleForUser(UC0154, userUseCases)
                  ? () => handleAssetDetail(userItem, false, true)
                  : () => {}
              }
              sx={{
                cursor: isComponentVisibleForUser(UC0154, userUseCases)
                  ? "pointer"
                  : "default",
              }}
              variant="h7"
            >
              {userItem.name}
            </Typography>
            <Typography
              variant="body2"
              onClick={
                isComponentVisibleForUser(UC0154, userUseCases)
                  ? () => handleAssetDetail(userItem, false, true)
                  : () => {}
              }
              sx={{
                cursor: isComponentVisibleForUser(UC0154, userUseCases)
                  ? "pointer"
                  : "default",
              }}
            >
              {userItem.locationName}{" "}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          {isComponentVisibleForUser(UC0153, userUseCases) && (
            <IconButton
              color="primary"
              aria-label="edit"
              size="small"
              label={t("EDIT")}
              onClick={() => {
                handleAssetDetail(userItem, false, false);
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={12} sx={{ padding: 0, margin: 0 }}>
          <Tooltip title={t("IMG")}>{renderImg(userItem.img, type)}</Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Tooltip title={t("GROUP")}>{renderChips(userItem.groups)}</Tooltip>
        </Grid>
      </Grid>
    );
  };

  const renderCar = (userItem) => {
    return (
      <Grid container width="100%" alignItems="center">
        <Grid item xs={2} sx={{ padding: 1 }}>
          {renderUserState(userItem)}
        </Grid>

        <Grid item xs={8} sx={{ padding: 1 }}>
          <Tooltip title={`${t("NAME")}`}>
            <Typography
              onClick={
                isComponentVisibleForUser(UC0158, userUseCases)
                  ? () => handleAssetDetail(userItem, false, true)
                  : () => {}
              }
              sx={{
                cursor: isComponentVisibleForUser(UC0158, userUseCases)
                  ? "pointer"
                  : "default",
              }}
              variant="h7"
            >
              {userItem.name}
            </Typography>
            <Typography
              variant="body2"
              onClick={
                isComponentVisibleForUser(UC0158, userUseCases)
                  ? () => handleAssetDetail(userItem, false, true)
                  : () => {}
              }
              sx={{
                cursor: isComponentVisibleForUser(UC0158, userUseCases)
                  ? "pointer"
                  : "default",
              }}
            >
              {userItem.locationName}{" "}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={2} sx={{ padding: 1 }}>
          {isComponentVisibleForUser(UC0157, userUseCases) && (
            <IconButton
              color="primary"
              aria-label="edit"
              size="small"
              label={t("EDIT")}
              onClick={() => {
                handleAssetDetail(userItem, false, false);
              }}
            >
              <Edit fontSize="small" />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={12} sx={{ padding: 0, margin: 0 }}>
          <Tooltip title={t("IMG")}>{renderImg(userItem.img, type)}</Tooltip>
        </Grid>
        <Grid item xs={12}>
          <Tooltip title={t("GROUP")}>{renderChips(userItem.groups)}</Tooltip>
        </Grid>
      </Grid>
    );
  };

  const CARD_SIZE = 320;

  const Row = ({ index, data, style }) => {
    const { assets, itemsPerRow } = data;

    if (!assets) {
      return null;
    }

    const items = [];
    const fromIndex = index * itemsPerRow;

    const toIndex = Math.min(fromIndex + itemsPerRow, assets.length);

    for (let i = fromIndex; i < toIndex; i++) {
      let userItem = assets[i];

      items.push(
        <Paper
          elevation={0}
          key={userItem.userId}
          sx={{
            width: CARD_SIZE,
            padding: 1,
            height: "105%",
            margin: 1,
            backgroundColor: (theme) => theme.palette.background.paper,
            boxShadow: `1px 1px 15px 1px ${theme.palette.shadow}`,
          }}
        >
          {type === ASSET_TYPE_PLACE && renderPlace(userItem)}
          {type === ASSET_TYPE_CAR && renderCar(userItem)}

          {type === ASSET_TYPE_PLACE &&
            isComponentVisibleForUser(UC0153, userUseCases) && (
              <Button
                onClick={() => {
                  handleAssetRelease(userItem);
                }}
                sx={{
                  width: "100% !important",
                  backgroundColor: (theme) => theme.palette.secondary.mainBg,
                  color: (theme) => theme.palette.secondary.main,
                  borderRadius: theme.shape.borderRadius,
                  fontWeight: 600,
                }}
              >
                {user && user.admin ? t("RELEASE") : t("RELEASE_NO_ADMIN")}
              </Button>
            )}

          {!(
            type === ASSET_TYPE_PLACE &&
            isComponentVisibleForUser(UC0153, userUseCases)
          ) && (
            <BottomNavigation
              sx={{
                borderBottomRightRadius: (theme) => theme.shape.borderRadius,
                borderBottomLeftRadius: (theme) => theme.shape.borderRadius,
                width: "100%",
                backgroundColor: (theme) => theme.palette.background.grey200,
                height: "60px",
              }}
              showLabels
            >
              {type === ASSET_TYPE_CAR &&
                isComponentVisibleForUser(UC0157, userUseCases) && (
                  <BottomNavigationAction
                    label={
                      <Typography
                        variant="caption"
                        sx={{
                          fontWeight: 800,
                        }}
                      >
                        {user && user.admin
                          ? t("RELEASE")
                          : t("RELEASE_CAR_NO_ADMIN")}
                      </Typography>
                    }
                    sx={{
                      width: "100% !important",
                      maxWidth: "100% !important",
                    }}
                    onClick={() => {
                      handleAssetRelease(userItem);
                    }}
                  />
                )}
            </BottomNavigation>
          )}
        </Paper>
      );
    }

    return (
      <div style={style}>
        <div
          style={{
            display: "flex",
            padding: "20px",
            justifyContent: "flex-start",
            height: "100%",
          }}
        >
          {items}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        marginTop: "10px",
        overflow: "auto",
        height: "calc(100vh - 200px)",
      }}
    >
      {confirmModalOpened && (
        <PDSDialog
          open={confirmModalOpened}
          title={`${
            userToActive.active
              ? t("PLACE_INACTIVATION")
              : t("PLACE_ACTIVATION")
          }`}
          onClose={() => {
            setConfirmModalOpened(false);
            setUserToActive(null);
          }}
          size="sm"
          headerColor="#5985EE"
          height={300}
          onSubmit={() => {
            handleActivate(userToActive);
          }}
          actionType={userToActive.active ? "userDeactivate" : "roleActivate"}
        >
          <div>
            {userToActive.active
              ? t("CONFIRM_PLACE_DEACTIVE", { userName: userToActive.userName })
              : t("CONFIRM_PLACE_ACTIVE", { userName: userToActive.userName })}
          </div>
        </PDSDialog>
      )}
      <AutoSizer>
        {({ height, width }) => {
          const itemsPerRow = Math.floor(width / CARD_SIZE) || 1; // A calculation to establish how many cards will go on each row.

          // The || 1 part is a simple hack that makes it work in a really small viewport (if someone totally collapses the window)

          const rowCount = Math.ceil(assets.length / itemsPerRow); // List will need the number of rows in order to be able to properly know what to render and what not to
          return (
            <div>
              <List
                height={height}
                itemCount={rowCount}
                itemData={{ itemsPerRow, assets: assets }}
                itemSize={330}
                width={width}
              >
                {Row}
              </List>
            </div>
          );
        }}
      </AutoSizer>
    </div>
  );
}

export default AssetList;
