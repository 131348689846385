import usersService from '../../services/users.service';
import types from '../actionTypes';

export const fetchAllUsers = () => (dispatch, getState) => {
    if (getState().usersData.isFetchingParkingList) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_FETCH_REQUEST,
    });

    return usersService.getUsersAll()
        .then((parkingUsers) => {
            dispatch({
                type: types.USERS_FETCH_SUCCESS,
                payload: {parkingUsers},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.USERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchAllUsersByRole = (roleId) => (dispatch, getState) => {
    if (getState().usersData.isFetchingRoleUser) {
        return Promise.reject();
    }

    dispatch({
        type: types.USERS_BY_ROLE_FETCH_REQUEST,
    });

    return usersService.getUsersAllByRole(roleId)
        .then((roleUsers) => {
            dispatch({
                type: types.USERS_BY_ROLE_FETCH_SUCCESS,
                payload: {roleUsers: roleUsers},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.USERS_BY_ROLE_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};


export const createUser = (user) => (dispatch, getState) => {
    if (getState().usersData.isCreating) {
        return;
    }

    dispatch({
        type: types.USER_CREATE_REQUEST,
    });

    return usersService.createUser(user)
        .then(() => {
            dispatch({
                type: types.USER_CREATE_SUCCESS,
                payload: {user: user},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.USER_CREATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateUser = (user) => (dispatch, getState) => {
    if (getState().usersData.isUpdating) {
        return;
    }

    dispatch({
        type: types.USER_UPDATE_REQUEST,
    });

    return usersService.updateUser(user)
        .then(() => {
            dispatch({
                type: types.USER_UPDATE_SUCCESS,
                payload: {user: user},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.USER_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateUserState = (userId, active) => (dispatch, getState) => {
    if (getState().usersData.isUpdating) {
        return Promise.reject();;
    }

    dispatch({
        type: types.USER_UPDATE_REQUEST,
    });

    return usersService.updateUserState(userId, active)
        .then(() => {
            dispatch({
                type: types.USER_UPDATE_SUCCESS,
                payload: {},
            });
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.USER_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};