import React from 'react';
import {useTranslation} from 'react-i18next';
import TextField from "@mui/material/TextField";
import {Chip} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";


export default function GroupAutocomplete({
                                              label,
                                              selectedGroups,
                                              groups,
                                              setGroups,
                                              multiple = true,
                                              required = false, error
                                          }) {
    const {t} = useTranslation();


    return (
        <Autocomplete
            multiple={multiple}
            value={selectedGroups || (multiple ? [] : null)}
            onChange={(event, newValue) => {
                setGroups(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
                return option.value === value.value
            }
            }
            filterSelectedOptions
            id="group"
            options={groups || []}
            getOptionLabel={(option) => {
                return option.label;
            }}
            style={{width: '100%'}}
            renderInput={(params) => (
                <TextField variant="outlined" required={required} error={!!error} helperText={error} {...params} label={label ? label : t('GROUP')}
                           fullWidth/>
            )}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => {
                        return <Chip
                            style={{
                                backgroundColor: option.lightColor,
                                color: 'black',
                                border: 'none'
                            }}
                            variant="outlined"
                            label={option.label}
                            {...getTagProps({index})}
                        />
                    }
                )
            }
            }
        />
    );
}

