import React from 'react';
import {useTranslation} from 'react-i18next';
import ToolBarCommon from "../../../components/common/ToolBarCommon";



function ToolBar() {


    const {t} = useTranslation();

    return (
        <ToolBarCommon shown={true} title={t('SETTING')} />
    )
        ;
}

ToolBar.propTypes = {};
ToolBar.defaultProps = {};

export default ToolBar;
