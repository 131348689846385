import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import {isComponentEnabledForUser, isComponentVisibleForUser} from "../../../utils";
import Edit from '@mui/icons-material/Edit';
import {UC0171} from '../../../utils/constants';
import {styled} from "@mui/system";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {Chip, CircularProgress, IconButton} from "@mui/material";
import AutoSizer from "react-virtualized-auto-sizer";
import {FixedSizeList as List} from "react-window";
import {useTheme} from '@emotion/react';

const FetchingProgress = styled('div')(({theme}) => ({
    paddingTop: '12px',
}));

const Container = styled('div')(({theme}) => ({
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 100,
    paddingTop: 20,

}));

const Attachment = styled('img')(({theme}) => ({
    height: '100px',
    width: 'auto',
    padding: '5px',
}));

const Chips = styled('div')(({theme}) => ({
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '50px',
    justifyContent: 'center'

}));

function GroupList(props) {

    const {t} = useTranslation();
    const theme = useTheme();
    const {
        groups,
        userUseCases,
        isLoading,
        handleGroupDetail,
        table,
        handleActivate,
        confirmModalOpened,
        setConfirmModalOpened,
        handlePlaceRelease
    } = props;

    console.warn(isLoading);

    const [userToActive, setUserToActive] = useState();
    const [actionInProgress, setActionInProgress] = useState(false);


    const renderChip = (group) => {
        return <Chips>
            <Chip
                style={{
                    backgroundColor: group.lightColor,
                    color: 'black',
                    border: 'none'
                }}
                variant="outlined"
                label={group.name}
            />
        </Chips>
    }


    const CARD_SIZE = 270;


    const Row = ({index, data, style}) => {

        const {groups, itemsPerRow} = data;

        if (!groups) {
            return null;
        }

        const items = [];
        const fromIndex = index * itemsPerRow;

        const toIndex = Math.min(
            fromIndex + itemsPerRow,
            groups.length
        );

        for (let i = fromIndex; i < toIndex; i++) {
            let userItem = groups[i];

            items.push(
                <Paper
                    elevation={0} key={userItem.userId}
                    sx={{
                        width: CARD_SIZE,
                        height: "80px",
                        padding: 2,
                        margin: 1,
                        backgroundColor: (theme) => theme.palette.background.paper,
                        boxShadow: `1px 1px 20px 1px ${theme.palette.shadow}`
                    }}
                >
                    <Grid container width='100%'
                          alignItems="center">
                        <Grid item xs={10}>

                            {renderChip(userItem)}
                        </Grid>


                        <Grid item xs={2}>
                            {isComponentVisibleForUser(UC0171, userUseCases) &&
                                <IconButton color="blabk" aria-label="edit" size="small" label={t('EDIT')}
                                            onClick={() => {
                                                handleGroupDetail(userItem, false, false)
                                            }}
                                            disabled={!isComponentEnabledForUser(UC0171, userUseCases)}
                                >
                                    <Edit fontSize="small"/>
                                </IconButton>}

                        </Grid>
                    </Grid>

                </Paper>)
        }

        return (

            <div style={style}>
                <div style={{display: 'flex', paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px', justifyContent: "flex-start", height: '100%'}}>
                    {items}
                </div>
            </div>
        )
    }


    return (
        <div style={{marginTop: "10px", overflow: 'auto', height: "calc(100vh - 200px)"}}>
             <AutoSizer>
                    {({height, width}) => {
                        const itemsPerRow = Math.floor(width / CARD_SIZE) || 1; // A calculation to establish how many cards will go on each row.

                        // The || 1 part is a simple hack that makes it work in a really small viewport (if someone totally collapses the window)

                        const rowCount = Math.ceil(groups.length / itemsPerRow); // List will need the number of rows in order to be able to properly know what to render and what not to
                        return (
                            <div>
                                <List
                                    height={height}
                                    itemCount={rowCount}
                                    itemData={{itemsPerRow, groups}}
                                    itemSize={100}
                                    width={width}
                                >
                                    {Row}


                                </List>

                            </div>
                        )
                            ;
                    }}
                </AutoSizer>
        </div>
    );
}

export default GroupList;

